import React from 'react';

import * as S from '../layout';
import { DialogVariantProps } from '../types';

const InfoDialog = ({
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  footerText,
}: DialogVariantProps) => {
  return (
    <>
      {title != null ? <S.WavesHeader>{title}</S.WavesHeader> : null}

      {description != null ? <S.Description>{description}</S.Description> : null}

      <S.ConfirmAndCancelButtons
        confirmText={confirmText}
        cancelText={cancelText}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />

      {footerText ? <S.FooterText>{footerText}</S.FooterText> : null}
    </>
  );
};

export default InfoDialog;
