import type { HTMLAttributeAnchorTarget } from 'react';

import { fetchEntries, fetchOneEntry } from '@builder.io/sdk-react';

import { logger } from '~/src/common/services/Logger';
import { getPathname, getSearchParams } from '~/src/common/utils/url';

import { BUILDER_API_KEY } from './constants';
import { BuilderFetchOptions, PageModel } from './types';

/**
 * Fonction générique de récupération d'un contenu Builder de type page.
 * Attend en entrée le nom du modèle ainsi que l'url de la page désirée.
 * Retourne un couple [queryKey, queryFn] utilisable directement avec RQ
 */
export const getPageModelEntry = (model: PageModel, path: string, opts?: BuilderFetchOptions) => {
  // La key utilisée pour le cache de react-query
  const urlPath = getPathname(path) ?? '';
  const queryKey = ['BuilderIO', 'page', model, urlPath];
  // La fonction de récupération des données
  const queryFn = async () => {
    const options = { ...getSearchParams(path), ...opts };
    const config = { model, options, userAttributes: { urlPath } };
    try {
      return await fetchOneEntry({ ...config, apiKey: BUILDER_API_KEY });
    } catch (originalError) {
      logger.error('Unable to load BuilderIO content', { originalError, ...config });
      return null;
    }
  };
  return [queryKey, queryFn] as const;
};

/**
 * Récupere une liste de liens depuis le modèle de données `Links` de BuilderIO
 * La liste renvoyée sera filtrée via le `slot` passé en entrée de la fonction
 * @todo Voir si possible de générer les types directement via l'api Builder ?
 */
export const getLinksForSlot = (slot?: string) => {
  // Le format de données du DataModel `link`, pas besoin de l'exposer
  type Data = {
    url: string;
    text: string;
    target?: HTMLAttributeAnchorTarget;
    rel?: ('noreferrer' | 'nofollow' | 'external')[];
    slots?: string[];
  };
  // La key utilisée pour le cache de react-query
  const queryKey = ['BuilderIO', 'model', 'links', slot];
  // La fonction de récupération des liens
  const queryFn = async () => {
    const query = { data: { slots: { $in: [slot] } } };
    const config = { model: 'links', limit: 100, query };
    try {
      return await fetchEntries<Data>({ ...config, apiKey: BUILDER_API_KEY });
    } catch (originalError) {
      logger.error('Unable to load BuilderIO content', { originalError, ...config });
      return null;
    }
  };

  return [queryKey, queryFn] as const;
};
