import styled from 'styled-components';

import { FAMILY_MENU_HEIGHT } from '~/src/common/components/Navigation/constants';
import { CategoryTreeViewContainer } from '~/src/screens/CatalogContent/ListProductsScreen/CategoryPage/layout';
import { IN_PROGRESS_ORDERS_BANNER_HEIGHT } from '~/src/screens/Home/layout';
import { NAV_HEIGHT, media } from '~/src/styles-constants';

export const CATALOG_SIDE_PADDING = 11;
export const CATALOG_SIDE_PADDING_V2 = 56;

export const CatalogLayoutContainer = styled.div<{ $isProgressOrdersBannerDisplayed: boolean }>`
  width: 100%;
  position: relative;
  padding-left: calc(env(safe-area-inset-left, 0px) + ${CATALOG_SIDE_PADDING}px);
  padding-right: calc(env(safe-area-inset-right, 0px) + ${CATALOG_SIDE_PADDING}px);

  ${media.XL<{ $isProgressOrdersBannerDisplayed: boolean }>`
    max-width: 100%;
    padding-left: calc(env(safe-area-inset-left, 0px) + ${CATALOG_SIDE_PADDING_V2}px);
    padding-right: calc(env(safe-area-inset-right, 0px) + ${CATALOG_SIDE_PADDING_V2}px);

    ${CategoryTreeViewContainer} {
      top: ${({ $isProgressOrdersBannerDisplayed }) =>
        `${
          $isProgressOrdersBannerDisplayed
            ? NAV_HEIGHT + FAMILY_MENU_HEIGHT + IN_PROGRESS_ORDERS_BANNER_HEIGHT + 16
            : NAV_HEIGHT + FAMILY_MENU_HEIGHT + 16
        }px`};
    }
  `}
`;
