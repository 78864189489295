import React from 'react';

/* Problème avec styled-components https://github.com/styled-components/styled-components/issues/2227 */
import FontIconsTtf from '~/src/common/assets/fonts/icons/font-icons-mm.ttf';
import FontIconsWoff from '~/src/common/assets/fonts/icons/font-icons-mm.woff';
import FontIconsWoff2 from '~/src/common/assets/fonts/icons/font-icons-mm.woff2';

const FONT_ICONS_HASH = 'm9yiho';
export const FONT_ICONS_URL = {
  woff2: `${FontIconsWoff2}?${FONT_ICONS_HASH}`,
  woff: `${FontIconsWoff}?${FONT_ICONS_HASH}`,
  ttf: `${FontIconsTtf}?${FONT_ICONS_HASH}`,
};

const FontIcons = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'font-icons-mm';
      src:
        url('${FONT_ICONS_URL.woff2}') format('woff2'),
        url('${FONT_ICONS_URL.woff}') format('woff'),
        url('${FONT_ICONS_URL.ttf}') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: block;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'font-icons-mm' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    /* This part is generated by scripts/update-font-icon.sh */
    /* START */
    .icon-Apple-full:before {
      content: ${'"\\e99c"'};
    }
    .icon-Arrow-up-left:before {
      content: ${'"\\e995"'};
    }
    .icon-Arrow-down-left:before {
      content: ${'"\\e996"'};
    }
    .icon-Arrow-up-right:before {
      content: ${'"\\e997"'};
    }
    .icon-Arrow-down-right:before {
      content: ${'"\\e998"'};
    }
    .icon-euro-crossed:before {
      content: ${'"\\e999"'};
    }
    .icon-euro-normal:before {
      content: ${'"\\e99a"'};
    }
    .icon-Apple:before {
      content: ${'"\\e99b"'};
    }
    .icon-barbecue:before {
      content: ${'"\\e994"'};
    }
    .icon-cooking-hat-full:before {
      content: ${'"\\e993"'};
    }
    .icon-thumb-up:before {
      content: ${'"\\e900"'};
    }
    .icon-basket-normal:before {
      content: ${'"\\e901"'};
    }
    .icon-gift:before {
      content: ${'"\\e902"'};
    }
    .icon-Warning-circle:before {
      content: ${'"\\e903"'};
    }
    .icon-trash:before {
      content: ${'"\\e904"'};
    }
    .icon-update:before {
      content: ${'"\\e905"'};
    }
    .icon-v-hand:before {
      content: ${'"\\e906"'};
    }
    .icon-weight:before {
      content: ${'"\\e907"'};
    }
    .icon-valid-small:before {
      content: ${'"\\e908"'};
    }
    .icon-valid-big:before {
      content: ${'"\\e909"'};
    }
    .icon-warning-normal:before {
      content: ${'"\\e90a"'};
    }
    .icon-warning-full:before {
      content: ${'"\\e90b"'};
    }
    .icon-Refresh:before {
      content: ${'"\\e90c"'};
    }
    .icon-Replace:before {
      content: ${'"\\e90d"'};
    }
    .icon-smiley:before {
      content: ${'"\\e90e"'};
    }
    .icon-stairs:before {
      content: ${'"\\e90f"'};
    }
    .icon-sun:before {
      content: ${'"\\e910"'};
    }
    .icon-support:before {
      content: ${'"\\e911"'};
    }
    .icon-sur-place:before {
      content: ${'"\\e912"'};
    }
    .icon-promotion:before {
      content: ${'"\\e913"'};
    }
    .icon-engagement:before {
      content: ${'"\\e914"'};
    }
    .icon-selection:before {
      content: ${'"\\e915"'};
    }
    .icon-supply:before {
      content: ${'"\\e916"'};
    }
    .icon-search-full:before {
      content: ${'"\\e917"'};
    }
    .icon-search-normal:before {
      content: ${'"\\e918"'};
    }
    .icon-shop-normal:before {
      content: ${'"\\e919"'};
    }
    .icon-shop-categories:before {
      content: ${'"\\e91a"'};
    }
    .icon-shop-stand:before {
      content: ${'"\\e91b"'};
    }
    .icon-shop-return:before {
      content: ${'"\\e91c"'};
    }
    .icon-question:before {
      content: ${'"\\e91d"'};
    }
    .icon-info:before {
      content: ${'"\\e91e"'};
    }
    .icon-promotion-2:before {
      content: ${'"\\e91f"'};
    }
    .icon-promotion-3:before {
      content: ${'"\\e920"'};
    }
    .icon-promotion-1:before {
      content: ${'"\\e921"'};
    }
    .icon-02:before {
      content: ${'"\\e922"'};
    }
    .icon-03:before {
      content: ${'"\\e923"'};
    }
    .icon-01:before {
      content: ${'"\\e924"'};
    }
    .icon-hourglass:before {
      content: ${'"\\e925"'};
    }
    .icon-clock-normal:before {
      content: ${'"\\e926"'};
    }
    .icon-Play-small:before {
      content: ${'"\\e927"'};
    }
    .icon-prepare-1:before {
      content: ${'"\\e928"'};
    }
    .icon-prepare-2:before {
      content: ${'"\\e929"'};
    }
    .icon-prepare-3:before {
      content: ${'"\\e92a"'};
    }
    .icon-price:before {
      content: ${'"\\e92b"'};
    }
    .icon-print:before {
      content: ${'"\\e92c"'};
    }
    .icon-profile-normal:before {
      content: ${'"\\e92d"'};
    }
    .icon-profile-full:before {
      content: ${'"\\e92e"'};
    }
    .icon-profile-sign-in:before {
      content: ${'"\\e92f"'};
    }
    .icon-sponsorship:before {
      content: ${'"\\e930"'};
    }
    .icon-Ingredient:before {
      content: ${'"\\e931"'};
    }
    .icon-logo-app:before {
      content: ${'"\\e932"'};
    }
    .icon-morning:before {
      content: ${'"\\e933"'};
    }
    .icon-night:before {
      content: ${'"\\e934"'};
    }
    .icon-home-normal:before {
      content: ${'"\\e935"'};
    }
    .icon-home-full:before {
      content: ${'"\\e936"'};
    }
    .icon-burger-menu:before {
      content: ${'"\\e937"'};
    }
    .icon-list-normal:before {
      content: ${'"\\e938"'};
    }
    .icon-list-heart-normal:before {
      content: ${'"\\e939"'};
    }
    .icon-list-plus:before {
      content: ${'"\\e93a"'};
    }
    .icon-list-heart-full:before {
      content: ${'"\\e93b"'};
    }
    .icon-marker-valid:before {
      content: ${'"\\e93c"'};
    }
    .icon-marker-normal:before {
      content: ${'"\\e93d"'};
    }
    .icon-Move-bike:before {
      content: ${'"\\e93e"'};
    }
    .icon-Move-delivery-clock:before {
      content: ${'"\\e93f"'};
    }
    .icon-Move-drive:before {
      content: ${'"\\e940"'};
    }
    .icon-Move-delivery:before {
      content: ${'"\\e941"'};
    }
    .icon-Move-walk:before {
      content: ${'"\\e942"'};
    }
    .icon-Move-truck:before {
      content: ${'"\\e943"'};
    }
    .icon-Move-bus:before {
      content: ${'"\\e944"'};
    }
    .icon-9:before {
      content: ${'"\\e945"'};
    }
    .icon-8:before {
      content: ${'"\\e946"'};
    }
    .icon-0:before {
      content: ${'"\\e947"'};
    }
    .icon-1:before {
      content: ${'"\\e948"'};
    }
    .icon-3:before {
      content: ${'"\\e949"'};
    }
    .icon-2:before {
      content: ${'"\\e94a"'};
    }
    .icon-6:before {
      content: ${'"\\e94b"'};
    }
    .icon-7:before {
      content: ${'"\\e94c"'};
    }
    .icon-5:before {
      content: ${'"\\e94d"'};
    }
    .icon-4:before {
      content: ${'"\\e94e"'};
    }
    .icon-padlock-secure:before {
      content: ${'"\\e94f"'};
    }
    .icon-padlock-valid:before {
      content: ${'"\\e950"'};
    }
    .icon-padlock-normal:before {
      content: ${'"\\e951"'};
    }
    .icon-flag:before {
      content: ${'"\\e952"'};
    }
    .icon-flashlight:before {
      content: ${'"\\e953"'};
    }
    .icon-freshness:before {
      content: ${'"\\e954"'};
    }
    .icon-geolocation:before {
      content: ${'"\\e955"'};
    }
    .icon-heart-normal:before {
      content: ${'"\\e956"'};
    }
    .icon-heart-full:before {
      content: ${'"\\e957"'};
    }
    .icon-Filter:before {
      content: ${'"\\e958"'};
    }
    .icon-contact:before {
      content: ${'"\\e959"'};
    }
    .icon-copy:before {
      content: ${'"\\e95a"'};
    }
    .icon-cross:before {
      content: ${'"\\e95b"'};
    }
    .icon-Disconnect:before {
      content: ${'"\\e95c"'};
    }
    .icon-Door:before {
      content: ${'"\\e95d"'};
    }
    .icon-edit:before {
      content: ${'"\\e95e"'};
    }
    .icon-Empty:before {
      content: ${'"\\e95f"'};
    }
    .icon-Chevron-down:before {
      content: ${'"\\e960"'};
    }
    .icon-Chevron-up:before {
      content: ${'"\\e961"'};
    }
    .icon-Chevron-right:before {
      content: ${'"\\e962"'};
    }
    .icon-Chevron-left:before {
      content: ${'"\\e963"'};
    }
    .icon-pressure-cooker:before {
      content: ${'"\\e964"'};
    }
    .icon-saucepan:before {
      content: ${'"\\e965"'};
    }
    .icon-oven:before {
      content: ${'"\\e966"'};
    }
    .icon-cooking-hat:before {
      content: ${'"\\e967"'};
    }
    .icon-Eye-off:before {
      content: ${'"\\e968"'};
    }
    .icon-Eye-on:before {
      content: ${'"\\e969"'};
    }
    .icon-arm:before {
      content: ${'"\\e96a"'};
    }
    .icon-Bac:before {
      content: ${'"\\e96b"'};
    }
    .icon-calendar:before {
      content: ${'"\\e96c"'};
    }
    .icon-Chargement:before {
      content: ${'"\\e96d"'};
    }
    .icon-plus:before {
      content: ${'"\\e96e"'};
    }
    .icon-minus:before {
      content: ${'"\\e96f"'};
    }
    .icon-Arrow-foreward:before {
      content: ${'"\\e970"'};
    }
    .icon-Arrow-down:before {
      content: ${'"\\e971"'};
    }
    .icon-Arrow-corner-right:before {
      content: ${'"\\e972"'};
    }
    .icon-Arrow-Modify:before {
      content: ${'"\\e973"'};
    }
    .icon-Arrow-back:before {
      content: ${'"\\e974"'};
    }
    .icon-Arrow-up:before {
      content: ${'"\\e975"'};
    }
    .icon-Switch:before {
      content: ${'"\\e976"'};
    }
    .icon-cart:before {
      content: ${'"\\e977"'};
    }
    .icon-select:before {
      content: ${'"\\e978"'};
    }
    .icon-basket-heart:before {
      content: ${'"\\e979"'};
    }
    .icon-basket-full:before {
      content: ${'"\\e97a"'};
    }
    .icon-card-normal:before {
      content: ${'"\\e97b"'};
    }
    .icon-no-card:before {
      content: ${'"\\e97c"'};
    }
    .icon-10:before {
      content: ${'"\\e97d"'};
    }
    .icon-11:before {
      content: ${'"\\e97e"'};
    }
    .icon-12:before {
      content: ${'"\\e97f"'};
    }
    .icon-13:before {
      content: ${'"\\e980"'};
    }
    .icon-14:before {
      content: ${'"\\e981"'};
    }
    .icon-15:before {
      content: ${'"\\e982"'};
    }
    .icon-16:before {
      content: ${'"\\e983"'};
    }
    .icon-17:before {
      content: ${'"\\e984"'};
    }
    .icon-18:before {
      content: ${'"\\e985"'};
    }
    .icon-19:before {
      content: ${'"\\e986"'};
    }
    .icon-20:before {
      content: ${'"\\e987"'};
    }
    .icon-21:before {
      content: ${'"\\e988"'};
    }
    .icon-22:before {
      content: ${'"\\e989"'};
    }
    .icon-23:before {
      content: ${'"\\e98a"'};
    }
    .icon-24:before {
      content: ${'"\\e98b"'};
    }
    .icon-25:before {
      content: ${'"\\e98c"'};
    }
    .icon-26:before {
      content: ${'"\\e98d"'};
    }
    .icon-27:before {
      content: ${'"\\e98e"'};
    }
    .icon-28:before {
      content: ${'"\\e98f"'};
    }
    .icon-29:before {
      content: ${'"\\e990"'};
    }
    .icon-30:before {
      content: ${'"\\e991"'};
    }
    .icon-matrice:before {
      content: ${'"\\e992"'};
    }
    /* END */
  `}</style>
);

export default FontIcons;
