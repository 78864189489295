export default {
  'free': 'Offert',
  'delivery': 'Livraison',
  'delivery-at': 'Livraison à',
  'applied': 'appliquée !',
  'FREE': 'OFFERTE',
  'delivery-applied': 'Livraison à %{deliveryFee} appliquée !',
  'delivery-when-step-reached': "Livraison à %{deliveryFee} dès %{cartStep} d'achat",
  'free-delivery-when-step-reached': "Livraison offerte dès %{cartStep} d'achat",
  'next-step-paying': 'Plus que %{remaining} pour la livraison à %{deliveryFee}',
  'next-step-offered': 'Plus que %{remaining} pour la livraison offerte',
};
