/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * api-ecom
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddArticleProposal204,
  AddArticleProposal400,
  AddArticleProposalBody,
  AddCoupons200,
  AddCouponsBody,
  AddCouponsParams,
  AddProduct200,
  AddProduct2200,
  AddProduct2Body,
  AddProductBody,
  AddProductParams,
  AddProductToList200,
  AddProductToList400,
  AddProductToListBody,
  AddProducts200,
  AddProducts2200,
  AddProducts2Body,
  AddProductsBody,
  AddProductsParams,
  AddProspectAddress200,
  AddProspectAddress400,
  AddProspectAddressBody,
  ApplyReferralCode200,
  CancelOrder200,
  CartExternalRecommenders200,
  CartExternalRecommendersBody,
  CartRelatedArticles200,
  CartRelatedArticlesParams,
  CreateAddress200,
  CreateAddress401,
  CreateAddressBody,
  CreateCard200,
  CreateCardBody,
  CreateCouponFromPromoCode200,
  CreateCouponFromPromoCodeParams,
  CreatePaymentIntent200,
  CreatePaymentIntentBody,
  CurrentOrders200,
  DeleteAccountProductsSku400,
  DeleteAddress200,
  DeleteAddress401,
  DeleteCard200,
  DeleteCustomer204,
  DeleteCustomer400,
  DeliverySlots200,
  DeliverySlotsBody,
  EmptyCart200,
  FinalizePrepay200,
  FinalizePrepayBody,
  GetAccountProducts200,
  GetAccountProducts400,
  GetAccountProductsSku400,
  GetApiAccountCoupons200,
  GetApiAccountCoupons401,
  GetApiAccountOptin200,
  GetApiFeedsProductsTsvParams,
  GetArticleSubstitutes200,
  GetCart200,
  GetCartByCustomerId200,
  GetCartByCustomerIdBody,
  GetCartLight200,
  GetCartParams,
  GetCategories200,
  GetCategoriesParams,
  GetCustomerReferralInfos200,
  GetDeliveryZone200,
  GetDeliveryZoneParams,
  GetDetailBySlug200,
  GetFrequentProducts200,
  GetFrequentProducts400,
  GetFrequentProductsParams,
  GetInvoiceDownloadUrl200,
  GetList2200,
  GetList2400,
  GetOptinReminder200,
  GetOrder200,
  GetOrderProducts200,
  GetProductIds200,
  GetProductIdsParams,
  GetRecipeAllProducts200,
  GetRecipeAllProductsBody,
  GetRecipeByIdOrSlug200,
  GetReferralSettings200,
  GetSearchPopular200,
  GetSearchSuggestions200,
  GetSearchSuggestionsParams,
  GetSpecialEvents200,
  GetTaskDeliveryInfos200,
  GetTaskDeliveryInfosBody,
  Home200,
  HomeParams,
  LastOrder200,
  ListAddresses200,
  ListAddresses401,
  ListAllCoupons200,
  ListAllCoupons401,
  ListAllCouponsParams,
  ListCards200,
  ListOrders200,
  ListOrdersParams,
  ListOrdersWithProducts200,
  ListOrdersWithProductsParams,
  MissingProducts200,
  MissingProductsBody,
  MissingProductsParams,
  ModifyOrder200,
  ModifyOrderParams,
  Navigation200,
  Packing200,
  PackingBody,
  PackingParams,
  PastOrders200,
  PastOrdersParams,
  PatchApiAccountOptin200,
  PatchApiAccountOptinBody,
  PatchCartDelivery2200,
  PatchCartDelivery2Body,
  PatchCartDelivery2Params,
  PostApiAuthForgottenPassword204,
  PostApiAuthForgottenPasswordBody,
  PostApiAuthMe200,
  PostApiAuthMeBody,
  PostApiAuthResetPassword200,
  PostApiAuthResetPasswordBody,
  PostApiAuthSignout204,
  PostApiWebhooksStripe200,
  PostApiWebhooksStripe400,
  ProductsByCategory200,
  ProductsByCategoryParams,
  Recompute200,
  Recompute2200,
  RemoveArticleFromList200,
  RemoveArticleFromList400,
  RootCategories200,
  SearchArticle200,
  SearchArticleParams,
  SearchItems200,
  SearchItemsParams,
  SearchRelatedArticles200,
  SearchRelatedArticlesParams,
  SendSmsVerificationCode200,
  SendSmsVerificationCodeBody,
  SetDefaultCard200,
  SetDefaultCardBody,
  SignIn200,
  SignInBody,
  Signup200,
  SignupBody,
  TrackPayment200,
  TrackPaymentBody,
  UpdateAddress200,
  UpdateAddress401,
  UpdateAddressBody,
  UpdateCustomer200,
  UpdateCustomer2200,
  UpdateCustomer2400,
  UpdateCustomer2Body,
  UpdateCustomer400,
  UpdateCustomerBody,
  UpdateCustomerInformation200,
  UpdateCustomerInformationBody,
  UpdateCustomerInformationParams,
  UpdateOrderTimeSlot200,
  UpdateOrderTimeSlotBody,
  UseReferralCode200,
  UseReferralCodeBodyBody,
  VerifyDelivery200,
  VerifyDeliveryBody,
  VerifyDeliveryParams,
  Vitals200,
  VitalsBody
} from './api-ecom.schemas'
import { fetchAPI } from '../../services/fetchAPI';



/**
 * @summary Empty the current cart
 */
export const emptyCart = (
    
 ) => {
      
      
      return fetchAPI<EmptyCart200>(
      {url: `/api/cart`, method: 'DELETE'
    },
      );
    }
  


export const getEmptyCartMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof emptyCart>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof emptyCart>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof emptyCart>>, void> = () => {
          

          return  emptyCart()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type EmptyCartMutationResult = NonNullable<Awaited<ReturnType<typeof emptyCart>>>
    
    export type EmptyCartMutationError = unknown

    /**
 * @summary Empty the current cart
 */
export const useEmptyCart = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof emptyCart>>, TError,void, TContext>, }
) => {

      const mutationOptions = getEmptyCartMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the current cart
 */
export const getCart = (
    params?: GetCartParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetCart200>(
      {url: `/api/cart`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCartQueryKey = (params?: GetCartParams,) => {
    return [`/api/cart`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCartQueryOptions = <TData = Awaited<ReturnType<typeof getCart>>, TError = unknown>(params?: GetCartParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCartQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCart>>> = ({ signal }) => getCart(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCartQueryResult = NonNullable<Awaited<ReturnType<typeof getCart>>>
export type GetCartQueryError = unknown

/**
 * @summary Get the current cart
 */
export const useGetCart = <TData = Awaited<ReturnType<typeof getCart>>, TError = unknown>(
 params?: GetCartParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCartQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the minimal informations for the current cart
 */
export const getCartLight = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetCartLight200>(
      {url: `/api/cart/light`, method: 'GET', signal
    },
      );
    }
  

export const getGetCartLightQueryKey = () => {
    return [`/api/cart/light`] as const;
    }

    
export const getGetCartLightQueryOptions = <TData = Awaited<ReturnType<typeof getCartLight>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCartLight>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCartLightQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCartLight>>> = ({ signal }) => getCartLight(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCartLight>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCartLightQueryResult = NonNullable<Awaited<ReturnType<typeof getCartLight>>>
export type GetCartLightQueryError = unknown

/**
 * @summary Get the minimal informations for the current cart
 */
export const useGetCartLight = <TData = Awaited<ReturnType<typeof getCartLight>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCartLight>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCartLightQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary add products to the current cart
 */
export const addProducts = (
    addProductsBody: AddProductsBody,
    params?: AddProductsParams,
 ) => {
      
      
      return fetchAPI<AddProducts200>(
      {url: `/api/cart/products`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: addProductsBody,
        params
    },
      );
    }
  


export const getAddProductsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProducts>>, TError,{data: AddProductsBody;params?: AddProductsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProducts>>, TError,{data: AddProductsBody;params?: AddProductsParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProducts>>, {data: AddProductsBody;params?: AddProductsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addProducts(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProductsMutationResult = NonNullable<Awaited<ReturnType<typeof addProducts>>>
    export type AddProductsMutationBody = AddProductsBody
    export type AddProductsMutationError = unknown

    /**
 * @summary add products to the current cart
 */
export const useAddProducts = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProducts>>, TError,{data: AddProductsBody;params?: AddProductsParams}, TContext>, }
) => {

      const mutationOptions = getAddProductsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary [DEPRECATED] add products to the current cart
 */
export const addProducts2 = (
    addProducts2Body: AddProducts2Body,
 ) => {
      
      
      return fetchAPI<AddProducts2200>(
      {url: `/api/cart/products2`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: addProducts2Body
    },
      );
    }
  


export const getAddProducts2MutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProducts2>>, TError,{data: AddProducts2Body}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProducts2>>, TError,{data: AddProducts2Body}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProducts2>>, {data: AddProducts2Body}> = (props) => {
          const {data} = props ?? {};

          return  addProducts2(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProducts2MutationResult = NonNullable<Awaited<ReturnType<typeof addProducts2>>>
    export type AddProducts2MutationBody = AddProducts2Body
    export type AddProducts2MutationError = unknown

    /**
 * @deprecated
 * @summary [DEPRECATED] add products to the current cart
 */
export const useAddProducts2 = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProducts2>>, TError,{data: AddProducts2Body}, TContext>, }
) => {

      const mutationOptions = getAddProducts2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary add a product to the current cart
 */
export const addProduct = (
    addProductBody: AddProductBody,
    params?: AddProductParams,
 ) => {
      
      
      return fetchAPI<AddProduct200>(
      {url: `/api/cart/product`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: addProductBody,
        params
    },
      );
    }
  


export const getAddProductMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProduct>>, TError,{data: AddProductBody;params?: AddProductParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProduct>>, TError,{data: AddProductBody;params?: AddProductParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProduct>>, {data: AddProductBody;params?: AddProductParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addProduct(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProductMutationResult = NonNullable<Awaited<ReturnType<typeof addProduct>>>
    export type AddProductMutationBody = AddProductBody
    export type AddProductMutationError = unknown

    /**
 * @summary add a product to the current cart
 */
export const useAddProduct = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProduct>>, TError,{data: AddProductBody;params?: AddProductParams}, TContext>, }
) => {

      const mutationOptions = getAddProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary [DEPRECATED] add a product to the current cart
 */
export const addProduct2 = (
    addProduct2Body: AddProduct2Body,
 ) => {
      
      
      return fetchAPI<AddProduct2200>(
      {url: `/api/cart/product2`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: addProduct2Body
    },
      );
    }
  


export const getAddProduct2MutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProduct2>>, TError,{data: AddProduct2Body}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProduct2>>, TError,{data: AddProduct2Body}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProduct2>>, {data: AddProduct2Body}> = (props) => {
          const {data} = props ?? {};

          return  addProduct2(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProduct2MutationResult = NonNullable<Awaited<ReturnType<typeof addProduct2>>>
    export type AddProduct2MutationBody = AddProduct2Body
    export type AddProduct2MutationError = unknown

    /**
 * @deprecated
 * @summary [DEPRECATED] add a product to the current cart
 */
export const useAddProduct2 = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProduct2>>, TError,{data: AddProduct2Body}, TContext>, }
) => {

      const mutationOptions = getAddProduct2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Replace the coupons in the cart with those provided
 */
export const addCoupons = (
    addCouponsBody: AddCouponsBody,
    params?: AddCouponsParams,
 ) => {
      
      
      return fetchAPI<AddCoupons200>(
      {url: `/api/cart/coupons`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: addCouponsBody,
        params
    },
      );
    }
  


export const getAddCouponsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCoupons>>, TError,{data: AddCouponsBody;params?: AddCouponsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addCoupons>>, TError,{data: AddCouponsBody;params?: AddCouponsParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCoupons>>, {data: AddCouponsBody;params?: AddCouponsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addCoupons(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddCouponsMutationResult = NonNullable<Awaited<ReturnType<typeof addCoupons>>>
    export type AddCouponsMutationBody = AddCouponsBody
    export type AddCouponsMutationError = unknown

    /**
 * @summary Replace the coupons in the cart with those provided
 */
export const useAddCoupons = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCoupons>>, TError,{data: AddCouponsBody;params?: AddCouponsParams}, TContext>, }
) => {

      const mutationOptions = getAddCouponsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Set the packing preferences
 */
export const packing = (
    packingBody: PackingBody,
    params?: PackingParams,
 ) => {
      
      
      return fetchAPI<Packing200>(
      {url: `/api/cart/packing`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: packingBody,
        params
    },
      );
    }
  


export const getPackingMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof packing>>, TError,{data: PackingBody;params?: PackingParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof packing>>, TError,{data: PackingBody;params?: PackingParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof packing>>, {data: PackingBody;params?: PackingParams}> = (props) => {
          const {data,params} = props ?? {};

          return  packing(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PackingMutationResult = NonNullable<Awaited<ReturnType<typeof packing>>>
    export type PackingMutationBody = PackingBody
    export type PackingMutationError = unknown

    /**
 * @summary Set the packing preferences
 */
export const usePacking = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof packing>>, TError,{data: PackingBody;params?: PackingParams}, TContext>, }
) => {

      const mutationOptions = getPackingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Set the customer preferences concerning the missing products
 */
export const missingProducts = (
    missingProductsBody: MissingProductsBody,
    params?: MissingProductsParams,
 ) => {
      
      
      return fetchAPI<MissingProducts200>(
      {url: `/api/cart/missingProducts`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: missingProductsBody,
        params
    },
      );
    }
  


export const getMissingProductsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof missingProducts>>, TError,{data: MissingProductsBody;params?: MissingProductsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof missingProducts>>, TError,{data: MissingProductsBody;params?: MissingProductsParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof missingProducts>>, {data: MissingProductsBody;params?: MissingProductsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  missingProducts(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type MissingProductsMutationResult = NonNullable<Awaited<ReturnType<typeof missingProducts>>>
    export type MissingProductsMutationBody = MissingProductsBody
    export type MissingProductsMutationError = unknown

    /**
 * @summary Set the customer preferences concerning the missing products
 */
export const useMissingProducts = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof missingProducts>>, TError,{data: MissingProductsBody;params?: MissingProductsParams}, TContext>, }
) => {

      const mutationOptions = getMissingProductsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary home
 */
export const home = (
    params?: HomeParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<Home200>(
      {url: `/api/home`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getHomeQueryKey = (params?: HomeParams,) => {
    return [`/api/home`, ...(params ? [params]: [])] as const;
    }

    
export const getHomeQueryOptions = <TData = Awaited<ReturnType<typeof home>>, TError = unknown>(params?: HomeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof home>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHomeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof home>>> = ({ signal }) => home(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof home>>, TError, TData> & { queryKey: QueryKey }
}

export type HomeQueryResult = NonNullable<Awaited<ReturnType<typeof home>>>
export type HomeQueryError = unknown

/**
 * @summary home
 */
export const useHome = <TData = Awaited<ReturnType<typeof home>>, TError = unknown>(
 params?: HomeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof home>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHomeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary returns the categories matching the filters and their children
 */
export const getCategories = (
    params?: GetCategoriesParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetCategories200>(
      {url: `/api/categories`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCategoriesQueryKey = (params?: GetCategoriesParams,) => {
    return [`/api/categories`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getCategories>>, TError = unknown>(params?: GetCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategories>>> = ({ signal }) => getCategories(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCategories>>>
export type GetCategoriesQueryError = unknown

/**
 * @summary returns the categories matching the filters and their children
 */
export const useGetCategories = <TData = Awaited<ReturnType<typeof getCategories>>, TError = unknown>(
 params?: GetCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary returns the list of the root categories, with their most important fields
 */
export const rootCategories = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<RootCategories200>(
      {url: `/api/rootCategories`, method: 'GET', signal
    },
      );
    }
  

export const getRootCategoriesQueryKey = () => {
    return [`/api/rootCategories`] as const;
    }

    
export const getRootCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof rootCategories>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof rootCategories>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRootCategoriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof rootCategories>>> = ({ signal }) => rootCategories(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof rootCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type RootCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof rootCategories>>>
export type RootCategoriesQueryError = unknown

/**
 * @summary returns the list of the root categories, with their most important fields
 */
export const useRootCategories = <TData = Awaited<ReturnType<typeof rootCategories>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof rootCategories>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRootCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the article details by slug
 */
export const getDetailBySlug = (
    slug: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetDetailBySlug200>(
      {url: `/api/articleDetailBySlug/${slug}`, method: 'GET', signal
    },
      );
    }
  

export const getGetDetailBySlugQueryKey = (slug: string,) => {
    return [`/api/articleDetailBySlug/${slug}`] as const;
    }

    
export const getGetDetailBySlugQueryOptions = <TData = Awaited<ReturnType<typeof getDetailBySlug>>, TError = unknown>(slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDetailBySlug>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDetailBySlugQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDetailBySlug>>> = ({ signal }) => getDetailBySlug(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDetailBySlug>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDetailBySlugQueryResult = NonNullable<Awaited<ReturnType<typeof getDetailBySlug>>>
export type GetDetailBySlugQueryError = unknown

/**
 * @summary Get the article details by slug
 */
export const useGetDetailBySlug = <TData = Awaited<ReturnType<typeof getDetailBySlug>>, TError = unknown>(
 slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDetailBySlug>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDetailBySlugQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary search article
 */
export const searchArticle = (
    params: SearchArticleParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<SearchArticle200>(
      {url: `/api/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSearchArticleQueryKey = (params: SearchArticleParams,) => {
    return [`/api/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchArticleQueryOptions = <TData = Awaited<ReturnType<typeof searchArticle>>, TError = unknown>(params: SearchArticleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchArticle>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchArticleQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchArticle>>> = ({ signal }) => searchArticle(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchArticle>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchArticleQueryResult = NonNullable<Awaited<ReturnType<typeof searchArticle>>>
export type SearchArticleQueryError = unknown

/**
 * @summary search article
 */
export const useSearchArticle = <TData = Awaited<ReturnType<typeof searchArticle>>, TError = unknown>(
 params: SearchArticleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchArticle>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchArticleQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Retrieves <limit> items (recipe or product) using given search algorithm
 */
export const searchItems = (
    params: SearchItemsParams,
 ) => {
      
      
      return fetchAPI<SearchItems200>(
      {url: `/api/search2`, method: 'GET',
        params
    },
      );
    }
  

export const getSearchItemsQueryKey = (params: SearchItemsParams,) => {
    return [`/api/search2`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchItemsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof searchItems>>, TError = unknown>(params: SearchItemsParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchItemsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchItems>>> = ({ pageParam }) => searchItems({...params, next: pageParam || params?.['next']}, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchItemsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof searchItems>>>
export type SearchItemsInfiniteQueryError = unknown

/**
 * @summary Retrieves <limit> items (recipe or product) using given search algorithm
 */
export const useSearchItemsInfinite = <TData = Awaited<ReturnType<typeof searchItems>>, TError = unknown>(
 params: SearchItemsParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchItemsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSearchItemsQueryOptions = <TData = Awaited<ReturnType<typeof searchItems>>, TError = unknown>(params: SearchItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchItemsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchItems>>> = () => searchItems(params, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchItemsQueryResult = NonNullable<Awaited<ReturnType<typeof searchItems>>>
export type SearchItemsQueryError = unknown

/**
 * @summary Retrieves <limit> items (recipe or product) using given search algorithm
 */
export const useSearchItems = <TData = Awaited<ReturnType<typeof searchItems>>, TError = unknown>(
 params: SearchItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchItems>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchItemsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary search suggestions
 */
export const getSearchSuggestions = (
    params: GetSearchSuggestionsParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetSearchSuggestions200>(
      {url: `/api/search/suggestions`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetSearchSuggestionsQueryKey = (params: GetSearchSuggestionsParams,) => {
    return [`/api/search/suggestions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchSuggestionsQueryOptions = <TData = Awaited<ReturnType<typeof getSearchSuggestions>>, TError = unknown>(params: GetSearchSuggestionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSearchSuggestions>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchSuggestionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchSuggestions>>> = ({ signal }) => getSearchSuggestions(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchSuggestions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchSuggestionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchSuggestions>>>
export type GetSearchSuggestionsQueryError = unknown

/**
 * @summary search suggestions
 */
export const useGetSearchSuggestions = <TData = Awaited<ReturnType<typeof getSearchSuggestions>>, TError = unknown>(
 params: GetSearchSuggestionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSearchSuggestions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchSuggestionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary search popular words
 */
export const getSearchPopular = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetSearchPopular200>(
      {url: `/api/search/popular`, method: 'GET', signal
    },
      );
    }
  

export const getGetSearchPopularQueryKey = () => {
    return [`/api/search/popular`] as const;
    }

    
export const getGetSearchPopularQueryOptions = <TData = Awaited<ReturnType<typeof getSearchPopular>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSearchPopular>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchPopularQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchPopular>>> = ({ signal }) => getSearchPopular(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchPopular>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchPopularQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchPopular>>>
export type GetSearchPopularQueryError = unknown

/**
 * @summary search popular words
 */
export const useGetSearchPopular = <TData = Awaited<ReturnType<typeof getSearchPopular>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSearchPopular>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchPopularQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary get the best-selling articles bought related to the article searched
 */
export const searchRelatedArticles = (
    params: SearchRelatedArticlesParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<SearchRelatedArticles200>(
      {url: `/api/search/relatedArticles`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getSearchRelatedArticlesQueryKey = (params: SearchRelatedArticlesParams,) => {
    return [`/api/search/relatedArticles`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchRelatedArticlesQueryOptions = <TData = Awaited<ReturnType<typeof searchRelatedArticles>>, TError = unknown>(params: SearchRelatedArticlesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchRelatedArticles>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchRelatedArticlesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchRelatedArticles>>> = ({ signal }) => searchRelatedArticles(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchRelatedArticles>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchRelatedArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof searchRelatedArticles>>>
export type SearchRelatedArticlesQueryError = unknown

/**
 * @summary get the best-selling articles bought related to the article searched
 */
export const useSearchRelatedArticles = <TData = Awaited<ReturnType<typeof searchRelatedArticles>>, TError = unknown>(
 params: SearchRelatedArticlesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchRelatedArticles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchRelatedArticlesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary get products by category
 */
export const productsByCategory = (
    categoryIdOrSlug: string,
    params?: ProductsByCategoryParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ProductsByCategory200>(
      {url: `/api/categories/${categoryIdOrSlug}/products`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getProductsByCategoryQueryKey = (categoryIdOrSlug: string,
    params?: ProductsByCategoryParams,) => {
    return [`/api/categories/${categoryIdOrSlug}/products`, ...(params ? [params]: [])] as const;
    }

    
export const getProductsByCategoryQueryOptions = <TData = Awaited<ReturnType<typeof productsByCategory>>, TError = unknown>(categoryIdOrSlug: string,
    params?: ProductsByCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof productsByCategory>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProductsByCategoryQueryKey(categoryIdOrSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof productsByCategory>>> = ({ signal }) => productsByCategory(categoryIdOrSlug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(categoryIdOrSlug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof productsByCategory>>, TError, TData> & { queryKey: QueryKey }
}

export type ProductsByCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof productsByCategory>>>
export type ProductsByCategoryQueryError = unknown

/**
 * @summary get products by category
 */
export const useProductsByCategory = <TData = Awaited<ReturnType<typeof productsByCategory>>, TError = unknown>(
 categoryIdOrSlug: string,
    params?: ProductsByCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof productsByCategory>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProductsByCategoryQueryOptions(categoryIdOrSlug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary returns all the product ids of a root category
 */
export const getProductIds = (
    categoryIdOrSlug: string,
    params?: GetProductIdsParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetProductIds200>(
      {url: `/api/categories/${categoryIdOrSlug}/productsBySubcategory`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetProductIdsQueryKey = (categoryIdOrSlug: string,
    params?: GetProductIdsParams,) => {
    return [`/api/categories/${categoryIdOrSlug}/productsBySubcategory`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductIdsQueryOptions = <TData = Awaited<ReturnType<typeof getProductIds>>, TError = unknown>(categoryIdOrSlug: string,
    params?: GetProductIdsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductIds>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductIdsQueryKey(categoryIdOrSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductIds>>> = ({ signal }) => getProductIds(categoryIdOrSlug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(categoryIdOrSlug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductIds>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductIdsQueryResult = NonNullable<Awaited<ReturnType<typeof getProductIds>>>
export type GetProductIdsQueryError = unknown

/**
 * @summary returns all the product ids of a root category
 */
export const useGetProductIds = <TData = Awaited<ReturnType<typeof getProductIds>>, TError = unknown>(
 categoryIdOrSlug: string,
    params?: GetProductIdsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductIds>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProductIdsQueryOptions(categoryIdOrSlug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get frequently bought products by a customer
 */
export const getFrequentProducts = (
    params?: GetFrequentProductsParams,
 ) => {
      
      
      return fetchAPI<GetFrequentProducts200>(
      {url: `/api/orders/frequentProducts`, method: 'GET',
        params
    },
      );
    }
  

export const getGetFrequentProductsQueryKey = (params?: GetFrequentProductsParams,) => {
    return [`/api/orders/frequentProducts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFrequentProductsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getFrequentProducts>>, TError = GetFrequentProducts400>(params?: GetFrequentProductsParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFrequentProductsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFrequentProducts>>> = ({ pageParam }) => getFrequentProducts({...params, offset: pageParam || params?.['offset']}, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFrequentProductsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFrequentProducts>>>
export type GetFrequentProductsInfiniteQueryError = GetFrequentProducts400

/**
 * @summary Get frequently bought products by a customer
 */
export const useGetFrequentProductsInfinite = <TData = Awaited<ReturnType<typeof getFrequentProducts>>, TError = GetFrequentProducts400>(
 params?: GetFrequentProductsParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFrequentProductsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFrequentProductsQueryOptions = <TData = Awaited<ReturnType<typeof getFrequentProducts>>, TError = GetFrequentProducts400>(params?: GetFrequentProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFrequentProductsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFrequentProducts>>> = () => getFrequentProducts(params, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFrequentProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getFrequentProducts>>>
export type GetFrequentProductsQueryError = GetFrequentProducts400

/**
 * @summary Get frequently bought products by a customer
 */
export const useGetFrequentProducts = <TData = Awaited<ReturnType<typeof getFrequentProducts>>, TError = GetFrequentProducts400>(
 params?: GetFrequentProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFrequentProducts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFrequentProductsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary site map
 */
export const getApiSitemapXml = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<void>(
      {url: `/api/sitemap.xml`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiSitemapXmlQueryKey = () => {
    return [`/api/sitemap.xml`] as const;
    }

    
export const getGetApiSitemapXmlQueryOptions = <TData = Awaited<ReturnType<typeof getApiSitemapXml>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSitemapXml>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiSitemapXmlQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSitemapXml>>> = ({ signal }) => getApiSitemapXml(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiSitemapXml>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiSitemapXmlQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSitemapXml>>>
export type GetApiSitemapXmlQueryError = unknown

/**
 * @summary site map
 */
export const useGetApiSitemapXml = <TData = Awaited<ReturnType<typeof getApiSitemapXml>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSitemapXml>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiSitemapXmlQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Products feed compatible with google merchant center and facebook catalogs
 */
export const getApiFeedsProductsTsv = (
    params?: GetApiFeedsProductsTsvParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<void>(
      {url: `/api/feeds/products.tsv`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiFeedsProductsTsvQueryKey = (params?: GetApiFeedsProductsTsvParams,) => {
    return [`/api/feeds/products.tsv`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiFeedsProductsTsvQueryOptions = <TData = Awaited<ReturnType<typeof getApiFeedsProductsTsv>>, TError = unknown>(params?: GetApiFeedsProductsTsvParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiFeedsProductsTsv>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiFeedsProductsTsvQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFeedsProductsTsv>>> = ({ signal }) => getApiFeedsProductsTsv(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiFeedsProductsTsv>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiFeedsProductsTsvQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFeedsProductsTsv>>>
export type GetApiFeedsProductsTsvQueryError = unknown

/**
 * @summary Products feed compatible with google merchant center and facebook catalogs
 */
export const useGetApiFeedsProductsTsv = <TData = Awaited<ReturnType<typeof getApiFeedsProductsTsv>>, TError = unknown>(
 params?: GetApiFeedsProductsTsvParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiFeedsProductsTsv>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiFeedsProductsTsvQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary create coupon from promo code 
 */
export const createCouponFromPromoCode = (
    promoCodeId: string,
    params?: CreateCouponFromPromoCodeParams,
 ) => {
      
      
      return fetchAPI<CreateCouponFromPromoCode200>(
      {url: `/api/promoCodes/${promoCodeId}/createCoupon`, method: 'POST',
        params
    },
      );
    }
  


export const getCreateCouponFromPromoCodeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCouponFromPromoCode>>, TError,{promoCodeId: string;params?: CreateCouponFromPromoCodeParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCouponFromPromoCode>>, TError,{promoCodeId: string;params?: CreateCouponFromPromoCodeParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCouponFromPromoCode>>, {promoCodeId: string;params?: CreateCouponFromPromoCodeParams}> = (props) => {
          const {promoCodeId,params} = props ?? {};

          return  createCouponFromPromoCode(promoCodeId,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCouponFromPromoCodeMutationResult = NonNullable<Awaited<ReturnType<typeof createCouponFromPromoCode>>>
    
    export type CreateCouponFromPromoCodeMutationError = unknown

    /**
 * @summary create coupon from promo code 
 */
export const useCreateCouponFromPromoCode = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCouponFromPromoCode>>, TError,{promoCodeId: string;params?: CreateCouponFromPromoCodeParams}, TContext>, }
) => {

      const mutationOptions = getCreateCouponFromPromoCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the special events (scheduling) of the current shop
 */
export const getSpecialEvents = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetSpecialEvents200>(
      {url: `/api/specialEvents`, method: 'GET', signal
    },
      );
    }
  

export const getGetSpecialEventsQueryKey = () => {
    return [`/api/specialEvents`] as const;
    }

    
export const getGetSpecialEventsQueryOptions = <TData = Awaited<ReturnType<typeof getSpecialEvents>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpecialEvents>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSpecialEventsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpecialEvents>>> = ({ signal }) => getSpecialEvents(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSpecialEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSpecialEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getSpecialEvents>>>
export type GetSpecialEventsQueryError = unknown

/**
 * @summary Get the special events (scheduling) of the current shop
 */
export const useGetSpecialEvents = <TData = Awaited<ReturnType<typeof getSpecialEvents>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpecialEvents>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSpecialEventsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary [DEPRECATED] Verify the current cart and return a list of potential actions needed
 */
export const recompute = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<Recompute200>(
      {url: `/api/cart/recompute`, method: 'GET', signal
    },
      );
    }
  

export const getRecomputeQueryKey = () => {
    return [`/api/cart/recompute`] as const;
    }

    
export const getRecomputeQueryOptions = <TData = Awaited<ReturnType<typeof recompute>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recompute>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRecomputeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof recompute>>> = ({ signal }) => recompute(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof recompute>>, TError, TData> & { queryKey: QueryKey }
}

export type RecomputeQueryResult = NonNullable<Awaited<ReturnType<typeof recompute>>>
export type RecomputeQueryError = unknown

/**
 * @deprecated
 * @summary [DEPRECATED] Verify the current cart and return a list of potential actions needed
 */
export const useRecompute = <TData = Awaited<ReturnType<typeof recompute>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recompute>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRecomputeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Verify the current cart and return it with a list of potential actions needed on each product
 */
export const recompute2 = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<Recompute2200>(
      {url: `/api/cart/recompute2`, method: 'GET', signal
    },
      );
    }
  

export const getRecompute2QueryKey = () => {
    return [`/api/cart/recompute2`] as const;
    }

    
export const getRecompute2QueryOptions = <TData = Awaited<ReturnType<typeof recompute2>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recompute2>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRecompute2QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof recompute2>>> = ({ signal }) => recompute2(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof recompute2>>, TError, TData> & { queryKey: QueryKey }
}

export type Recompute2QueryResult = NonNullable<Awaited<ReturnType<typeof recompute2>>>
export type Recompute2QueryError = unknown

/**
 * @summary Verify the current cart and return it with a list of potential actions needed on each product
 */
export const useRecompute2 = <TData = Awaited<ReturnType<typeof recompute2>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recompute2>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRecompute2QueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create a new cart or update the customer cart delivery information
 */
export const patchCartDelivery2 = (
    patchCartDelivery2Body: PatchCartDelivery2Body,
    params?: PatchCartDelivery2Params,
 ) => {
      
      
      return fetchAPI<PatchCartDelivery2200>(
      {url: `/api/cart/delivery2`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchCartDelivery2Body,
        params
    },
      );
    }
  


export const getPatchCartDelivery2MutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchCartDelivery2>>, TError,{data: PatchCartDelivery2Body;params?: PatchCartDelivery2Params}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchCartDelivery2>>, TError,{data: PatchCartDelivery2Body;params?: PatchCartDelivery2Params}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchCartDelivery2>>, {data: PatchCartDelivery2Body;params?: PatchCartDelivery2Params}> = (props) => {
          const {data,params} = props ?? {};

          return  patchCartDelivery2(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchCartDelivery2MutationResult = NonNullable<Awaited<ReturnType<typeof patchCartDelivery2>>>
    export type PatchCartDelivery2MutationBody = PatchCartDelivery2Body
    export type PatchCartDelivery2MutationError = unknown

    /**
 * @summary Create a new cart or update the customer cart delivery information
 */
export const usePatchCartDelivery2 = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchCartDelivery2>>, TError,{data: PatchCartDelivery2Body;params?: PatchCartDelivery2Params}, TContext>, }
) => {

      const mutationOptions = getPatchCartDelivery2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary returns the available delivery slots for an address
 */
export const deliverySlots = (
    deliverySlotsBody: DeliverySlotsBody,
 ) => {
      
      
      return fetchAPI<DeliverySlots200>(
      {url: `/api/addresses/deliverySlots2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliverySlotsBody
    },
      );
    }
  


export const getDeliverySlotsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deliverySlots>>, TError,{data: DeliverySlotsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deliverySlots>>, TError,{data: DeliverySlotsBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deliverySlots>>, {data: DeliverySlotsBody}> = (props) => {
          const {data} = props ?? {};

          return  deliverySlots(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeliverySlotsMutationResult = NonNullable<Awaited<ReturnType<typeof deliverySlots>>>
    export type DeliverySlotsMutationBody = DeliverySlotsBody
    export type DeliverySlotsMutationError = unknown

    /**
 * @summary returns the available delivery slots for an address
 */
export const useDeliverySlots = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deliverySlots>>, TError,{data: DeliverySlotsBody}, TContext>, }
) => {

      const mutationOptions = getDeliverySlotsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary get the best selling articles bought together with products in current cart
 */
export const cartRelatedArticles = (
    params: CartRelatedArticlesParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<CartRelatedArticles200>(
      {url: `/api/cart/relatedArticles`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCartRelatedArticlesQueryKey = (params: CartRelatedArticlesParams,) => {
    return [`/api/cart/relatedArticles`, ...(params ? [params]: [])] as const;
    }

    
export const getCartRelatedArticlesQueryOptions = <TData = Awaited<ReturnType<typeof cartRelatedArticles>>, TError = unknown>(params: CartRelatedArticlesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cartRelatedArticles>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCartRelatedArticlesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof cartRelatedArticles>>> = ({ signal }) => cartRelatedArticles(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof cartRelatedArticles>>, TError, TData> & { queryKey: QueryKey }
}

export type CartRelatedArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof cartRelatedArticles>>>
export type CartRelatedArticlesQueryError = unknown

/**
 * @summary get the best selling articles bought together with products in current cart
 */
export const useCartRelatedArticles = <TData = Awaited<ReturnType<typeof cartRelatedArticles>>, TError = unknown>(
 params: CartRelatedArticlesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cartRelatedArticles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCartRelatedArticlesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the current cart
 */
export const getCartByCustomerId = (
    getCartByCustomerIdBody: GetCartByCustomerIdBody,
 ) => {
      
      
      return fetchAPI<GetCartByCustomerId200>(
      {url: `/api/cart/getByCustomerId`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getCartByCustomerIdBody
    },
      );
    }
  


export const getGetCartByCustomerIdMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCartByCustomerId>>, TError,{data: GetCartByCustomerIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getCartByCustomerId>>, TError,{data: GetCartByCustomerIdBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCartByCustomerId>>, {data: GetCartByCustomerIdBody}> = (props) => {
          const {data} = props ?? {};

          return  getCartByCustomerId(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type GetCartByCustomerIdMutationResult = NonNullable<Awaited<ReturnType<typeof getCartByCustomerId>>>
    export type GetCartByCustomerIdMutationBody = GetCartByCustomerIdBody
    export type GetCartByCustomerIdMutationError = unknown

    /**
 * @summary Get the current cart
 */
export const useGetCartByCustomerId = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCartByCustomerId>>, TError,{data: GetCartByCustomerIdBody}, TContext>, }
) => {

      const mutationOptions = getGetCartByCustomerIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the cross-sell or upsell products for a given customer
 */
export const cartExternalRecommenders = (
    cartExternalRecommendersBody: CartExternalRecommendersBody,
 ) => {
      
      
      return fetchAPI<CartExternalRecommenders200>(
      {url: `/api/cart/external/recommenders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cartExternalRecommendersBody
    },
      );
    }
  


export const getCartExternalRecommendersMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cartExternalRecommenders>>, TError,{data: CartExternalRecommendersBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cartExternalRecommenders>>, TError,{data: CartExternalRecommendersBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cartExternalRecommenders>>, {data: CartExternalRecommendersBody}> = (props) => {
          const {data} = props ?? {};

          return  cartExternalRecommenders(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CartExternalRecommendersMutationResult = NonNullable<Awaited<ReturnType<typeof cartExternalRecommenders>>>
    export type CartExternalRecommendersMutationBody = CartExternalRecommendersBody
    export type CartExternalRecommendersMutationError = unknown

    /**
 * @summary Get the cross-sell or upsell products for a given customer
 */
export const useCartExternalRecommenders = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cartExternalRecommenders>>, TError,{data: CartExternalRecommendersBody}, TContext>, }
) => {

      const mutationOptions = getCartExternalRecommendersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create a payment intent for customer's current cart
 */
export const createPaymentIntent = (
    createPaymentIntentBody: CreatePaymentIntentBody,
 ) => {
      
      
      return fetchAPI<CreatePaymentIntent200>(
      {url: `/api/cart/createPaymentIntent`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createPaymentIntentBody
    },
      );
    }
  


export const getCreatePaymentIntentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPaymentIntent>>, TError,{data: CreatePaymentIntentBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createPaymentIntent>>, TError,{data: CreatePaymentIntentBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPaymentIntent>>, {data: CreatePaymentIntentBody}> = (props) => {
          const {data} = props ?? {};

          return  createPaymentIntent(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePaymentIntentMutationResult = NonNullable<Awaited<ReturnType<typeof createPaymentIntent>>>
    export type CreatePaymentIntentMutationBody = CreatePaymentIntentBody
    export type CreatePaymentIntentMutationError = unknown

    /**
 * @summary Create a payment intent for customer's current cart
 */
export const useCreatePaymentIntent = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPaymentIntent>>, TError,{data: CreatePaymentIntentBody}, TContext>, }
) => {

      const mutationOptions = getCreatePaymentIntentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * <br>      Three things can happen:<br>        - There is an error (insufficent funds, etc.) => the route returns a BusinessError<br>        - The payment succeeds (state: 'prepaid') => the route returns the paid order and a new empty cart to be used for later. The new empty cart may not be returned when there is an error creating it.<br>    
 * @summary Prepay the current cart
 */
export const finalizePrepay = (
    finalizePrepayBody: FinalizePrepayBody,
 ) => {
      
      
      return fetchAPI<FinalizePrepay200>(
      {url: `/api/cart/finalizePrepay`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: finalizePrepayBody
    },
      );
    }
  


export const getFinalizePrepayMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finalizePrepay>>, TError,{data: FinalizePrepayBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof finalizePrepay>>, TError,{data: FinalizePrepayBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof finalizePrepay>>, {data: FinalizePrepayBody}> = (props) => {
          const {data} = props ?? {};

          return  finalizePrepay(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FinalizePrepayMutationResult = NonNullable<Awaited<ReturnType<typeof finalizePrepay>>>
    export type FinalizePrepayMutationBody = FinalizePrepayBody
    export type FinalizePrepayMutationError = unknown

    /**
 * @summary Prepay the current cart
 */
export const useFinalizePrepay = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finalizePrepay>>, TError,{data: FinalizePrepayBody}, TContext>, }
) => {

      const mutationOptions = getFinalizePrepayMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update customer information before prepay
 */
export const updateCustomerInformation = (
    updateCustomerInformationBody: UpdateCustomerInformationBody,
    params?: UpdateCustomerInformationParams,
 ) => {
      
      
      return fetchAPI<UpdateCustomerInformation200>(
      {url: `/api/cart/updateCustomerInformation`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCustomerInformationBody,
        params
    },
      );
    }
  


export const getUpdateCustomerInformationMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomerInformation>>, TError,{data: UpdateCustomerInformationBody;params?: UpdateCustomerInformationParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateCustomerInformation>>, TError,{data: UpdateCustomerInformationBody;params?: UpdateCustomerInformationParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCustomerInformation>>, {data: UpdateCustomerInformationBody;params?: UpdateCustomerInformationParams}> = (props) => {
          const {data,params} = props ?? {};

          return  updateCustomerInformation(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCustomerInformationMutationResult = NonNullable<Awaited<ReturnType<typeof updateCustomerInformation>>>
    export type UpdateCustomerInformationMutationBody = UpdateCustomerInformationBody
    export type UpdateCustomerInformationMutationError = unknown

    /**
 * @summary Update customer information before prepay
 */
export const useUpdateCustomerInformation = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomerInformation>>, TError,{data: UpdateCustomerInformationBody;params?: UpdateCustomerInformationParams}, TContext>, }
) => {

      const mutationOptions = getUpdateCustomerInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get deliveryZone by id with computed timeSlots
 */
export const getDeliveryZone = (
    deliveryZoneId: string,
    params?: GetDeliveryZoneParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetDeliveryZone200>(
      {url: `/api/deliveryZones/${deliveryZoneId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetDeliveryZoneQueryKey = (deliveryZoneId: string,
    params?: GetDeliveryZoneParams,) => {
    return [`/api/deliveryZones/${deliveryZoneId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDeliveryZoneQueryOptions = <TData = Awaited<ReturnType<typeof getDeliveryZone>>, TError = unknown>(deliveryZoneId: string,
    params?: GetDeliveryZoneParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDeliveryZone>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeliveryZoneQueryKey(deliveryZoneId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeliveryZone>>> = ({ signal }) => getDeliveryZone(deliveryZoneId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(deliveryZoneId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDeliveryZone>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDeliveryZoneQueryResult = NonNullable<Awaited<ReturnType<typeof getDeliveryZone>>>
export type GetDeliveryZoneQueryError = unknown

/**
 * @summary Get deliveryZone by id with computed timeSlots
 */
export const useGetDeliveryZone = <TData = Awaited<ReturnType<typeof getDeliveryZone>>, TError = unknown>(
 deliveryZoneId: string,
    params?: GetDeliveryZoneParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDeliveryZone>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDeliveryZoneQueryOptions(deliveryZoneId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Track the payment events, so we can we make stats on which payments succeeded or failed, and why
 * @summary Track payment events
 */
export const trackPayment = (
    trackPaymentBody: TrackPaymentBody,
 ) => {
      
      
      return fetchAPI<TrackPayment200>(
      {url: `/api/cart/trackPayment`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: trackPaymentBody
    },
      );
    }
  


export const getTrackPaymentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trackPayment>>, TError,{data: TrackPaymentBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof trackPayment>>, TError,{data: TrackPaymentBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trackPayment>>, {data: TrackPaymentBody}> = (props) => {
          const {data} = props ?? {};

          return  trackPayment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type TrackPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof trackPayment>>>
    export type TrackPaymentMutationBody = TrackPaymentBody
    export type TrackPaymentMutationError = unknown

    /**
 * @summary Track payment events
 */
export const useTrackPayment = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trackPayment>>, TError,{data: TrackPaymentBody}, TContext>, }
) => {

      const mutationOptions = getTrackPaymentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary find substitutes for an article
 */
export const getArticleSubstitutes = (
    slug: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetArticleSubstitutes200>(
      {url: `/api/articles/${slug}/substitutes`, method: 'GET', signal
    },
      );
    }
  

export const getGetArticleSubstitutesQueryKey = (slug: string,) => {
    return [`/api/articles/${slug}/substitutes`] as const;
    }

    
export const getGetArticleSubstitutesQueryOptions = <TData = Awaited<ReturnType<typeof getArticleSubstitutes>>, TError = unknown>(slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticleSubstitutes>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticleSubstitutesQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticleSubstitutes>>> = ({ signal }) => getArticleSubstitutes(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getArticleSubstitutes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArticleSubstitutesQueryResult = NonNullable<Awaited<ReturnType<typeof getArticleSubstitutes>>>
export type GetArticleSubstitutesQueryError = unknown

/**
 * @summary find substitutes for an article
 */
export const useGetArticleSubstitutes = <TData = Awaited<ReturnType<typeof getArticleSubstitutes>>, TError = unknown>(
 slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticleSubstitutes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticleSubstitutesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary register prospect address in waiting list
 */
export const addProspectAddress = (
    addProspectAddressBody: AddProspectAddressBody,
 ) => {
      
      
      return fetchAPI<AddProspectAddress200>(
      {url: `/api/addresses/prospects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addProspectAddressBody
    },
      );
    }
  


export const getAddProspectAddressMutationOptions = <TError = AddProspectAddress400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProspectAddress>>, TError,{data: AddProspectAddressBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProspectAddress>>, TError,{data: AddProspectAddressBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProspectAddress>>, {data: AddProspectAddressBody}> = (props) => {
          const {data} = props ?? {};

          return  addProspectAddress(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProspectAddressMutationResult = NonNullable<Awaited<ReturnType<typeof addProspectAddress>>>
    export type AddProspectAddressMutationBody = AddProspectAddressBody
    export type AddProspectAddressMutationError = AddProspectAddress400

    /**
 * @summary register prospect address in waiting list
 */
export const useAddProspectAddress = <TError = AddProspectAddress400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProspectAddress>>, TError,{data: AddProspectAddressBody}, TContext>, }
) => {

      const mutationOptions = getAddProspectAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets a recipe by its id or slug
 */
export const getRecipeByIdOrSlug = (
    idOrSlug: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetRecipeByIdOrSlug200>(
      {url: `/api/recipe/${idOrSlug}`, method: 'GET', signal
    },
      );
    }
  

export const getGetRecipeByIdOrSlugQueryKey = (idOrSlug: string,) => {
    return [`/api/recipe/${idOrSlug}`] as const;
    }

    
export const getGetRecipeByIdOrSlugQueryOptions = <TData = Awaited<ReturnType<typeof getRecipeByIdOrSlug>>, TError = unknown>(idOrSlug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecipeByIdOrSlug>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecipeByIdOrSlugQueryKey(idOrSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecipeByIdOrSlug>>> = ({ signal }) => getRecipeByIdOrSlug(idOrSlug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(idOrSlug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecipeByIdOrSlug>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRecipeByIdOrSlugQueryResult = NonNullable<Awaited<ReturnType<typeof getRecipeByIdOrSlug>>>
export type GetRecipeByIdOrSlugQueryError = unknown

/**
 * @summary Gets a recipe by its id or slug
 */
export const useGetRecipeByIdOrSlug = <TData = Awaited<ReturnType<typeof getRecipeByIdOrSlug>>, TError = unknown>(
 idOrSlug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecipeByIdOrSlug>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRecipeByIdOrSlugQueryOptions(idOrSlug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Gets products informations from a recipe (including the products disabled)
 */
export const getRecipeAllProducts = (
    idOrSlug: string,
    getRecipeAllProductsBody: GetRecipeAllProductsBody,
 ) => {
      
      
      return fetchAPI<GetRecipeAllProducts200>(
      {url: `/api/recipe/${idOrSlug}/allProducts`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: getRecipeAllProductsBody
    },
      );
    }
  


export const getGetRecipeAllProductsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getRecipeAllProducts>>, TError,{idOrSlug: string;data: GetRecipeAllProductsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getRecipeAllProducts>>, TError,{idOrSlug: string;data: GetRecipeAllProductsBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getRecipeAllProducts>>, {idOrSlug: string;data: GetRecipeAllProductsBody}> = (props) => {
          const {idOrSlug,data} = props ?? {};

          return  getRecipeAllProducts(idOrSlug,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type GetRecipeAllProductsMutationResult = NonNullable<Awaited<ReturnType<typeof getRecipeAllProducts>>>
    export type GetRecipeAllProductsMutationBody = GetRecipeAllProductsBody
    export type GetRecipeAllProductsMutationError = unknown

    /**
 * @summary Gets products informations from a recipe (including the products disabled)
 */
export const useGetRecipeAllProducts = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getRecipeAllProducts>>, TError,{idOrSlug: string;data: GetRecipeAllProductsBody}, TContext>, }
) => {

      const mutationOptions = getGetRecipeAllProductsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Ingestion de données de perfs à partir du front
 */
export const vitals = (
    vitalsBody: VitalsBody,
 ) => {
      
      
      return fetchAPI<Vitals200>(
      {url: `/api/vitals`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: vitalsBody
    },
      );
    }
  


export const getVitalsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vitals>>, TError,{data: VitalsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof vitals>>, TError,{data: VitalsBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof vitals>>, {data: VitalsBody}> = (props) => {
          const {data} = props ?? {};

          return  vitals(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VitalsMutationResult = NonNullable<Awaited<ReturnType<typeof vitals>>>
    export type VitalsMutationBody = VitalsBody
    export type VitalsMutationError = unknown

    /**
 * @summary Ingestion de données de perfs à partir du front
 */
export const useVitals = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof vitals>>, TError,{data: VitalsBody}, TContext>, }
) => {

      const mutationOptions = getVitalsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Checks that the current timeSlot is valid and/or automatically assign a new one
 */
export const verifyDelivery = (
    verifyDeliveryBody: VerifyDeliveryBody,
    params?: VerifyDeliveryParams,
 ) => {
      
      
      return fetchAPI<VerifyDelivery200>(
      {url: `/api/cart/delivery/verify`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: verifyDeliveryBody,
        params
    },
      );
    }
  


export const getVerifyDeliveryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyDelivery>>, TError,{data: VerifyDeliveryBody;params?: VerifyDeliveryParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof verifyDelivery>>, TError,{data: VerifyDeliveryBody;params?: VerifyDeliveryParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyDelivery>>, {data: VerifyDeliveryBody;params?: VerifyDeliveryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  verifyDelivery(data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VerifyDeliveryMutationResult = NonNullable<Awaited<ReturnType<typeof verifyDelivery>>>
    export type VerifyDeliveryMutationBody = VerifyDeliveryBody
    export type VerifyDeliveryMutationError = unknown

    /**
 * @summary Checks that the current timeSlot is valid and/or automatically assign a new one
 */
export const useVerifyDelivery = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyDelivery>>, TError,{data: VerifyDeliveryBody;params?: VerifyDeliveryParams}, TContext>, }
) => {

      const mutationOptions = getVerifyDeliveryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary allow customers to add article proposals
 */
export const addArticleProposal = (
    addArticleProposalBody: AddArticleProposalBody,
 ) => {
      
      
      return fetchAPI<AddArticleProposal204>(
      {url: `/api/articles/proposal`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addArticleProposalBody
    },
      );
    }
  


export const getAddArticleProposalMutationOptions = <TError = AddArticleProposal400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addArticleProposal>>, TError,{data: AddArticleProposalBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addArticleProposal>>, TError,{data: AddArticleProposalBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addArticleProposal>>, {data: AddArticleProposalBody}> = (props) => {
          const {data} = props ?? {};

          return  addArticleProposal(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddArticleProposalMutationResult = NonNullable<Awaited<ReturnType<typeof addArticleProposal>>>
    export type AddArticleProposalMutationBody = AddArticleProposalBody
    export type AddArticleProposalMutationError = AddArticleProposal400

    /**
 * @summary allow customers to add article proposals
 */
export const useAddArticleProposal = <TError = AddArticleProposal400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addArticleProposal>>, TError,{data: AddArticleProposalBody}, TContext>, }
) => {

      const mutationOptions = getAddArticleProposalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Gets all navigation: families, their categories and sub-categories and the root recipe category
 */
export const navigation = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<Navigation200>(
      {url: `/api/navigation`, method: 'GET', signal
    },
      );
    }
  

export const getNavigationQueryKey = () => {
    return [`/api/navigation`] as const;
    }

    
export const getNavigationQueryOptions = <TData = Awaited<ReturnType<typeof navigation>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof navigation>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNavigationQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof navigation>>> = ({ signal }) => navigation(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof navigation>>, TError, TData> & { queryKey: QueryKey }
}

export type NavigationQueryResult = NonNullable<Awaited<ReturnType<typeof navigation>>>
export type NavigationQueryError = unknown

/**
 * @summary Gets all navigation: families, their categories and sub-categories and the root recipe category
 */
export const useNavigation = <TData = Awaited<ReturnType<typeof navigation>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof navigation>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNavigationQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Trustpilot WebHook handler. The api-ecom part will only check auth then transfer to catalog
 * @summary Trustpilot webhook
 */
export const postApiWebhooksTrustpilot = (
    
 ) => {
      
      
      return fetchAPI<void>(
      {url: `/api/webhooks/trustpilot`, method: 'POST'
    },
      );
    }
  


export const getPostApiWebhooksTrustpilotMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksTrustpilot>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksTrustpilot>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWebhooksTrustpilot>>, void> = () => {
          

          return  postApiWebhooksTrustpilot()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiWebhooksTrustpilotMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWebhooksTrustpilot>>>
    
    export type PostApiWebhooksTrustpilotMutationError = unknown

    /**
 * @summary Trustpilot webhook
 */
export const usePostApiWebhooksTrustpilot = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksTrustpilot>>, TError,void, TContext>, }
) => {

      const mutationOptions = getPostApiWebhooksTrustpilotMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel a prepaid order
 */
export const cancelOrder = (
    orderId: string,
 ) => {
      
      
      return fetchAPI<CancelOrder200>(
      {url: `/api/orders/${orderId}/cancel`, method: 'PUT'
    },
      );
    }
  


export const getCancelOrderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelOrder>>, TError,{orderId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cancelOrder>>, TError,{orderId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelOrder>>, {orderId: string}> = (props) => {
          const {orderId} = props ?? {};

          return  cancelOrder(orderId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CancelOrderMutationResult = NonNullable<Awaited<ReturnType<typeof cancelOrder>>>
    
    export type CancelOrderMutationError = unknown

    /**
 * @summary Cancel a prepaid order
 */
export const useCancelOrder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelOrder>>, TError,{orderId: string}, TContext>, }
) => {

      const mutationOptions = getCancelOrderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary [DEPRECATED] Get all orders of the customer
 */
export const listOrders = (
    params?: ListOrdersParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ListOrders200>(
      {url: `/api/orders`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListOrdersQueryKey = (params?: ListOrdersParams,) => {
    return [`/api/orders`, ...(params ? [params]: [])] as const;
    }

    
export const getListOrdersQueryOptions = <TData = Awaited<ReturnType<typeof listOrders>>, TError = unknown>(params?: ListOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrders>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrdersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrders>>> = ({ signal }) => listOrders(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listOrders>>, TError, TData> & { queryKey: QueryKey }
}

export type ListOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof listOrders>>>
export type ListOrdersQueryError = unknown

/**
 * @deprecated
 * @summary [DEPRECATED] Get all orders of the customer
 */
export const useListOrders = <TData = Awaited<ReturnType<typeof listOrders>>, TError = unknown>(
 params?: ListOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListOrdersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get all the current ongoing orders of the customer and the total number of orders
 */
export const currentOrders = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<CurrentOrders200>(
      {url: `/api/orders/current`, method: 'GET', signal
    },
      );
    }
  

export const getCurrentOrdersQueryKey = () => {
    return [`/api/orders/current`] as const;
    }

    
export const getCurrentOrdersQueryOptions = <TData = Awaited<ReturnType<typeof currentOrders>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof currentOrders>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentOrdersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentOrders>>> = ({ signal }) => currentOrders(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof currentOrders>>, TError, TData> & { queryKey: QueryKey }
}

export type CurrentOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof currentOrders>>>
export type CurrentOrdersQueryError = unknown

/**
 * @summary Get all the current ongoing orders of the customer and the total number of orders
 */
export const useCurrentOrders = <TData = Awaited<ReturnType<typeof currentOrders>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof currentOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCurrentOrdersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary [DEPRECATED] Get all orders of the customer with the products current details.
 */
export const listOrdersWithProducts = (
    params?: ListOrdersWithProductsParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ListOrdersWithProducts200>(
      {url: `/api/orders/products`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListOrdersWithProductsQueryKey = (params?: ListOrdersWithProductsParams,) => {
    return [`/api/orders/products`, ...(params ? [params]: [])] as const;
    }

    
export const getListOrdersWithProductsQueryOptions = <TData = Awaited<ReturnType<typeof listOrdersWithProducts>>, TError = unknown>(params?: ListOrdersWithProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrdersWithProducts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrdersWithProductsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrdersWithProducts>>> = ({ signal }) => listOrdersWithProducts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listOrdersWithProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type ListOrdersWithProductsQueryResult = NonNullable<Awaited<ReturnType<typeof listOrdersWithProducts>>>
export type ListOrdersWithProductsQueryError = unknown

/**
 * @deprecated
 * @summary [DEPRECATED] Get all orders of the customer with the products current details.
 */
export const useListOrdersWithProducts = <TData = Awaited<ReturnType<typeof listOrdersWithProducts>>, TError = unknown>(
 params?: ListOrdersWithProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrdersWithProducts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListOrdersWithProductsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get an order of the customer
 */
export const getOrder = (
    orderId: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetOrder200>(
      {url: `/api/orders/${orderId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetOrderQueryKey = (orderId: string,) => {
    return [`/api/orders/${orderId}`] as const;
    }

    
export const getGetOrderQueryOptions = <TData = Awaited<ReturnType<typeof getOrder>>, TError = unknown>(orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrder>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderQueryKey(orderId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrder>>> = ({ signal }) => getOrder(orderId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrder>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getOrder>>>
export type GetOrderQueryError = unknown

/**
 * @summary Get an order of the customer
 */
export const useGetOrder = <TData = Awaited<ReturnType<typeof getOrder>>, TError = unknown>(
 orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrder>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrderQueryOptions(orderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update the timeslot of an order
 */
export const updateOrderTimeSlot = (
    orderId: string,
    updateOrderTimeSlotBody: UpdateOrderTimeSlotBody,
 ) => {
      
      
      return fetchAPI<UpdateOrderTimeSlot200>(
      {url: `/api/orders/${orderId}/updateTimeSlot`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateOrderTimeSlotBody
    },
      );
    }
  


export const getUpdateOrderTimeSlotMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrderTimeSlot>>, TError,{orderId: string;data: UpdateOrderTimeSlotBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateOrderTimeSlot>>, TError,{orderId: string;data: UpdateOrderTimeSlotBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrderTimeSlot>>, {orderId: string;data: UpdateOrderTimeSlotBody}> = (props) => {
          const {orderId,data} = props ?? {};

          return  updateOrderTimeSlot(orderId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateOrderTimeSlotMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrderTimeSlot>>>
    export type UpdateOrderTimeSlotMutationBody = UpdateOrderTimeSlotBody
    export type UpdateOrderTimeSlotMutationError = unknown

    /**
 * @summary Update the timeslot of an order
 */
export const useUpdateOrderTimeSlot = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrderTimeSlot>>, TError,{orderId: string;data: UpdateOrderTimeSlotBody}, TContext>, }
) => {

      const mutationOptions = getUpdateOrderTimeSlotMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the invoice download url of an order.
 */
export const getInvoiceDownloadUrl = (
    orderId: string,
    invoiceId: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetInvoiceDownloadUrl200>(
      {url: `/api/orders/${orderId}/invoices/${invoiceId}/downloadUrl`, method: 'GET', signal
    },
      );
    }
  

export const getGetInvoiceDownloadUrlQueryKey = (orderId: string,
    invoiceId: string,) => {
    return [`/api/orders/${orderId}/invoices/${invoiceId}/downloadUrl`] as const;
    }

    
export const getGetInvoiceDownloadUrlQueryOptions = <TData = Awaited<ReturnType<typeof getInvoiceDownloadUrl>>, TError = unknown>(orderId: string,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceDownloadUrl>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoiceDownloadUrlQueryKey(orderId,invoiceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceDownloadUrl>>> = ({ signal }) => getInvoiceDownloadUrl(orderId,invoiceId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId && invoiceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInvoiceDownloadUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInvoiceDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceDownloadUrl>>>
export type GetInvoiceDownloadUrlQueryError = unknown

export const useGetInvoiceDownloadUrl = <TData = Awaited<ReturnType<typeof getInvoiceDownloadUrl>>, TError = unknown>(
 orderId: string,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceDownloadUrl>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoiceDownloadUrlQueryOptions(orderId,invoiceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary If an order is not prepared, cancels the order and returns it as a new cart.
 */
export const modifyOrder = (
    orderId: string,
    params?: ModifyOrderParams,
 ) => {
      
      
      return fetchAPI<ModifyOrder200>(
      {url: `/api/orders/${orderId}/modify`, method: 'PUT',
        params
    },
      );
    }
  


export const getModifyOrderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyOrder>>, TError,{orderId: string;params?: ModifyOrderParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof modifyOrder>>, TError,{orderId: string;params?: ModifyOrderParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof modifyOrder>>, {orderId: string;params?: ModifyOrderParams}> = (props) => {
          const {orderId,params} = props ?? {};

          return  modifyOrder(orderId,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ModifyOrderMutationResult = NonNullable<Awaited<ReturnType<typeof modifyOrder>>>
    
    export type ModifyOrderMutationError = unknown

    /**
 * @summary If an order is not prepared, cancels the order and returns it as a new cart.
 */
export const useModifyOrder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyOrder>>, TError,{orderId: string;params?: ModifyOrderParams}, TContext>, }
) => {

      const mutationOptions = getModifyOrderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the delivery informations of the next kepler task to be delivered
 */
export const getTaskDeliveryInfos = (
    getTaskDeliveryInfosBody: GetTaskDeliveryInfosBody,
 ) => {
      
      
      return fetchAPI<GetTaskDeliveryInfos200>(
      {url: `/api/orders/taskDeliveryInfos`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getTaskDeliveryInfosBody
    },
      );
    }
  


export const getGetTaskDeliveryInfosMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskDeliveryInfos>>, TError,{data: GetTaskDeliveryInfosBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getTaskDeliveryInfos>>, TError,{data: GetTaskDeliveryInfosBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getTaskDeliveryInfos>>, {data: GetTaskDeliveryInfosBody}> = (props) => {
          const {data} = props ?? {};

          return  getTaskDeliveryInfos(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type GetTaskDeliveryInfosMutationResult = NonNullable<Awaited<ReturnType<typeof getTaskDeliveryInfos>>>
    export type GetTaskDeliveryInfosMutationBody = GetTaskDeliveryInfosBody
    export type GetTaskDeliveryInfosMutationError = unknown

    /**
 * @summary Get the delivery informations of the next kepler task to be delivered
 */
export const useGetTaskDeliveryInfos = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskDeliveryInfos>>, TError,{data: GetTaskDeliveryInfosBody}, TContext>, }
) => {

      const mutationOptions = getGetTaskDeliveryInfosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the past orders of the customer
 */
export const pastOrders = (
    params?: PastOrdersParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<PastOrders200>(
      {url: `/api/orders/past`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getPastOrdersQueryKey = (params?: PastOrdersParams,) => {
    return [`/api/orders/past`, ...(params ? [params]: [])] as const;
    }

    
export const getPastOrdersQueryOptions = <TData = Awaited<ReturnType<typeof pastOrders>>, TError = unknown>(params?: PastOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pastOrders>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPastOrdersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pastOrders>>> = ({ signal }) => pastOrders(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pastOrders>>, TError, TData> & { queryKey: QueryKey }
}

export type PastOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof pastOrders>>>
export type PastOrdersQueryError = unknown

/**
 * @summary Get the past orders of the customer
 */
export const usePastOrders = <TData = Awaited<ReturnType<typeof pastOrders>>, TError = unknown>(
 params?: PastOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof pastOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPastOrdersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the last order of the customer, ongoing or past one
 */
export const lastOrder = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<LastOrder200>(
      {url: `/api/orders/last`, method: 'GET', signal
    },
      );
    }
  

export const getLastOrderQueryKey = () => {
    return [`/api/orders/last`] as const;
    }

    
export const getLastOrderQueryOptions = <TData = Awaited<ReturnType<typeof lastOrder>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof lastOrder>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLastOrderQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof lastOrder>>> = ({ signal }) => lastOrder(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof lastOrder>>, TError, TData> & { queryKey: QueryKey }
}

export type LastOrderQueryResult = NonNullable<Awaited<ReturnType<typeof lastOrder>>>
export type LastOrderQueryError = unknown

/**
 * @summary Get the last order of the customer, ongoing or past one
 */
export const useLastOrder = <TData = Awaited<ReturnType<typeof lastOrder>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof lastOrder>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLastOrderQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the products of an order grouped and sorted by catman categories.
 */
export const getOrderProducts = (
    orderId: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetOrderProducts200>(
      {url: `/api/orders/${orderId}/products`, method: 'GET', signal
    },
      );
    }
  

export const getGetOrderProductsQueryKey = (orderId: string,) => {
    return [`/api/orders/${orderId}/products`] as const;
    }

    
export const getGetOrderProductsQueryOptions = <TData = Awaited<ReturnType<typeof getOrderProducts>>, TError = unknown>(orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrderProducts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderProductsQueryKey(orderId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderProducts>>> = ({ signal }) => getOrderProducts(orderId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrderProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderProducts>>>
export type GetOrderProductsQueryError = unknown

/**
 * @summary Get the products of an order grouped and sorted by catman categories.
 */
export const useGetOrderProducts = <TData = Awaited<ReturnType<typeof getOrderProducts>>, TError = unknown>(
 orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrderProducts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrderProductsQueryOptions(orderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Register a new credit card
 */
export const createCard = (
    createCardBody: CreateCardBody,
 ) => {
      
      
      return fetchAPI<CreateCard200>(
      {url: `/api/account/cards`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCardBody
    },
      );
    }
  


export const getCreateCardMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{data: CreateCardBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{data: CreateCardBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCard>>, {data: CreateCardBody}> = (props) => {
          const {data} = props ?? {};

          return  createCard(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCardMutationResult = NonNullable<Awaited<ReturnType<typeof createCard>>>
    export type CreateCardMutationBody = CreateCardBody
    export type CreateCardMutationError = unknown

    /**
 * @summary Register a new credit card
 */
export const useCreateCard = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCard>>, TError,{data: CreateCardBody}, TContext>, }
) => {

      const mutationOptions = getCreateCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List all customer credit cards
 */
export const listCards = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ListCards200>(
      {url: `/api/account/cards`, method: 'GET', signal
    },
      );
    }
  

export const getListCardsQueryKey = () => {
    return [`/api/account/cards`] as const;
    }

    
export const getListCardsQueryOptions = <TData = Awaited<ReturnType<typeof listCards>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCards>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCardsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCards>>> = ({ signal }) => listCards(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCards>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCardsQueryResult = NonNullable<Awaited<ReturnType<typeof listCards>>>
export type ListCardsQueryError = unknown

/**
 * @summary List all customer credit cards
 */
export const useListCards = <TData = Awaited<ReturnType<typeof listCards>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCards>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCardsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete a credit card
 */
export const deleteCard = (
    cardId: string,
 ) => {
      
      
      return fetchAPI<DeleteCard200>(
      {url: `/api/account/cards/${cardId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCardMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{cardId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{cardId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  deleteCard(cardId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCard>>>
    
    export type DeleteCardMutationError = unknown

    /**
 * @summary Delete a credit card
 */
export const useDeleteCard = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCard>>, TError,{cardId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Change the default credit card
 */
export const setDefaultCard = (
    setDefaultCardBody: SetDefaultCardBody,
 ) => {
      
      
      return fetchAPI<SetDefaultCard200>(
      {url: `/api/account/cards/default`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: setDefaultCardBody
    },
      );
    }
  


export const getSetDefaultCardMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCard>>, TError,{data: SetDefaultCardBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof setDefaultCard>>, TError,{data: SetDefaultCardBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setDefaultCard>>, {data: SetDefaultCardBody}> = (props) => {
          const {data} = props ?? {};

          return  setDefaultCard(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SetDefaultCardMutationResult = NonNullable<Awaited<ReturnType<typeof setDefaultCard>>>
    export type SetDefaultCardMutationBody = SetDefaultCardBody
    export type SetDefaultCardMutationError = unknown

    /**
 * @summary Change the default credit card
 */
export const useSetDefaultCard = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCard>>, TError,{data: SetDefaultCardBody}, TContext>, }
) => {

      const mutationOptions = getSetDefaultCardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create customer addresses
 */
export const createAddress = (
    createAddressBody: CreateAddressBody,
 ) => {
      
      
      return fetchAPI<CreateAddress200>(
      {url: `/api/account/addresses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createAddressBody
    },
      );
    }
  


export const getCreateAddressMutationOptions = <TError = CreateAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError,{data: CreateAddressBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError,{data: CreateAddressBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAddress>>, {data: CreateAddressBody}> = (props) => {
          const {data} = props ?? {};

          return  createAddress(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof createAddress>>>
    export type CreateAddressMutationBody = CreateAddressBody
    export type CreateAddressMutationError = CreateAddress401

    /**
 * @summary Create customer addresses
 */
export const useCreateAddress = <TError = CreateAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError,{data: CreateAddressBody}, TContext>, }
) => {

      const mutationOptions = getCreateAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List all customer addresses
 */
export const listAddresses = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ListAddresses200>(
      {url: `/api/account/addresses`, method: 'GET', signal
    },
      );
    }
  

export const getListAddressesQueryKey = () => {
    return [`/api/account/addresses`] as const;
    }

    
export const getListAddressesQueryOptions = <TData = Awaited<ReturnType<typeof listAddresses>>, TError = ListAddresses401>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAddressesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAddresses>>> = ({ signal }) => listAddresses(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof listAddresses>>>
export type ListAddressesQueryError = ListAddresses401

/**
 * @summary List all customer addresses
 */
export const useListAddresses = <TData = Awaited<ReturnType<typeof listAddresses>>, TError = ListAddresses401>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAddressesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete customer address
 */
export const deleteAddress = (
    addressId: string,
 ) => {
      
      
      return fetchAPI<DeleteAddress200>(
      {url: `/api/account/addresses/${addressId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteAddressMutationOptions = <TError = DeleteAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAddress>>, {addressId: string}> = (props) => {
          const {addressId} = props ?? {};

          return  deleteAddress(addressId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAddressMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAddress>>>
    
    export type DeleteAddressMutationError = DeleteAddress401

    /**
 * @summary Delete customer address
 */
export const useDeleteAddress = <TError = DeleteAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update customer addresses
 */
export const updateAddress = (
    addressId: string,
    updateAddressBody: UpdateAddressBody,
 ) => {
      
      
      return fetchAPI<UpdateAddress200>(
      {url: `/api/account/addresses/${addressId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateAddressBody
    },
      );
    }
  


export const getUpdateAddressMutationOptions = <TError = UpdateAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAddress>>, TError,{addressId: string;data: UpdateAddressBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAddress>>, TError,{addressId: string;data: UpdateAddressBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAddress>>, {addressId: string;data: UpdateAddressBody}> = (props) => {
          const {addressId,data} = props ?? {};

          return  updateAddress(addressId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof updateAddress>>>
    export type UpdateAddressMutationBody = UpdateAddressBody
    export type UpdateAddressMutationError = UpdateAddress401

    /**
 * @summary Update customer addresses
 */
export const useUpdateAddress = <TError = UpdateAddress401,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAddress>>, TError,{addressId: string;data: UpdateAddressBody}, TContext>, }
) => {

      const mutationOptions = getUpdateAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List all coupons for the customer, active or expired
 */
export const listAllCoupons = (
    params: ListAllCouponsParams,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<ListAllCoupons200>(
      {url: `/api/account/allCoupons`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListAllCouponsQueryKey = (params: ListAllCouponsParams,) => {
    return [`/api/account/allCoupons`, ...(params ? [params]: [])] as const;
    }

    
export const getListAllCouponsQueryOptions = <TData = Awaited<ReturnType<typeof listAllCoupons>>, TError = ListAllCoupons401>(params: ListAllCouponsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllCoupons>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllCouponsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllCoupons>>> = ({ signal }) => listAllCoupons(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAllCoupons>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAllCouponsQueryResult = NonNullable<Awaited<ReturnType<typeof listAllCoupons>>>
export type ListAllCouponsQueryError = ListAllCoupons401

/**
 * @summary List all coupons for the customer, active or expired
 */
export const useListAllCoupons = <TData = Awaited<ReturnType<typeof listAllCoupons>>, TError = ListAllCoupons401>(
 params: ListAllCouponsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllCoupons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllCouponsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List all valid coupons for the customer
 */
export const getApiAccountCoupons = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetApiAccountCoupons200>(
      {url: `/api/account/coupons`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiAccountCouponsQueryKey = () => {
    return [`/api/account/coupons`] as const;
    }

    
export const getGetApiAccountCouponsQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountCoupons>>, TError = GetApiAccountCoupons401>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAccountCoupons>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAccountCouponsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountCoupons>>> = ({ signal }) => getApiAccountCoupons(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountCoupons>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAccountCouponsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountCoupons>>>
export type GetApiAccountCouponsQueryError = GetApiAccountCoupons401

/**
 * @summary List all valid coupons for the customer
 */
export const useGetApiAccountCoupons = <TData = Awaited<ReturnType<typeof getApiAccountCoupons>>, TError = GetApiAccountCoupons401>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAccountCoupons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiAccountCouponsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update the customer
 * @summary Update the customer
 */
export const updateCustomer = (
    updateCustomerBody: UpdateCustomerBody,
 ) => {
      
      
      return fetchAPI<UpdateCustomer200>(
      {url: `/api/account`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateCustomerBody
    },
      );
    }
  


export const getUpdateCustomerMutationOptions = <TError = UpdateCustomer400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomer>>, TError,{data: UpdateCustomerBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateCustomer>>, TError,{data: UpdateCustomerBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCustomer>>, {data: UpdateCustomerBody}> = (props) => {
          const {data} = props ?? {};

          return  updateCustomer(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof updateCustomer>>>
    export type UpdateCustomerMutationBody = UpdateCustomerBody
    export type UpdateCustomerMutationError = UpdateCustomer400

    /**
 * @summary Update the customer
 */
export const useUpdateCustomer = <TError = UpdateCustomer400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomer>>, TError,{data: UpdateCustomerBody}, TContext>, }
) => {

      const mutationOptions = getUpdateCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Soft deletes the customer
 * @summary Soft deletes the customer
 */
export const deleteCustomer = (
    
 ) => {
      
      
      return fetchAPI<DeleteCustomer204>(
      {url: `/api/account`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCustomerMutationOptions = <TError = DeleteCustomer400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCustomer>>, void> = () => {
          

          return  deleteCustomer()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCustomer>>>
    
    export type DeleteCustomerMutationError = DeleteCustomer400

    /**
 * @summary Soft deletes the customer
 */
export const useDeleteCustomer = <TError = DeleteCustomer400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,void, TContext>, }
) => {

      const mutationOptions = getDeleteCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update the customer
 * @summary Update the customer
 */
export const updateCustomer2 = (
    updateCustomer2Body: UpdateCustomer2Body,
 ) => {
      
      
      return fetchAPI<UpdateCustomer2200>(
      {url: `/api/account`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCustomer2Body
    },
      );
    }
  


export const getUpdateCustomer2MutationOptions = <TError = UpdateCustomer2400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomer2>>, TError,{data: UpdateCustomer2Body}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateCustomer2>>, TError,{data: UpdateCustomer2Body}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCustomer2>>, {data: UpdateCustomer2Body}> = (props) => {
          const {data} = props ?? {};

          return  updateCustomer2(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCustomer2MutationResult = NonNullable<Awaited<ReturnType<typeof updateCustomer2>>>
    export type UpdateCustomer2MutationBody = UpdateCustomer2Body
    export type UpdateCustomer2MutationError = UpdateCustomer2400

    /**
 * @summary Update the customer
 */
export const useUpdateCustomer2 = <TError = UpdateCustomer2400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCustomer2>>, TError,{data: UpdateCustomer2Body}, TContext>, }
) => {

      const mutationOptions = getUpdateCustomer2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get a specific list of a customer
 */
export const getList2 = (
    listId: string,
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetList2200>(
      {url: `/api/account/lists2/${listId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetList2QueryKey = (listId: string,) => {
    return [`/api/account/lists2/${listId}`] as const;
    }

    
export const getGetList2QueryOptions = <TData = Awaited<ReturnType<typeof getList2>>, TError = GetList2400>(listId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getList2>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetList2QueryKey(listId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getList2>>> = ({ signal }) => getList2(listId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(listId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getList2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetList2QueryResult = NonNullable<Awaited<ReturnType<typeof getList2>>>
export type GetList2QueryError = GetList2400

/**
 * @summary Get a specific list of a customer
 */
export const useGetList2 = <TData = Awaited<ReturnType<typeof getList2>>, TError = GetList2400>(
 listId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getList2>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetList2QueryOptions(listId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add a product to a list
 */
export const addProductToList = (
    listId: string,
    addProductToListBody: AddProductToListBody,
 ) => {
      
      
      return fetchAPI<AddProductToList200>(
      {url: `/api/account/lists2/${listId}/article`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addProductToListBody
    },
      );
    }
  


export const getAddProductToListMutationOptions = <TError = AddProductToList400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProductToList>>, TError,{listId: string;data: AddProductToListBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addProductToList>>, TError,{listId: string;data: AddProductToListBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProductToList>>, {listId: string;data: AddProductToListBody}> = (props) => {
          const {listId,data} = props ?? {};

          return  addProductToList(listId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddProductToListMutationResult = NonNullable<Awaited<ReturnType<typeof addProductToList>>>
    export type AddProductToListMutationBody = AddProductToListBody
    export type AddProductToListMutationError = AddProductToList400

    /**
 * @summary Add a product to a list
 */
export const useAddProductToList = <TError = AddProductToList400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProductToList>>, TError,{listId: string;data: AddProductToListBody}, TContext>, }
) => {

      const mutationOptions = getAddProductToListMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Remove an article from a list
 */
export const removeArticleFromList = (
    listId: string,
    articleId: string,
 ) => {
      
      
      return fetchAPI<RemoveArticleFromList200>(
      {url: `/api/account/lists2/${listId}/article/${articleId}`, method: 'DELETE'
    },
      );
    }
  


export const getRemoveArticleFromListMutationOptions = <TError = RemoveArticleFromList400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeArticleFromList>>, TError,{listId: string;articleId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof removeArticleFromList>>, TError,{listId: string;articleId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeArticleFromList>>, {listId: string;articleId: string}> = (props) => {
          const {listId,articleId} = props ?? {};

          return  removeArticleFromList(listId,articleId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveArticleFromListMutationResult = NonNullable<Awaited<ReturnType<typeof removeArticleFromList>>>
    
    export type RemoveArticleFromListMutationError = RemoveArticleFromList400

    /**
 * @summary Remove an article from a list
 */
export const useRemoveArticleFromList = <TError = RemoveArticleFromList400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeArticleFromList>>, TError,{listId: string;articleId: string}, TContext>, }
) => {

      const mutationOptions = getRemoveArticleFromListMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get customer referral information
 */
export const getCustomerReferralInfos = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetCustomerReferralInfos200>(
      {url: `/api/account/referral/infos`, method: 'GET', signal
    },
      );
    }
  

export const getGetCustomerReferralInfosQueryKey = () => {
    return [`/api/account/referral/infos`] as const;
    }

    
export const getGetCustomerReferralInfosQueryOptions = <TData = Awaited<ReturnType<typeof getCustomerReferralInfos>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerReferralInfos>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerReferralInfosQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerReferralInfos>>> = ({ signal }) => getCustomerReferralInfos(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomerReferralInfos>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomerReferralInfosQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerReferralInfos>>>
export type GetCustomerReferralInfosQueryError = unknown

/**
 * @summary Get customer referral information
 */
export const useGetCustomerReferralInfos = <TData = Awaited<ReturnType<typeof getCustomerReferralInfos>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerReferralInfos>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomerReferralInfosQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Deprecated, use applyReferralCode instead. Use referral code
 */
export const useReferralCode = (
    useReferralCodeBodyBody: UseReferralCodeBodyBody,
 ) => {
      
      
      return fetchAPI<UseReferralCode200>(
      {url: `/api/account/referral/useReferralCode`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: useReferralCodeBodyBody
    },
      );
    }
  


export const getUseReferralCodeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof useReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof useReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof useReferralCode>>, {data: UseReferralCodeBodyBody}> = (props) => {
          const {data} = props ?? {};

          return  useReferralCode(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UseReferralCodeMutationResult = NonNullable<Awaited<ReturnType<typeof useReferralCode>>>
    export type UseReferralCodeMutationBody = UseReferralCodeBodyBody
    export type UseReferralCodeMutationError = unknown

    /**
 * @summary Deprecated, use applyReferralCode instead. Use referral code
 */
export const useUseReferralCode = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof useReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext>, }
) => {

      const mutationOptions = getUseReferralCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Apply referral code
 */
export const applyReferralCode = (
    useReferralCodeBodyBody: UseReferralCodeBodyBody,
 ) => {
      
      
      return fetchAPI<ApplyReferralCode200>(
      {url: `/api/account/referral/applyReferralCode`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: useReferralCodeBodyBody
    },
      );
    }
  


export const getApplyReferralCodeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applyReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof applyReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof applyReferralCode>>, {data: UseReferralCodeBodyBody}> = (props) => {
          const {data} = props ?? {};

          return  applyReferralCode(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApplyReferralCodeMutationResult = NonNullable<Awaited<ReturnType<typeof applyReferralCode>>>
    export type ApplyReferralCodeMutationBody = UseReferralCodeBodyBody
    export type ApplyReferralCodeMutationError = unknown

    /**
 * @summary Apply referral code
 */
export const useApplyReferralCode = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applyReferralCode>>, TError,{data: UseReferralCodeBodyBody}, TContext>, }
) => {

      const mutationOptions = getApplyReferralCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get all subscription status of the customer
 */
export const getApiAccountOptin = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetApiAccountOptin200>(
      {url: `/api/account/optin`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiAccountOptinQueryKey = () => {
    return [`/api/account/optin`] as const;
    }

    
export const getGetApiAccountOptinQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountOptin>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAccountOptin>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAccountOptinQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountOptin>>> = ({ signal }) => getApiAccountOptin(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountOptin>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAccountOptinQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountOptin>>>
export type GetApiAccountOptinQueryError = unknown

/**
 * @summary Get all subscription status of the customer
 */
export const useGetApiAccountOptin = <TData = Awaited<ReturnType<typeof getApiAccountOptin>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAccountOptin>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiAccountOptinQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update subscription status of the customer
 */
export const patchApiAccountOptin = (
    patchApiAccountOptinBody: PatchApiAccountOptinBody,
 ) => {
      
      
      return fetchAPI<PatchApiAccountOptin200>(
      {url: `/api/account/optin`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiAccountOptinBody
    },
      );
    }
  


export const getPatchApiAccountOptinMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAccountOptin>>, TError,{data: PatchApiAccountOptinBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchApiAccountOptin>>, TError,{data: PatchApiAccountOptinBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiAccountOptin>>, {data: PatchApiAccountOptinBody}> = (props) => {
          const {data} = props ?? {};

          return  patchApiAccountOptin(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchApiAccountOptinMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiAccountOptin>>>
    export type PatchApiAccountOptinMutationBody = PatchApiAccountOptinBody
    export type PatchApiAccountOptinMutationError = unknown

    /**
 * @summary Update subscription status of the customer
 */
export const usePatchApiAccountOptin = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiAccountOptin>>, TError,{data: PatchApiAccountOptinBody}, TContext>, }
) => {

      const mutationOptions = getPatchApiAccountOptinMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Compute if opt-in should be reminded to customer
 */
export const getOptinReminder = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetOptinReminder200>(
      {url: `/api/account/optin/reminder`, method: 'GET', signal
    },
      );
    }
  

export const getGetOptinReminderQueryKey = () => {
    return [`/api/account/optin/reminder`] as const;
    }

    
export const getGetOptinReminderQueryOptions = <TData = Awaited<ReturnType<typeof getOptinReminder>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOptinReminder>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOptinReminderQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOptinReminder>>> = ({ signal }) => getOptinReminder(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOptinReminder>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOptinReminderQueryResult = NonNullable<Awaited<ReturnType<typeof getOptinReminder>>>
export type GetOptinReminderQueryError = unknown

/**
 * @summary Compute if opt-in should be reminded to customer
 */
export const useGetOptinReminder = <TData = Awaited<ReturnType<typeof getOptinReminder>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOptinReminder>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOptinReminderQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the customer usual products
 */
export const getAccountProducts = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetAccountProducts200>(
      {url: `/api/account/products`, method: 'GET', signal
    },
      );
    }
  

export const getGetAccountProductsQueryKey = () => {
    return [`/api/account/products`] as const;
    }

    
export const getGetAccountProductsQueryOptions = <TData = Awaited<ReturnType<typeof getAccountProducts>>, TError = GetAccountProducts400>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountProducts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccountProductsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountProducts>>> = ({ signal }) => getAccountProducts(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAccountProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAccountProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountProducts>>>
export type GetAccountProductsQueryError = GetAccountProducts400

/**
 * @summary Get the customer usual products
 */
export const useGetAccountProducts = <TData = Awaited<ReturnType<typeof getAccountProducts>>, TError = GetAccountProducts400>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountProducts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountProductsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the customer usual products SKU
 */
export const getAccountProductsSku = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<string[]>(
      {url: `/api/account/products/sku`, method: 'GET', signal
    },
      );
    }
  

export const getGetAccountProductsSkuQueryKey = () => {
    return [`/api/account/products/sku`] as const;
    }

    
export const getGetAccountProductsSkuQueryOptions = <TData = Awaited<ReturnType<typeof getAccountProductsSku>>, TError = GetAccountProductsSku400>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountProductsSku>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccountProductsSkuQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountProductsSku>>> = ({ signal }) => getAccountProductsSku(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAccountProductsSku>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAccountProductsSkuQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountProductsSku>>>
export type GetAccountProductsSkuQueryError = GetAccountProductsSku400

/**
 * @summary Get the customer usual products SKU
 */
export const useGetAccountProductsSku = <TData = Awaited<ReturnType<typeof getAccountProductsSku>>, TError = GetAccountProductsSku400>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountProductsSku>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountProductsSkuQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Remove a product (sku) form the customer account products
 */
export const deleteAccountProductsSku = (
    sku: string,
 ) => {
      
      
      return fetchAPI<string[]>(
      {url: `/api/account/products/sku/${sku}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteAccountProductsSkuMutationOptions = <TError = DeleteAccountProductsSku400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountProductsSku>>, TError,{sku: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAccountProductsSku>>, TError,{sku: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAccountProductsSku>>, {sku: string}> = (props) => {
          const {sku} = props ?? {};

          return  deleteAccountProductsSku(sku,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAccountProductsSkuMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountProductsSku>>>
    
    export type DeleteAccountProductsSkuMutationError = DeleteAccountProductsSku400

    /**
 * @summary Remove a product (sku) form the customer account products
 */
export const useDeleteAccountProductsSku = <TError = DeleteAccountProductsSku400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountProductsSku>>, TError,{sku: string}, TContext>, }
) => {

      const mutationOptions = getDeleteAccountProductsSkuMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get referral settings information
 */
export const getReferralSettings = (
    
 signal?: AbortSignal
) => {
      
      
      return fetchAPI<GetReferralSettings200>(
      {url: `/api/referral/settings`, method: 'GET', signal
    },
      );
    }
  

export const getGetReferralSettingsQueryKey = () => {
    return [`/api/referral/settings`] as const;
    }

    
export const getGetReferralSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getReferralSettings>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReferralSettings>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReferralSettingsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferralSettings>>> = ({ signal }) => getReferralSettings(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReferralSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReferralSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getReferralSettings>>>
export type GetReferralSettingsQueryError = unknown

/**
 * @summary Get referral settings information
 */
export const useGetReferralSettings = <TData = Awaited<ReturnType<typeof getReferralSettings>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReferralSettings>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReferralSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * If the customer's email exists, generate a reset password link (with a token) and send it to the given email.
 * @summary Ask for a password reset
 */
export const postApiAuthForgottenPassword = (
    postApiAuthForgottenPasswordBody: PostApiAuthForgottenPasswordBody,
 ) => {
      
      
      return fetchAPI<PostApiAuthForgottenPassword204>(
      {url: `/api/auth/forgottenPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiAuthForgottenPasswordBody
    },
      );
    }
  


export const getPostApiAuthForgottenPasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgottenPassword>>, TError,{data: PostApiAuthForgottenPasswordBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgottenPassword>>, TError,{data: PostApiAuthForgottenPasswordBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthForgottenPassword>>, {data: PostApiAuthForgottenPasswordBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthForgottenPassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthForgottenPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthForgottenPassword>>>
    export type PostApiAuthForgottenPasswordMutationBody = PostApiAuthForgottenPasswordBody
    export type PostApiAuthForgottenPasswordMutationError = unknown

    /**
 * @summary Ask for a password reset
 */
export const usePostApiAuthForgottenPassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgottenPassword>>, TError,{data: PostApiAuthForgottenPasswordBody}, TContext>, }
) => {

      const mutationOptions = getPostApiAuthForgottenPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Ensure auth token is still valid
 * @summary Ensure auth token is still valid
 */
export const postApiAuthMe = (
    postApiAuthMeBody: PostApiAuthMeBody,
 ) => {
      
      
      return fetchAPI<PostApiAuthMe200>(
      {url: `/api/auth/me`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiAuthMeBody
    },
      );
    }
  


export const getPostApiAuthMeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthMe>>, TError,{data: PostApiAuthMeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthMe>>, TError,{data: PostApiAuthMeBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthMe>>, {data: PostApiAuthMeBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthMe(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthMeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthMe>>>
    export type PostApiAuthMeMutationBody = PostApiAuthMeBody
    export type PostApiAuthMeMutationError = unknown

    /**
 * @summary Ensure auth token is still valid
 */
export const usePostApiAuthMe = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthMe>>, TError,{data: PostApiAuthMeBody}, TContext>, }
) => {

      const mutationOptions = getPostApiAuthMeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Signup the customer
 * @summary Signup the customer
 */
export const postApiAuthResetPassword = (
    postApiAuthResetPasswordBody: PostApiAuthResetPasswordBody,
 ) => {
      
      
      return fetchAPI<PostApiAuthResetPassword200>(
      {url: `/api/auth/resetPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiAuthResetPasswordBody
    },
      );
    }
  


export const getPostApiAuthResetPasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: PostApiAuthResetPasswordBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: PostApiAuthResetPasswordBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthResetPassword>>, {data: PostApiAuthResetPasswordBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthResetPassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthResetPassword>>>
    export type PostApiAuthResetPasswordMutationBody = PostApiAuthResetPasswordBody
    export type PostApiAuthResetPasswordMutationError = unknown

    /**
 * @summary Signup the customer
 */
export const usePostApiAuthResetPassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: PostApiAuthResetPasswordBody}, TContext>, }
) => {

      const mutationOptions = getPostApiAuthResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Sign in the customer
 * @summary Sign in the customer
 */
export const signIn = (
    signInBody: SignInBody,
 ) => {
      
      
      return fetchAPI<SignIn200>(
      {url: `/api/auth/signin`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signInBody
    },
      );
    }
  


export const getSignInMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signIn>>, TError,{data: SignInBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof signIn>>, TError,{data: SignInBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof signIn>>, {data: SignInBody}> = (props) => {
          const {data} = props ?? {};

          return  signIn(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SignInMutationResult = NonNullable<Awaited<ReturnType<typeof signIn>>>
    export type SignInMutationBody = SignInBody
    export type SignInMutationError = unknown

    /**
 * @summary Sign in the customer
 */
export const useSignIn = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signIn>>, TError,{data: SignInBody}, TContext>, }
) => {

      const mutationOptions = getSignInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Signout / log out a user
 * @summary Signout / log out a user
 */
export const postApiAuthSignout = (
    
 ) => {
      
      
      return fetchAPI<PostApiAuthSignout204>(
      {url: `/api/auth/signout`, method: 'POST'
    },
      );
    }
  


export const getPostApiAuthSignoutMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignout>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignout>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthSignout>>, void> = () => {
          

          return  postApiAuthSignout()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthSignoutMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthSignout>>>
    
    export type PostApiAuthSignoutMutationError = unknown

    /**
 * @summary Signout / log out a user
 */
export const usePostApiAuthSignout = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignout>>, TError,void, TContext>, }
) => {

      const mutationOptions = getPostApiAuthSignoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Signup / register a new user based on his email.
 * @summary Signup / register a new user
 */
export const signup = (
    signupBody: SignupBody,
 ) => {
      
      
      return fetchAPI<Signup200>(
      {url: `/api/auth/signup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signupBody
    },
      );
    }
  


export const getSignupMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signup>>, TError,{data: SignupBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof signup>>, TError,{data: SignupBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof signup>>, {data: SignupBody}> = (props) => {
          const {data} = props ?? {};

          return  signup(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SignupMutationResult = NonNullable<Awaited<ReturnType<typeof signup>>>
    export type SignupMutationBody = SignupBody
    export type SignupMutationError = unknown

    /**
 * @summary Signup / register a new user
 */
export const useSignup = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signup>>, TError,{data: SignupBody}, TContext>, }
) => {

      const mutationOptions = getSignupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send a verification SMS code to the customer
 */
export const sendSmsVerificationCode = (
    sendSmsVerificationCodeBody: SendSmsVerificationCodeBody,
 ) => {
      
      
      return fetchAPI<SendSmsVerificationCode200>(
      {url: `/api/auth/sendSmsVerificationCode`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendSmsVerificationCodeBody
    },
      );
    }
  


export const getSendSmsVerificationCodeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendSmsVerificationCode>>, TError,{data: SendSmsVerificationCodeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sendSmsVerificationCode>>, TError,{data: SendSmsVerificationCodeBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendSmsVerificationCode>>, {data: SendSmsVerificationCodeBody}> = (props) => {
          const {data} = props ?? {};

          return  sendSmsVerificationCode(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SendSmsVerificationCodeMutationResult = NonNullable<Awaited<ReturnType<typeof sendSmsVerificationCode>>>
    export type SendSmsVerificationCodeMutationBody = SendSmsVerificationCodeBody
    export type SendSmsVerificationCodeMutationError = unknown

    /**
 * @summary Send a verification SMS code to the customer
 */
export const useSendSmsVerificationCode = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendSmsVerificationCode>>, TError,{data: SendSmsVerificationCodeBody}, TContext>, }
) => {

      const mutationOptions = getSendSmsVerificationCodeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Stripe webhook handler. Each account will require to set its URL in Stripe Dashboard
 * @summary stripe webhook
 */
export const postApiWebhooksStripe = (
    
 ) => {
      
      
      return fetchAPI<PostApiWebhooksStripe200>(
      {url: `/api/webhooks/stripe`, method: 'POST'
    },
      );
    }
  


export const getPostApiWebhooksStripeMutationOptions = <TError = PostApiWebhooksStripe400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksStripe>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksStripe>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWebhooksStripe>>, void> = () => {
          

          return  postApiWebhooksStripe()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApiWebhooksStripeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWebhooksStripe>>>
    
    export type PostApiWebhooksStripeMutationError = PostApiWebhooksStripe400

    /**
 * @summary stripe webhook
 */
export const usePostApiWebhooksStripe = <TError = PostApiWebhooksStripe400,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWebhooksStripe>>, TError,void, TContext>, }
) => {

      const mutationOptions = getPostApiWebhooksStripeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
