import { NiceModalHocProps } from '@ebay/nice-modal-react';
import dynamic from 'next/dynamic';

/* prettier-ignore */ // Prettier rend le code moins lisible ici
export const modals = {
  'account-management-modal': dynamic(() => import('~/src/screens/AccountManagementModal')),
  'categories-mobile-menu-modal': dynamic(() => import('~/src/screens/App/Header/Navigation/CategoriesMobileMenu')),
  'cart-modal': dynamic(() => import('~/src/screens/CartScreen/CartScreen')),
  'cart-products-actions-modal': dynamic(() => import('~/src/screens/CartScreen/CartProductActionsPanel')),
  'cgv-modal': dynamic(() => import('~/src/screens/GoogleDocScreen/Modals/CGVModal')),
  'checkout-confirmation-modal': dynamic(() => import('~/src/screens/Checkout/CheckoutConfirmationModal')),
  'cookies-modal': dynamic(() => import('~/src/screens/GoogleDocScreen/Modals/CookiesModal')),
  'coupon-modal': dynamic(() => import('~/src/screens/CouponConfirmationModal/CouponConfirmationModal')),
  'debug-modal': dynamic(() => import('~/src/screens/App/Footer/DebugModal')),
  'delivery-modal': dynamic(() => import('~/src/screens/DeliveryModal/DeliveryModal')),
  'delivery-tracking-drawer': dynamic(() => import('~/src/screens/DeliveryTrackingDrawer/DeliveryTrackingDrawer')),
  'expired-delivery-slot-modal': dynamic(() => import('~/src/screens/ExpiredDeliverySlotModal/ExpiredDeliverySlotModal')),
  'frequent-purchases-panel': dynamic(() => import('~/src/screens/Home/FrequentPurchasesPanel')),
  'google-places-autocomplete-modal': dynamic(() => import('~/src/common/components/GooglePlacesAutocomplete/modal/GooglePlacesAutocompleteModal')),
  'last-minute-purchase-panel': dynamic(() => import('~/src/screens/CartScreen/LastMinutePurchasePanel')),
  'last-orders-panel': dynamic(() => import('~/src/screens/Home/LastOrdersPanel')),
  'last-order-selected-panel': dynamic(() => import('~/src/screens/Home/LastOrdersPanel/OrdersSelectedPanel')),
  'marketing-modal': dynamic(() => import('~/src/common/components/MarketingModal')),
  'privacy-modal': dynamic(() => import('~/src/screens/GoogleDocScreen/Modals/PrivacyModal')),
  'recipe-ingredients-modal': dynamic(() => import('~/src/screens/RecipeIngredientsModal')),
  'product-ratings-modal': dynamic(() => import('~/src/screens/ProductRatingsModal')),
  'referral-modal': dynamic(() => import('~/src/screens/ReferralModal')),
  'replace-missing-products-modal': dynamic(() => import('~/src/screens/Checkout/ReplaceMissingProductsSection/ReplaceMissingProductsModal/ReplaceMissingProductsModal')),
  'reset-password-modal': dynamic(() => import('~/src/screens/ResetPasswordModal')),
  'select-slot-modal': dynamic(() => import('~/src/screens/Account/Orders/SelectSlotModal')),
  'special-operations-modal': dynamic(() => import('~/src/screens/GoogleDocScreen/Modals/SpecialOperationsModal')),
  'substitute-products-modal': dynamic(() => import('~/src/screens/SubstituteProducts')),
  'updated-products-panel': dynamic(() => import('~/src/screens/CartScreen/UpdatedProductsPanel')),
  'user-personal-lists-modal': dynamic(() => import('~/src/screens/UserPersonalLists')),
  'weight-pricing-explanation-modal': dynamic(() => import('~/src/screens/WeightPricingExplanationModal')),
  'zoomed-in-image': dynamic(() => import('~/src/screens/CatalogContent/ContentScreen/ImageSlider/ZoomedInImageModal/ZoomedInImageModal')),
  'customer-service-modal': dynamic(() => import('~/src/screens/Account/CustomerServiceModal')),
  'update-order-modal': dynamic(() => import('~/src/screens/Account/Orders/UpdateOrderModal')),
  'coupon-details-modal': dynamic(() => import('~/src/screens/Account/Benefits/CouponsScreen/CouponDetailsModal/CouponDetailsModal')),
  'order-again-products-modal': dynamic(() => import('~/src/screens/Account/Orders/OrderAgainProductsModal/OrderAgainProductsModal')),
};

// Ces types ne sont ici que pour valider que les valeurs utilisées dans le mapping ci-dessus
// sont bien tous de type NiceModalHoc. Je n'ai pas trouvé mieux pour faire la validation.
// Pour chaque composant, on vérifie si ses props correspondent à ceux d'une NiceModal,
// si ce n'est pas le cas, on ajoute un message pour détailler au mieux le probleme.
type AssertNever<T extends never> = T;
type InvalidModals = {
  [K in keyof typeof modals]: (typeof modals)[K] extends React.ComponentType<infer _>
    ? React.ComponentProps<(typeof modals)[K]> extends NiceModalHocProps
      ? never
      : `${K} is not a NiceModal`
    : `${K} is not a Component`;
}[keyof typeof modals];
type _ = AssertNever<InvalidModals>;
