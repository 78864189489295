import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'other:footer';
const Footer = dynamic(() => import('./Component'));
export default createComponent(BuilderId, Footer, inputs, {
  friendlyName: 'Footer',
});
