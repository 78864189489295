import styled, { css } from 'styled-components';

import Icon from '~/src/common/components/Icon';

export const ProgressBar = styled.div<{ $reachedValueAsPercentage: number }>`
  transition: width 1s ease-in-out;
  width: ${({ $reachedValueAsPercentage = 0 }) => $reachedValueAsPercentage}%;
  background-color: ${({ theme }) => theme.palette.common.PITAYA};
  display: flex;
  height: 4px;
`;

export const Container = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const ProgressBarInnerSteps = styled.div`
  position: relative;
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.common.POSITIVE_LIGHT};
  padding: 0 8px;
`;

export const ProgressStepDotIcon = styled(Icon)`
  top: -6px;
`;

export const ProgressStepDot = styled.div<{
  $isFirst: boolean;
  $isLast: boolean;
  $isReached: boolean;
  $absolutePosition: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  top: -6px;
  transition-delay: 0.5s;
  transition-property: background-color;
  border: ${({ theme }) => `2px solid ${theme.palette.common.WHITE}`};

  ${({ $isReached, $isFirst, $isLast, $absolutePosition, theme }) => {
    let left: string | number = `${$absolutePosition}%`;
    if ($isFirst) {
      left = 0;
    } else if ($isLast) {
      left = 'inherit';
    }

    return css`
      background-color: ${$isReached
        ? theme.palette.common.PITAYA
        : theme.palette.common.POSITIVE_LIGHT};
      margin-left: ${$isFirst ? '-1px' : '-8px'};
      margin-right: ${$isLast ? '-1px' : 0};
      left: ${left};
      right: ${$isLast ? 0 : 'inherit'};
    `;
  }};
`;

export const ProgressStepPriceContainer = styled.div<{
  $isFirst: boolean;
  $isLast: boolean;
  $absolutePosition: number;
}>`
  position: absolute;
  top: 10px;
  ${({ $isFirst, $isLast, $absolutePosition }) => {
    let left: string | number = `${$absolutePosition}%`;
    if ($isFirst) {
      left = 0;
    } else if ($isLast) {
      left = 'inherit';
    }

    return css`
      left: ${left};
      right: ${$isLast ? 0 : 'inherit'};
      transform: ${$isLast || $isFirst ? 'inherit' : 'translate(-50%, 0)'};
    `;
  }};
`;

export const ProgressStepPriceText = styled.span<{ $isHighlighted: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme, $isHighlighted }) =>
    $isHighlighted ? theme.palette.common.PITAYA : theme.palette.common.TEXTS};
`;

export const ContainerWording = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWording = styled.span`
  color: ${({ theme }) => theme.palette.common.TEXTS};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const HighLightDeliveryPrice = styled(TextWording)`
  color: ${({ theme }) => theme.palette.common.PITAYA};
`;

export const IconGift = styled(Icon)`
  margin-right: 10px;
`;
