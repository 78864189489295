import styled, { css } from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints, zIndex } from '~/src/styles-constants';

import { TRANSITION_DURATION } from '../constants';

import * as I from './Input/layout';
import * as O from './Output/layout';

/** Element invisible utilisé pour dimensionner la barre de recherche */
export const Sizer = styled.div`
  height: 32px;
  font-size: 12px;
  visibility: hidden;
  pointer-events: none;
  padding: 0 82px 0 32px; /* Idem Input + 50px à droite */
`;

/** Contenu actif de la recherche (Input + Output) */
export const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 788px;

  will-change: width;
  transition: width ${TRANSITION_DURATION}ms ease-in-out;
  z-index: ${zIndex.modalOverlay + 1};
  overscroll-behavior: contain;

  /* Affichage en flex vertical sur mobile */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    display: flex;
    flex-direction: column;

    ${I.Container} {
      flex: 0 0 auto;
    }

    ${O.Container} {
      flex: 1 1 auto;
      overflow: auto;
      max-height: none;
      margin-top: 0;
      padding-top: 0;
      overscroll-behavior: contain;

      padding-bottom: 8px;
    }
  }
`;

/** Wrapper pour la recherche et les résultats */
export const Container = styled.div<{ $active: boolean }>`
  position: relative;
  overscroll-behavior: contain;

  /* Affichage d'un overlay, masqué par défaut */
  &::after {
    inset: 0;
    opacity: 0;
    content: '';
    position: fixed;
    pointer-events: none;
    z-index: ${zIndex.modalOverlay};
    transition: opacity ${TRANSITION_DURATION}ms ease-in-out;
    background: ${({ theme }) => theme.palette.common.BLACK};
  }

  /* Hors desktop : Passage en plein écran, suppression de l'overlay et du Sizer */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    inset: 0;
    opacity: 0;
    position: fixed;
    visibility: visible;
    pointer-events: none;
    transition: opacity ${TRANSITION_DURATION}ms ease-in-out;
    background: ${({ theme }) => theme.palette.common.WHITE};

    &::after {
      content: none;
    }

    ${Sizer} {
      display: none;
    }
  }

  /** Surcharge quand la recherche est active */
  ${({ theme, $active }) => {
    if (!$active) return '';
    return css`
      &::after {
        opacity: 0.5;
        pointer-events: auto;
      }

      ${Content} {
        width: 70vw;
      }

      ${I.Field}::placeholder {
        color: ${theme.palette.common.DISABLED};
      }

      ${O.Container} {
        opacity: 1;
        pointer-events: auto;
      }

      /** Hors desktop : Affichage du container */
      @media ${getMediaQuery(0, breakpoints.XL)} {
        opacity: 1;
        pointer-events: auto;
      }
    `;
  }}
`;

export const Header = styled.div`
  @media ${getMediaQuery(0, breakpoints.XL)} {
    background-color: ${({ theme }) => theme.palette.common.PRIMARY};
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    color: ${({ theme }) => theme.palette.common.WHITE};
  }

  & > span {
    margin: 12px 8px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
