export default {
  /* string validation */
  'required': 'Champ requis',
  'minLength': 'Longueur minimum %{length}',
  'maxLength': 'Longueur maximum %{length}',
  'email': 'Email non valide',
  'phone': 'Indiquez 10 chiffres sans espace',
  'old-password': 'Le mot de passe doit contenir au moins 5 caractères',
  'password-reset-different': 'Les mots de passe ne correspondent pas',

  /* number validation */
  'min': 'Minimum %{min}',
  'max': 'Maximum %{max}',

  /* Promo code */
  'promo-code': "Le code promo n'est pas valide",

  /* Password */
  'at-least-1-lowercase': 'Le champ doit contenir au moins une lettre minuscule.',
  'at-least-1-uppercase': 'Le champ doit contenir au moins une lettre majuscule.',
  'at-least-1-number': 'Le champ doit contenir au moins un chiffre.',
  'at-least-8-char': 'Le champ doit contenir au moins 8 caractères.',
  'only-alphabetic-characters': 'Le champ doit contenir uniquement des caractères alphabétiques.',
  'hide-password-aria-label': 'Masquer le mot de passe',
  'show-password-aria-label': 'Afficher le mot de passe',
};
