import { useEffect, useMemo } from 'react';

import { useGetTaskDeliveryInfos } from '~/src/queries/api-ecom/generated/api-ecom';
import { CurrentOrders200ItemsItemDelivery } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import { isDelivery } from '../../App/CatalogLayout/utils';
import { getDeliveryEstimatedSlot } from '../../DeliveryTrackingDrawer/utils/getDeliveryEstimatedSlot.utils';

export function useEstimatedTimeSlot(orderId: string, delivery: CurrentOrders200ItemsItemDelivery) {
  const { mutate, data } = useGetTaskDeliveryInfos();

  const isDeliveryOrder = isDelivery(delivery);

  useEffect(() => {
    if (isDeliveryOrder) mutate({ data: { orderIds: [orderId] } });
  }, [mutate, orderId, isDeliveryOrder]);

  return useMemo(() => {
    if (!data || !data.estimatedArrivalTime) return null;

    return getDeliveryEstimatedSlot(delivery.timeSlot, data.estimatedArrivalTime);
  }, [delivery.timeSlot, data]);
}
