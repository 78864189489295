import {
  ActionChangeName,
  ActionChangeNameOrAttrOrPrice,
  ActionChangePrice,
  ActionChangeVaryingAttribute,
  ActionInsufficientQuantity,
  CartProductAction,
} from '~/src/common/typings/cart';
import { CustomContent, MarketingCard } from '~/src/common/typings/product';
import { Product, CatalogProduct, Recipe } from '~/src/typings/products/types';

export const isProduct = (product: CatalogProduct | undefined): product is Product =>
  product?.type === 'PRODUCT';

export const isRecipe = (product: CatalogProduct | undefined): product is Recipe =>
  product?.type === 'RECIPE';

const isCustomContent = (product: CatalogProduct | undefined): product is CustomContent =>
  product?.type === 'CUSTOM_CONTENT';

export const isMarketingCard = (product: CatalogProduct | undefined): product is MarketingCard =>
  isCustomContent(product) && product.customContentType === 'Card Marketing';

export const isActionInsufficientQuantity = (
  action: CartProductAction,
): action is ActionInsufficientQuantity =>
  action.type === 'CHANGE_INSUFFICIENT_AVAILABLE_QUANTITY' && action.availableQuantity > 0;

export const isActionChangeNameOrAttrOrPrice = (
  action: CartProductAction,
): action is ActionChangeNameOrAttrOrPrice =>
  action.type === 'CHANGE_PRODUCT_NAME' ||
  action.type === 'CHANGE_VARYING_ATTRIBUTE' ||
  action.type === 'CHANGE_ATTRIBUTE' ||
  action.type === 'CHANGE_PRICE';

export const isActionChangeVaryingAttribute = (
  action: CartProductAction,
): action is ActionChangeVaryingAttribute => action.type === 'CHANGE_VARYING_ATTRIBUTE';

export const isActionChangeName = (action: CartProductAction): action is ActionChangeName =>
  action.type === 'CHANGE_PRODUCT_NAME';

export const isActionChangePrice = (action: CartProductAction): action is ActionChangePrice =>
  action.type === 'CHANGE_PRICE';
