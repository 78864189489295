import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderID = 'button:promocode';
const Component = dynamic(() => import('./Component'));
export default createComponent(BuilderID, Component, inputs, {
  friendlyName: 'Bouton Code Promo',
});
