import { useCallback, useContext } from 'react';

import { useRouter } from 'next/router';

import Tracker, { CommonCatalogEventProperties } from '~/src/common/services/Tracker';

import { RouterContext } from './context';

const BACK_URL = '/';

export const useGoBack = (analyticsProperties: CommonCatalogEventProperties) => {
  const { push, back } = useRouter();
  const { isFirstView } = useContext(RouterContext);

  // Si c'est la première fois qu'on arrive sur le site le bouton de retour redirige vers la home plutôt que d'utiliser le back du navigateur.
  // Empèche de sortir du site au clic sur le bouton de retour
  const handleBack = useCallback(() => {
    Tracker.sendEvent('click back', analyticsProperties);

    return isFirstView ? void push(BACK_URL) : back();
  }, [isFirstView, back, push, analyticsProperties]);

  return { handleBack };
};
