import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useToggle } from '~/src/common/hooks/use-toggle';

const useSmartBanner = () => {
  const smartBannerState = useToggle(false);
  const router = useRouter();

  useEffect(() => {
    const showSmartBanner = () => {
      const [isSmartBannerDisplayed, toggleSmartBanner] = smartBannerState;

      // Permet d'afficher la smart banner appsflyer (bannière qui permet d'ouvrir l'app)
      if (!isSmartBannerDisplayed) {
        toggleSmartBanner();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
        window.AF('banners', 'showBanner', { bannerContainerQuery: '#smartbanner' });
      }
    };

    router.events.on('routeChangeComplete', showSmartBanner);

    return () => {
      router.events.off('routeChangeComplete', showSmartBanner);
    };
  }, [router.events, smartBannerState]);
};

export default useSmartBanner;
