// eslint-disable-next-line no-restricted-imports -- Nécessaire pour tout configurer et re-exporter
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/fr';

// Changement de la langue par défaut
dayjs.locale('fr');

// Plugins
dayjs.extend(utc); // Requis pour la gestion des timezones
dayjs.extend(timezone); // Support des timezones dans le construteur et les transformations
dayjs.extend(localizedFormat); // Ajout d'options locales lors de l'utilisation de .format(...)
dayjs.extend(customParseFormat); // Ajoute la possibilité de parser de date custom dans le constructeur

/** La timezone du site */
const TIMEZONE = 'Europe/Paris';

/**
 * Création d'un wrapper autour de la fonction dayjs d'origine afin d'être sur que
 * toute initialisation de date soit forcément faite dans la timezone spécifiée.
 */
export default (date: number | string | Date = Date.now(), format: string | null = null) => {
  return format ? dayjs.tz(date, format, TIMEZONE) : dayjs.tz(date, TIMEZONE);
};
