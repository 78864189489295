import { useContext } from 'react';

import NiceModal, { NiceModalHandler, useModal } from '@ebay/nice-modal-react';

import { modals } from './modals';
import { ModalArgs, ModalComponent, ModalName, ModalState, ModalTuple } from './types';

export const useNiceModal = <N extends ModalName>(name: N, args?: ModalArgs<N>) =>
  useModal(name, args) as NiceModalHandler<ModalArgs<N>>;

export const showNiceModal = <N extends ModalName>(name: N, args?: ModalArgs<N>) =>
  NiceModal.show(name, args);

export const removeNiceModal = (name: ModalName) => NiceModal.remove(name);

export const useModalState = () => {
  const modalContext = useContext(NiceModal.NiceModalContext);

  return Object.keys(modalContext).reduce<ModalState>(
    (acc, niceModalName) => ({
      ...acc,
      [niceModalName]: modalContext[niceModalName]?.visible || false,
    }),
    {},
  );
};

export const registerModal = <N extends ModalName>(name: N, component: ModalComponent<N>) =>
  // @ts-expect-error -- `register` attend FC mais `dynamic` renvoie ComponentType
  NiceModal.register(name, component);

export const useChainedModals = <N extends ModalName>(name: N, args?: ModalArgs<N>) => {
  const { show, remove } = useNiceModal(name, args);

  const showModals = async (props: ModalArgs<N>[]) => {
    // https://opensource.ebay.com/nice-modal-react/#promise
    for (let i = 0; i < props.length; i += 1) {
      await show(props[i]);
      remove();
    }
  };

  return { showModals };
};

export const registerModals = () => {
  Object.entries(modals).forEach(entry => {
    const [name, comp] = entry as ModalTuple;
    registerModal(name, comp);
  });
};

export const isModalVisible = (modalState: ModalState | undefined, modalName: ModalName): boolean =>
  modalState?.[modalName] ?? false;

export const removeAllModals = () => {
  Object.keys(modals).forEach(mid => {
    void NiceModal.remove(mid as ModalName);
  });
};
