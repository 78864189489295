import { useEffect } from 'react';

import { useRouter } from 'next/router';

import TagManager from '../TagManager';

export const getPageType = (url: string, query?: { q?: string }) => {
  if (query?.q != null) return 'ResultPage';
  if (url === '/landing') return 'LandingPage';
  if (url === '/account/me') return 'EditAccountPage';
  if (url === '/' || url === '/reset-password') return 'HomePage';
  if (url === '/account/addresses') return 'ListAccountAddressesPage';
  return null; // Pas de suivi automatic, probablement en manuel sur la page
};

const useGtmPageTracking = () => {
  const router = useRouter();

  useEffect(() => {
    const url = router.asPath.split('?')[0];
    const initialPageType = getPageType(url, router?.query);
    if (initialPageType != null) TagManager.pageView(initialPageType);
  }, [router.asPath]);
};

export default useGtmPageTracking;
