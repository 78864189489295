export default {
  'delivery': 'Livraison',
  'delivery-instructions': 'Informations de livraison',
  'estimatedArrival': 'Arrivée estimée',
  'close-label': 'Fermer le suivi de livraison',
  'goToDetails': 'Détails de ma commande',
  'install-app-incentive':
    "Installez l'app pour être notifié de l'évolution de votre commande en flashant ce QRcode",
  'last-update': 'Dernière mise à jour à %{time}',
  'latest-arrival': 'Arrivée la plus tardive',
  'openApp': "Ouvrir l'application pour être informé de l'évolution de votre commande",
  'status': {
    'accepted': 'Votre commande a bien été acceptée.',
    'arriving': 'Votre livreur arrive !',
    'delivering': 'Votre livreur est en tournée !',
    'failed-delivery': "Votre commande n'a pas pu être livrée.",
    'pending-redelivery': 'Votre livraison a été reprogrammée.',
    'preparing': 'Votre commande est en préparation.',
  },
  'title': 'Commande',
  'x-orders-before-yours_0': '0 livraison avant la vôtre',
  'x-orders-before-yours_1': '1 livraison avant la vôtre',
  'x-orders-before-yours_plural': '%{count} livraisons avant la vôtre',
};
