export default {
  'title': 'Les ingrédients de la recette',
  'add-to-cart': 'Ajouter au panier',
  'nb-articles': '%{count} article',
  'nb-articles_plural': '%{count} articles',
  'servings': 'Personnes',
  'add-products-success-message': '%{count} article ajouté au panier',
  'add-products-success-message_plural': '%{count} articles ajoutés au panier',
  'no-product': "Aucun produit n'est disponible pour cette recette",
  'substituted-product-quantity': 'Quantité prévue dans la recette',
};
