import styled from 'styled-components';

export const Tag = styled.div<{ $color: string }>`
  padding: 0 4px;
  width: fit-content;
  white-space: pre;

  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  border-radius: 4px;

  color: ${({ theme }) => theme.palette.common.DARK_LABEL};
  background-color: ${({ $color }) => $color};
`;
