import React from 'react';

import I18n from '~/src/common/services/I18n/I18n';

import FrenchRepublicLogo from './images/france-republique-logo.jpg';
import * as S from './layout';

const AlcoholDisclaimer = () => {
  return (
    <S.AlcoholDisclaimer>
      <img src={FrenchRepublicLogo} alt={I18n.t('footer.alcohol-disclaimer.part-1')} />
      <div>
        <p>{I18n.t('footer.alcohol-disclaimer.part-1')}</p>
        <p>{I18n.t('footer.alcohol-disclaimer.part-2')}</p>
        <p>{I18n.t('footer.alcohol-disclaimer.part-3')}</p>
      </div>
    </S.AlcoholDisclaimer>
  );
};

export default AlcoholDisclaimer;
