import { useEffect, useState } from 'react';

/**
 * Ce hook permet de gérer la navigation au clavier au sein d'un élément html.
 * Il retourne une fonction devant être attachée à la ref de l'élément à gérer.
 * En paramètre, attend un sélecteur css permettant de cibler les enfants à naviguer.
 * L'élément actif sélectionné est mis en avant via l'attribut `aria-selected`.
 *
 * @example
 * // Les <li> seront selectable via les fleches haut/bas
 * const ref = useAriaKeyboardNavigation<HTMLUListElement>('li');
 * return (
 *   <ul ref={ref}>
 *     <li>Element 1</li>
 *     <li>Element 2</li>
 *     <li>Element 3</li>
 *   </ul>
 * );
 *
 */
const useAriaKeyboardNavigation = <T extends Element>(selector: string) => {
  // On utilise un state et non pas une ref pour stocker l'élément HTML afin de forcer
  // un rerender si l'élément change. Indispensable pour attacher le mutation observer
  const [ref, setRef] = useState<T | null>(null);
  const [index, setIndex] = useState<number | null>(null);

  // Réinitialisation automatique de l'index
  useEffect(() => {
    const observer = new MutationObserver(entries => {
      if (entries.some(e => e.type !== 'attributes')) setIndex(null);
    });
    const options = { attributes: true, childList: true, subtree: true };
    if (ref) observer.observe(ref, options);
    return () => observer.disconnect();
  }, [ref]);

  // Mise à jour de l'affichage
  useEffect(() => {
    const nodes = ref?.querySelectorAll(selector);
    nodes?.forEach(node => node.removeAttribute('aria-selected'));
    if (index != null) nodes?.[index]?.setAttribute('aria-selected', 'true');
  }, [ref, selector, index]);

  // Gestion du clavier
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // On ne fait rien si pas d'éléments
      const nodes = ref?.querySelectorAll(selector);
      const count = nodes?.length ?? 0;
      if (count === 0) return;
      // Navigation au clavier, fleches "Haut" et "Bas"
      if (e.key === 'ArrowDown') setIndex(v => ((v ?? -1) + 1) % count);
      if (e.key === 'ArrowUp') setIndex(v => ((v ?? 0) + count - 1) % count);
      // Simulation de click lors de l'appui sur "Entrée"
      if (e.key === 'Enter') {
        const node = index != null ? nodes?.[index] : null;
        const opts = { bubbles: true, cancelable: true };
        node?.dispatchEvent(new MouseEvent('click', opts));
        setIndex(null);
      }
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [ref, index, selector]);

  return setRef;
};

export default useAriaKeyboardNavigation;
