import { QueryClient } from '@tanstack/react-query';

import { DeliveryErrors } from '@keplr/api-ecom-errors';

import Dialog from '~/src/common/components/Dialog';
import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import { logger } from '~/src/common/services/Logger';
import { removeNiceModal, showNiceModal } from '~/src/common/services/ModalsManager';
import Toaster from '~/src/common/services/Toaster';
import Tracker, {
  PickupTypeValues,
  setTimeSlotUserProperties,
} from '~/src/common/services/Tracker';
import { Cart } from '~/src/common/typings/cart';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { getDeliveryIconName } from '~/src/common/utils/delivery';
import { noop } from '~/src/common/utils/function';
import { isApiEcomError } from '~/src/common/utils/guards';
import {
  getGetCartQueryKey,
  getRecompute2QueryKey,
  verifyDelivery,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { getDeliveryModalStep } from '~/src/screens/App/CatalogLayout/utils';

// Utilisé pour debug le refresh en boucle
const debug = { time: 0, count: 0, reported: false };

/**
 * Vérifie et récupère le nouveau créneau horaire si le créneau actuel n'est plus valide
 * Si le créneau est toujours valide, la fonction ne renvoi pas de créneau
 * Si il n'y a a plus de créneaux valides, le back renvoi une erreur que l'on peut handle.
 *
 * Cette fonction s'occupe d'invalider les caches react query et d'afficher la modale de créneau expiré
 * @param queryClient Le client React Query
 * @returns Le nouveau timeslot si l'ancien est expiré, undefined sinon
 */
export const getNewTimeSlot = async (queryClient: QueryClient, currentCart?: Cart) => {
  try {
    const verification = await verifyDelivery(
      {
        action: 'all',
      },
      { recomputed: true },
    );

    // Pour éviter de spam Sentry, on limite le reporting
    if (!debug.reported) {
      // Une petite trace dans Sentry
      logger.info(
        'GetNewTimeSlot',
        {
          'cart.delivery.timeSlotValidity': currentCart?.delivery.timeSlotValidity,
          'verification.previousTimeslotValidity': verification?.previousTimeslotValidity,
          'cart.delivery': currentCart?.delivery,
          'verification.cart.delivery': verification?.cart?.delivery,
        },
        { sentryBreadCrumbCategory: 'TimeSlot' },
      );
      // Si déjà arrivé dans la minute, on incrémente le compteur, sinon on reset
      debug.count = debug.time > Date.now() - 60000 ? debug.count + 1 : 0;
      debug.time = Date.now();
      // Si on passe plus de 3 fois, on remonte l'info à Sentry
      if (debug.count > 3) {
        debug.reported = true;
        throw new Error('Loop detected in getNewTimeSlot');
      }
    }

    queryClient.setQueryData(getGetCartQueryKey(), {
      ...currentCart,
      delivery: { ...verification.cart.delivery },
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    queryClient.invalidateQueries(getGetCartQueryKey());
    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    queryClient.invalidateQueries(getRecompute2QueryKey());

    const zone = verification.cart.delivery.deliveryZone;

    Tracker.setUserProperties({
      'delivery zone/pick-up point': zone.name,
    });

    if (zone.type != null) {
      Tracker.setUserProperties({
        'shipping type': zone.type === 'delivery' ? 'delivery' : 'pickup',
        'pickup mode': zone.type === 'delivery' ? '-' : zone.pickupType,
        'pickup type': PickupTypeValues[zone.type],
      });
    }

    setTimeSlotUserProperties({
      ...verification.cart.delivery.timeSlot,
      isExpired: false,
      isFull: false,
      isExcluded: false,
    });

    // Si le créneau a expiré depuis moins de 12h, on affiche la notification de créneau expiré
    if (
      currentCart?.delivery.timeSlot &&
      dayjs().diff(currentCart.delivery.timeSlot.from, 'hour') < 12
    ) {
      Tracker.sendEvent('shipping slot toaster viewed');
      Toaster.notify({
        icon: getDeliveryIconName(verification.cart.delivery.deliveryZone?.pickupType),
        message: I18n.translate({
          value: 'delivery-modal.toaster-auto-select-timeslot.success',
          slot: getDeliveryDateWithTimeslotInterval(verification.cart.delivery.timeSlot),
        }),
        actionButtonLabel: I18n.t('common.modify'),
        onActionButtonClick: () => {
          Tracker.sendEvent('shipping slot toaster click');

          showNiceModal('delivery-modal', {
            initialStep: getDeliveryModalStep(currentCart.delivery),
          }).catch(noop);
        },
      });
    }

    return {
      timeSlot: verification.cart.delivery.timeSlot,
      deliveryZone: verification.cart.delivery.deliveryZone,
    };
  } catch (error) {
    if (isApiEcomError(error)) {
      if (error.code === DeliveryErrors.NoTimeSlotAvailable.code) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
        showNiceModal('delivery-modal', {
          initialStep: 'PICK_ADDRESS',
        });
        removeNiceModal('cart-modal');
      }
      Dialog.error({
        title: I18n.t('errors.default-title'),
        description: error.message,
      });
    } else {
      logger.error('Error while getting new timeslot for cart', {
        originalError: error,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    queryClient.invalidateQueries(getGetCartQueryKey());
  }

  return null;
};
