import styled, { css } from 'styled-components';

import AppStoreBadgeSvg from '~/src/common/assets/app-store-badge.svg';
import GooglePlayBadgeSvg from '~/src/common/assets/google-play-badge.svg';
import Button from '~/src/common/components/Button';
import { getMediaQuery } from '~/src/common/utils/style';
import { IS_MOBILE_CATALOG_CONTENT_CLASS } from '~/src/screens/CatalogContent/ContentScreen/CatalogContentScreenLayout';
import { media } from '~/src/styles-constants';

const MOBILE_CHECKOUT_BOTTOM_NAVBAR_HEIGHT = 76;

const LinkStyles = css`
  font-size: 14px;
  line-height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.TEXTS};

  &::first-letter {
    text-transform: capitalize;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
  }
`;

export const Container = styled.footer`
  order: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.TEXTS};

  background: ${({ theme }) => theme.palette.common.WHITE};

  .${IS_MOBILE_CATALOG_CONTENT_CLASS} & {
    /* Nécessaire pour éviter que le footer ne soit caché par le ATC sur les pages produits ou recettes */
    margin-bottom: 75px;
  }
`;

export const AllLinksPart = styled.div`
  padding: 16px 40px;

  ${media.XL`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 16px;
  `}
`;

export const LinksSection = styled.section`
  display: flex;
  flex-direction: column;

  ${media.XL`
    margin-right: 16px;
    width: 100%;
    max-width: 267px;
  `}
`;

export const LinksInnerSection = styled.div<{ $isOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
  line-height: 32px;
  max-height: ${({ $isOpened }) => ($isOpened ? '100%' : 0)};

  > a {
    ${LinkStyles};
  }

  ${media.XL`
    max-height: 100%;
  `}
`;

export const SectionTitle = styled.span`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  cursor: pointer;

  ${media.XL`
    pointer-events: none;
    margin-top: 0;
  `}
`;

export const ToggleSectionButton = styled(Button)<{ $isOpened?: boolean }>`
  max-height: 22px;

  transform: rotate(${({ $isOpened }) => ($isOpened ? -180 : 0)}deg);
  transition: transform 0.5s;

  span {
    color: ${({ theme }) => theme.palette.common.TEXTS};
  }

  ${media.XL`
    display: none;
  `}
`;

export const StyledLink = styled.a`
  ${LinkStyles};
`;

export const AboutInfo = styled.p`
  max-width: 200px;
  margin-top: 32px;
  margin-bottom: 20px;
  white-space: pre-line;
  line-height: 1rem;

  color: ${({ theme }) => theme.palette.common.TEXTS};
  font-size: 14px;
`;

export const OpeningHours = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  margin-top: 16px;

  > * {
    margin-right: 32px;
  }
`;

export const AppStoreBadge = styled(AppStoreBadgeSvg)`
  width: 222px;
  height: 67px;

  margin: 10px 0 8px;
  ${media.XL`
    display: block;
  `}
`;

export const GooglePlayBadge = styled(GooglePlayBadgeSvg)`
  width: 211px;
  margin-left: 5px;
  margin-bottom: 10px;
  ${media.XL`
    display: block;
  `}
`;

export const LegalPart = styled.div<{ $isAlcoholDisclaimerDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px 40px;

  ${({ $isAlcoholDisclaimerDisplayed }) =>
    !$isAlcoholDisclaimerDisplayed
      ? ''
      : css`
          margin-bottom: env(safe-area-inset-bottom);
        `};

  background: ${({ theme }) => theme.palette.common.BLACK};
  color: ${({ theme }) => theme.palette.common.WHITE};

  ${media.M`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
  `}

  ${media.S`
    margin-bottom: 0
  `}


    & > button {
    text-align: left;
    border: none;
    background-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
    padding: 0;
  }

  & a,
  & > span,
  & > button {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.common.WHITE};

    &:not(:first-child) {
      margin-top: 16px;
      ${media.M`
        margin-top: 0;
        margin-left: 32px;
      `}
    }
  }

  a:hover,
  a:visited {
    font-family: inherit;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.common.WHITE};
  }
`;

export const LegalLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.M`
    margin-bottom: 0;
    flex-direction: row;
  `}
`;

export const LegalLink = styled.a`
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  ${media.M`
    margin: 0;
  `}
`;

export const ContactList = styled.ul`
  list-style: inside;
  margin: 0;
  padding: 0;
  margin-left: 16px;

  & li {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;

    > a {
      text-decoration: none;
    }
  }

  & button {
    background-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
    border: none;
    padding: 0;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
  }
`;

export const AppsBadgesContainer = styled.div`
  margin-left: -3px;

  ${media.M`
    margin-top: 32px;
  `}
`;

export const AlcoholDisclaimer = styled.div`
  border: 4px solid ${({ theme }) => theme.palette.common.BLACK};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.common.WHITE};

  @media ${getMediaQuery(375, null)} {
    padding: 16px 12px;
  }

  & > div {
    margin-left: 16px;

    p {
      /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
      font-family: 'Helvetica Neue';
      font-weight: 400;

      font-size: 10px;
      line-height: 10px;
      margin: 0;

      ${media.M`
        font-size: 16px;
        line-height: 16px;
      `}
    }

    & > p:first-child {
      font-weight: 700;

      ${media.M`
        font-size: 18px;
        line-height: 24px;
      `}
    }

    & > p:last-child {
      text-align: right;
      font-weight: 500;

      font-size: 8px;
      line-height: 16px;

      ${media.M`
        font-size: 14px;
        line-height: 24px;
      `}
    }
  }
`;

// container for ethylotest img to be displayed fine during checkout
export const LegalInnerContainer = styled.div`
  ${AlcoholDisclaimer} {
    width: 100%;
    max-height: 145px;

    margin-bottom: 0;

    ${media.XS`
    order: -1;
    `}

    ${media.S`
    order: 1;
    `}

    img {
      ${media.S`
      width: 20%;`}

      ${media.L`
      width: auto;
      `}
    }

    ${media.XL`
        margin-bottom: 0;
    `}

    & > div {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;

        ${media.M`
          font-size: 14px;
        `};
      }

      & > p:first-child {
        ${media.M`
          font-size: 14px;
          line-height: 18px;
        `}
      }

      & > p:last-child {
        ${media.M`
          font-size: 12px;
          line-height: 14px;
        `}
      }
    }

    margin-left: auto;
    margin-right: auto;
  }

  display: flex;
  color: ${({ theme }) => theme.palette.common.TEXTS};

  background: ${({ theme }) => theme.palette.common.WHITE};

  ${media.XS`
  flex-direction: column;
  border: none;
  `}

  ${media.S`
  flex-direction: row;
  `}

  ${media.M`
  margin-bottom: 0;
  `}
`;

export const EthylotestBloc = styled.img`
  object-fit: contain;
  border: 4px solid ${({ theme }) => theme.palette.common.BLACK};
  max-height: 137px;

  ${media.XS`
max-height: 100%;
object-fit: cover;

border-top: none;
box-sizing: border-box;
`}

  ${media.S`
border-top: 4px solid ${({ theme }) => theme.palette.common.BLACK};
border-right: none;
max-height: 137px;`}
`;

export const VersionContainer = styled.div<{
  $isCheckout: boolean;
  $marginBottom: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px;

  & > span {
    margin-right: 16px;
  }

  & > a {
    color: ${({ theme }) => theme.palette.common.TEXTS};
  }

  ${({ $isCheckout, $marginBottom }) => {
    const margin = $isCheckout
      ? MOBILE_CHECKOUT_BOTTOM_NAVBAR_HEIGHT + $marginBottom
      : $marginBottom;
    return media.XS`
      margin-bottom: calc(env(safe-area-inset-bottom) + ${margin}px);
      font-size: 12px;
      & > span {
        font-size: 12px;
      }
      padding: 4px;
    `;
  }}

  ${({ $isCheckout }) => {
    if ($isCheckout) {
      return media.M`
      margin-bottom: 0px;
    `;
    }

    return media.M`
    font-size: 14px;
    & > span {
      font-size: 14px;
    }
    `;
  }}

  ${media.XL`
    margin-bottom: 0;
  `}
`;

export const VersionLink = styled.a`
  cursor: pointer;
`;
