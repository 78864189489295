import { useMemo } from 'react';

import { Cart, DeliveryZone } from '~/src/common/typings/cart';

import DeliveryProgressBarSteps from './DeliveryProgressBarSteps';
import DeliveryProgressBarWording from './DeliveryProgressBarWording';

export type Props = {
  deliveryPrices: DeliveryZone['deliveryPrices'];
  totalCartAmount: Cart['price']['quotation']['net'];
};

const DeliveryProgressBar = ({ totalCartAmount, deliveryPrices }: Props) => {
  // Sort Steps
  const steps = useMemo(
    () =>
      [...deliveryPrices].sort((priceA, priceB) => priceA.minCartNetPrice - priceB.minCartNetPrice),
    [deliveryPrices],
  );

  return (
    <>
      <DeliveryProgressBarWording totalCartAmount={totalCartAmount} deliveryPrices={steps} />
      <DeliveryProgressBarSteps totalCartAmount={totalCartAmount} deliveryPrices={steps} />
    </>
  );
};

export default DeliveryProgressBar;
