import React from 'react';

import PinkButton from '~/src/common/components/Button/Variants/PinkButton';

import type ButtonBase from './Variants/BaseButton';
import LinkButton from './Variants/LinkButton';
import PrimaryButton from './Variants/PrimaryButton';
import QuaternaryButton from './Variants/QuaternaryButton';
import SecondaryButton from './Variants/SecondaryButton';
import SecondaryLinkButton from './Variants/SecondaryLinkButton';
import ShadowButton from './Variants/ShadowButton';
import TertiaryButton from './Variants/TertiaryButton';
import TransparentButton from './Variants/TransparentButton';

const BUTTONS = {
  'primary': PrimaryButton,
  'secondary': SecondaryButton,
  'tertiary': TertiaryButton,
  'quaternary': QuaternaryButton,
  'transparent': TransparentButton,
  'shadow': ShadowButton,
  'pink': PinkButton,
  'link': LinkButton,
  'secondary-link': SecondaryLinkButton,
};

export type Props = React.ComponentProps<typeof ButtonBase> & {
  variant?: keyof typeof BUTTONS;
};

const Button = React.forwardRef<HTMLButtonElement | null, Props>(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  ({ variant = 'primary', ...props }, ref) => {
    const ButtonComponent = BUTTONS[variant];

    return <ButtonComponent ref={ref} {...props} />;
  },
);

export default Button;
