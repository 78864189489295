import dayjs from '~/src/common/services/Date';
import { Cart } from '~/src/common/typings/cart';
import { useDeliveryInformation } from '~/src/screens/DeliveryModal/app-context';

export const useDelivery = (cart?: Cart) => {
  const {
    address: cartAddress,
    timeSlot,
    mode: cartDeliveryMode,
    shop: cartShop,
  } = cart?.delivery || {};
  const [{ address, deliveryZone }] = useDeliveryInformation();

  const deliveryMode = deliveryZone?.deliverySlots[0]?.deliveryMode;
  const cartDeliveryZone = cart?.delivery?.deliveryZone;

  const isTimeSlotValid = timeSlot != null && dayjs().isBefore(timeSlot.orderUntil);

  const isSetup = cartAddress != null && timeSlot != null;

  return {
    isSetup,
    address: isSetup ? cartAddress : address,
    mode: isSetup ? cartDeliveryMode : deliveryMode,
    deliveryZone: cartDeliveryZone,
    shop: isSetup ? cartShop : deliveryZone?.shop,
    timeSlot,
    isTimeSlotValid,
  };
};
