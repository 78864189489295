import React, { useContext, useState, useMemo, useRef } from 'react';

import { noop } from '~/src/common/utils/function';

type ModalBackState = [
  AnyFunction | undefined,
  React.Dispatch<React.SetStateAction<AnyFunction | undefined>>,
];

export const ModalBackContext = React.createContext<ModalBackState>([undefined, noop]);

export const useBackModalState = (initialState?: AnyFunction) => {
  const [back, setBack] = useState<AnyFunction | undefined>(initialState);

  const { current: setBackHandler } = useRef((f?: AnyFunction) => {
    setBack(() => f);
  });

  const backModalState = useMemo(() => [back, setBackHandler], [back, setBackHandler]);

  return backModalState as ModalBackState;
};

/**
 * Retrieve the current back method & allow permit to set it
 */
export const useBackModal = () => useContext(ModalBackContext);
