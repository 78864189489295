import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import {
  CartDelivery,
  DeliveryAddress,
  DeliverySlot,
  DeliveryZone,
} from '~/src/common/typings/cart';
import { noop } from '~/src/common/utils/function';
import { Address } from '~/src/typings/addresses/types';

type DeliveryInformation = {
  address?: DeliveryAddress | Address;
  customer?: Address['contact'];
  deliveryZone?: DeliveryZone;
  timeSlot?: DeliverySlot;
  note?: CartDelivery['note'];
};

type DeliveryModalAppContextType = [
  deliveryInformation: DeliveryInformation,
  setDeliveryInformation: Dispatch<SetStateAction<DeliveryInformation>>,
];

const initialState = [{}, noop] as DeliveryModalAppContextType;

const DeliveryModalAppContext = createContext<DeliveryModalAppContextType>(initialState);

export const DeliveryModalAppProvider = ({ children }: { children: React.ReactNode }) => {
  const deliveryInformationState = useState<DeliveryInformation>({});

  return (
    <DeliveryModalAppContext.Provider value={deliveryInformationState}>
      {children}
    </DeliveryModalAppContext.Provider>
  );
};

export const useDeliveryInformation = () => {
  const context = useContext(DeliveryModalAppContext);

  return context;
};
