import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'section:tuto';
const Tuto = dynamic(() => import('./Component'));
export default createComponent(BuilderId, Tuto, inputs, {
  friendlyName: 'Section tutoriel',
});
