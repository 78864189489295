import React from 'react';

import styled from 'styled-components';

import type colors from '../../themes/main/colors';

const SIZES = {
  small: 16,
  large: 23,
};

type Props = {
  size?: keyof typeof SIZES;
  stroke?: string;
};

export const BaseWaves = ({ size = 'small', stroke = 'currentColor', ...props }: Props) => {
  const dx = 18;
  const dy = 55;
  const strokeWidth = SIZES[size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`-${strokeWidth / 2} -${strokeWidth / 2} ${strokeWidth + 500} ${strokeWidth + 42}`}
      style={{ maxHeight: '12px' }}
      {...props}>
      <path
        fill="none"
        stroke={stroke}
        className="animated"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d={`
            M0,0 C${0 + dx},${dy} ${100 - dx},${dy} 100,0
            L100,0 C${100 + dx},${dy} ${200 - dx},${dy} 200,0
            L200,0 C${200 + dx},${dy} ${300 - dx},${dy} 300,0
            L300,0 C${300 + dx},${dy} ${400 - dx},${dy} 400,0
            L400,0 C${400 + dx},${dy} ${500 - dx},${dy} 500,0
          `}
      />
    </svg>
  );
};

type WavesProps = Props & { color?: keyof typeof colors; withMargin?: boolean };

export const Waves = styled(BaseWaves).attrs<WavesProps>(({ theme, color }) => ({
  stroke: color != null ? theme.palette.common[color] : theme.palette.primary,
}))<WavesProps>`
  /* FIXME: Ce composant ne devrait pas avoir de margin */
  margin-bottom: 20px;
`;
