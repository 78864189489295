import type { Category, Family, Navigation, RecipeCategory, SubCategory } from './types';

// Les différents "chemins" possibles dans la navigation
type Path =
  | {
      kind: 'family';
      family: { index: number; item: Family };
    }
  | {
      kind: 'category';
      family: { index: number; item: Family };
      category: { index: number; item: Category };
    }
  | {
      kind: 'subcategory';
      family: { index: number; item: Family };
      category: { index: number; item: Category };
      subcategory: { index: number; item: SubCategory };
    }
  | {
      kind: 'recipecategory';
      recipe: { index: null; item: RecipeCategory };
    };

/**
 * Permet de récupérer les informations à partir de l'ID d'un élément de la navigation.
 * Cette fonction est couteuse, et ne doit pas être utilisée pendant un render.
 * Le format de retour de la fonction dépend du type d'ID passé en paramètre.
 */
export const getPath = (nav: Navigation, id: string): Path | null => {
  // Si catégorie recette
  if (nav.recipe?.id === id) {
    return { kind: 'recipecategory', recipe: { index: null, item: nav.recipe } };
  }

  // Sinon, itération en profondeur, d'abord sur les familles
  for (let fIndex = 0; fIndex < nav.families.length; fIndex += 1) {
    const fItem = nav.families[fIndex];
    const family = { index: fIndex, item: fItem };
    if (fItem.id === id) return { kind: 'family', family };

    // Puis sur les catégories
    for (let cIndex = 0; cIndex < fItem.categories.length; cIndex += 1) {
      const cItem = fItem.categories[cIndex];
      const category = { index: cIndex, item: cItem };
      if (cItem.id === id) return { kind: 'category', family, category };

      // Et enfin sur les sous-catégories
      for (let sIndex = 0; sIndex < cItem.children.length; sIndex += 1) {
        const sItem = cItem.children[sIndex];
        const subcategory = { index: sIndex, item: sItem };
        if (sItem.id === id) return { kind: 'subcategory', family, category, subcategory };
      }
    }
  }

  // Aucune correspondance
  return null;
};

/** Retourne le chemin vers une famille à partir d'un ID */
export const getFamilyPath = (nav: Navigation, id: string) => {
  const match = getPath(nav, id);
  return match?.kind === 'family' ? match : null;
};

/** Retourne le chemin vers une catégorie à partir d'un ID */
export const getCategoryPath = (nav: Navigation, id: string) => {
  const match = getPath(nav, id);
  return match?.kind === 'category' ? match : null;
};

/** Retourne le chemin vers une sous-catégorie à partir d'un ID */
export const getSubCategoryPath = (nav: Navigation, id: string) => {
  const match = getPath(nav, id);
  return match?.kind === 'subcategory' ? match : null;
};

/** Retourne le chemin vers la catégorie recette à partir d'un ID */
export const getRecipeCategoryPath = (nav: Navigation, id: string) => {
  const match = getPath(nav, id);
  return match?.kind === 'recipecategory' ? match : null;
};
