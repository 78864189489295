export default {
  'title': 'Mon panier',
  'aria-label-close': 'Fermer le panier',
  'article_0': '0 article',
  'article_1': '1 article',
  'article': '%{count} articles',
  'delivery': 'Livraison',
  'free': 'OFFERTE',
  'until-free-delivery': 'Plus que %{amount} avant la livraison offerte',
  'min-amount-message': 'Minimum de commande de %{amount} non atteint',
  'before-free-delivery': "Livraison offerte dès %{amount} d'achat",
  'empty-message': 'Votre panier est vide',
  'empty-sub-message': 'Retrouvez de nouveaux arrivages de produits frais tous les jours !',
  'submit': 'Valider mon panier',
  'estimated-total': 'Total estimé',
  'button-label': 'Tout supprimer',
  'confirm-title-part-1': 'Voulez-vous vraiment ',
  'confirm-title-part-2': 'vider votre panier ?',
  'updated-products-label': '%{count} produit de votre panier a été modifié',
  'updated-products-label_plural': '%{count} produits de votre panier ont été modifiés',
  'unavailable-products': {
    'title': '%{count} produit indisponible',
    'title_plural': '%{count} produits indisponibles',
    'button-label': 'Remplacer',
  },
  'incomplete-products': {
    'title': '%{count} produit incomplet',
    'title_plural': '%{count} produits incomplets',
    'button-label': 'Compléter',
    'explanation':
      'Pour ces produits, nous ne disposons plus de toute la quantité demandée pour votre jour de livraison.',
  },
  'actions-panel': {
    'unavailable': {
      'title': 'Remplacer le produit',
      'product-section': 'Produit indisponible',
      'suggested-products-section': 'Produit de remplacement',
      'suggested-products-section_plural': 'Produits de remplacement',
      'button': {
        'label': 'Produit indisponible suivant',
        'last-product-label': 'Terminer',
      },
    },
    'incomplete': {
      'title': 'Compléter le produit',
      'product-section': 'Produit incomplet',
      'suggested-products-section': 'Produit pour compléter',
      'suggested-products-section_plural': 'Produits pour compléter',
      'button': {
        'label': 'Produit incomplet suivant',
        'last-product-label': 'Terminer',
      },
    },
    'empty-substitute-products-list-text':
      'Malheureusement les produits de substitution pour ce produit ne sont pas disponibles, mais ils seront bientôt de retour à la vente',
    'show-more-products': 'Afficher plus de produits',
    'deleted-from-cart': 'Supprimé de votre panier',
    'replaced-from-cart': 'Produit remplacé dans votre panier',
    'update-cart-quantity-incomplete-error':
      'Le produit incomplet a vu sa quantité disponible changer pour le créneau sélectionné',
  },
};
