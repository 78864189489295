export default {
  title: 'Mes communications',
  subtitle: 'Nos newsletters et alertes',
  text: 'Vitaminez votre semaine en choisissant ce que vous préférez recevoir.',
  email: {
    'aria-label': {
      accepted: 'communication par email acceptées',
      denied: 'communication par email refusées',
    },
    'title': 'Email',
    'description':
      'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par email',
  },
  sms: {
    'aria-label': {
      accepted: 'communication par sms acceptées',
      denied: 'communication par sms refusées',
    },
    'title': 'SMS',
    'description':
      'Je souhaite recevoir des offres, des idées de recettes et les nouveaux arrivages en avant-première par SMS',
  },
};
