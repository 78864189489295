import React from 'react';

import { useRouter } from 'next/router';

import I18n from '~/src/common/services/I18n';

import * as S from '../layout';

interface Props {
  onClickCGV: () => void;
  onClickSpecialOperations: () => void;
  onClickCookies: () => void;
  onClickPrivacy: () => void;
}

const LegalSection = ({
  onClickCGV,
  onClickSpecialOperations,
  onClickCookies,
  onClickPrivacy,
}: Props) => {
  const { pathname } = useRouter();

  return (
    <S.LegalPart $isAlcoholDisclaimerDisplayed={pathname.startsWith('/categorie/')}>
      <S.LegalLinksContainer>
        <S.LegalLink href="/cgv" target="_blank" onClick={onClickCGV}>
          {I18n.t('footer.cgv')}
        </S.LegalLink>

        <S.LegalLink onClick={onClickSpecialOperations}>
          {I18n.t('footer.special-operations-policies')}
        </S.LegalLink>
        <S.LegalLink onClick={onClickCookies}>{I18n.t('footer.cookies')}</S.LegalLink>
        <S.LegalLink
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
            window.Didomi.preferences.show();
          }}>
          {I18n.t('footer.cookies-management')}
        </S.LegalLink>
        <S.LegalLink onClick={onClickPrivacy}>{I18n.t('footer.privacy')}</S.LegalLink>
      </S.LegalLinksContainer>
    </S.LegalPart>
  );
};

export default LegalSection;
