import React from 'react';

import { useQuery } from '@tanstack/react-query';

import Link from '~/src/common/components/Link';

import { getLinksForSlot } from '../fetch';

type Props = {
  slot: string;
  onClick?: (link: { url: string; text: string }) => void;
};

const SlotLinks = ({ slot, onClick }: Props) => {
  const { data } = useQuery(...getLinksForSlot(slot));

  return data?.results.map(link => (
    <Link
      key={link.id}
      href={link.data.url}
      target={link.data.target}
      rel={link.data.rel?.join(' ')}
      onClick={() => onClick?.(link.data)}>
      {link.data.text}
    </Link>
  ));
};

export default SlotLinks;
