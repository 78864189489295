import useDelayedValue from '~/src/common/hooks/use-delayed-value';

import { HIDE_OUTPUT_DELAY, MIN_SEARCH_QUERY_LENGTH } from '../../constants';

import * as S from './layout';
import Popular from './Popular/Popular';
import Suggestions from './Suggestions/Suggestions';

type Props = {
  query: string;
  active: boolean;
};

const Output = ({ query, active }: Props) => {
  // On affiche le contenu si la recherche est active, ou l'était récemment
  // Ce délai est utile pour laisser les animations se terminer correctement
  const wasActive = useDelayedValue(active, HIDE_OUTPUT_DELAY);
  const showContent = active || wasActive;

  // Le contenu dépends de la longueur de la recherche
  const showPopular = query.length < MIN_SEARCH_QUERY_LENGTH;
  const showSuggestions = query.length >= MIN_SEARCH_QUERY_LENGTH;

  return (
    <S.Container>
      {showContent && (
        <>
          {showPopular ? <Popular /> : null}
          {showSuggestions ? <Suggestions query={query} /> : null}
        </>
      )}
    </S.Container>
  );
};

export default Output;
