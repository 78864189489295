import styled, { css } from 'styled-components';

import ButtonBase from './BaseButton';

export const cssStyle = css`
  color: ${({ theme }) => theme.palette.common.WHITE};
  background: ${({ theme }) => theme.palette.primary};
  border-color: ${({ theme }) => theme.palette.primary};

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    background: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    color: ${({ theme }) => theme.palette.common.WHITE}; /* override du hover d'un lien */
  }
`;

const PrimaryButton = styled(ButtonBase)`
  ${cssStyle}
`;

export default PrimaryButton;
