import styled from 'styled-components';

import { media } from '~/src/styles-constants';

const BaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

export const Grid = styled(BaseGrid)`
  ${media.S`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(198px, 1fr));
    gap: 10px;
  `}
`;

export const EmptyText = styled.p`
  text-align: center;
  font-size: 12px;
  font-style: italic;
  padding-top: 40px;
`;
