/* eslint-disable no-fallthrough */

import I18n from '~/src/common/services/I18n';
import { Order } from '~/src/common/typings/order';
import { MarketingHeader, ParsedMarketingHeader } from '~/src/screens/App/Header/types';

export const transformHomeHeader = (cmsHeader: MarketingHeader): ParsedMarketingHeader => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
  const media = cmsHeader.image?.media != null ? JSON.parse(cmsHeader.image.media) : null;

  const stringifiedHeader = {
    ...cmsHeader,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
    image: media,
  };

  return stringifiedHeader;
};

export const getOrderStatusLabel = (state: Order['state']) => {
  let statusLabel = I18n.t('home.order.accepted');
  switch (state) {
    case 'preparing':

    case 'prepared':

    case 'approvedByClient': {
      statusLabel = I18n.t('home.order.preparing');

      break;
    }

    case 'ready':
      statusLabel = I18n.t('home.order.pickup-ready');

      break;

    case 'cart':

    case 'inTransit':

    case 'readyToDeliver':
      statusLabel = I18n.t('home.order.delivering');

      break;

    default:
      statusLabel = I18n.t('home.order.accepted');

      break;
  }

  return statusLabel;
};

export const getOrderStatusCompletion = (state: Order['state']) => {
  let statusStepCompletion = 1;
  const statusSteps = 3;

  switch (state) {
    case 'preparing':

    case 'prepared':

    case 'approvedByClient': {
      statusStepCompletion = 2;

      break;
    }

    case 'ready':

    case 'cart':

    case 'inTransit':

    case 'readyToDeliver':
      statusStepCompletion = 3;

      break;

    default:
      statusStepCompletion = 1;

      break;
  }

  return `${statusStepCompletion}/${statusSteps}`;
};
