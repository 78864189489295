import styled, { css } from 'styled-components';

import ButtonBase from './BaseButton';

export const cssStyle = css`
  color: ${({ theme }) => theme.palette.primary};
  background: ${({ theme }) => theme.palette.common.WHITE};
  border-color: ${({ theme }) => theme.palette.primary};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    border-color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
  }
`;

const SecondaryButton = styled(ButtonBase)`
  ${cssStyle}

  &&:disabled {
    ${({ theme, $isLoading }) =>
      !$isLoading
        ? `
        background: ${theme.palette.common.WHITE};
        color: ${theme.palette.common.DISABLED};
      `
        : ''};
  }
`;

export default SecondaryButton;
