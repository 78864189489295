import type { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import I18n from '~/src/common/services/I18n';
import Tracker from '~/src/common/services/Tracker';

import { SEARCHBAR_ID } from '../../constants';
import { createSearchID, createSearchURL, enrichSearchURL } from '../../utils';

import * as S from './layout';

const SEARCH_TYPE = 'free';

type Props = {
  query: string;
  active: boolean;
  setQuery: Dispatch<SetStateAction<string>>;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const Input = ({ active, query, setActive, setQuery }: Props) => {
  // Référence vers le noeud DOM de l'input
  const ref = useRef<HTMLInputElement>(null);

  // Navigation vers la recherche
  const router = useRouter();
  const navigateToSearch = () => {
    const searchID = createSearchID();
    const displayURL = createSearchURL(query);
    const enrichedURL = enrichSearchURL(displayURL, { source: SEARCH_TYPE, searchID });

    void router.push(enrichedURL, displayURL);
    Tracker.sendEvent('search completed', {
      'search type': SEARCH_TYPE,
      'search ID': searchID,
      'user input': query,
      'keywords': query,
    });
  };

  // Basic event handlers
  const handleFocus = () => setActive(true);
  const handleReset = () => void (setQuery(''), ref.current?.focus());
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value);

  // Keyboard event handlers
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Passe la main à la navigation des suggestions via le clavier
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.currentTarget.blur();
    // Lance la recherche si la requête de l'utilisateur est non-vide
    if (e.key === 'Enter' && query.length) navigateToSearch();
    // Evite les interferances avec les deplacements de page ou caroussel
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') e.stopPropagation();
  };

  // Autofocus à l'ouverture de la recherche
  useEffect(() => void (active && ref.current?.focus()), [active]);

  // Fermeture et blur de l'input lors de la navigation
  useEffect(() => void (setActive(false), ref.current?.blur()), [router.asPath, setActive]);

  // Gestion du placeholder
  const placeholder = active
    ? I18n.t('search.bar.placeholder.active')
    : I18n.t('search.bar.placeholder.simple');

  return (
    <S.Container>
      <S.IconSearch name="search-normal" />
      <S.Field
        ref={ref}
        value={query}
        id={SEARCHBAR_ID}
        autoComplete="off"
        placeholder={placeholder}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {query ? <S.IconReset name="cross" onClick={handleReset} /> : null}
    </S.Container>
  );
};

export default Input;
