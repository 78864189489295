import Script from 'next/script';

import Tracker from '~/src/common/services/Tracker';
import constants from '~/src/env-constants';

import { logger } from '../../services/Logger';

if (typeof window !== 'undefined') {
  // Typings locaux
  type Context = Record<string, unknown>;
  type Listener = { event: string; listener: (ctx: Context) => void };
  type EventConfig = [event: string, enrich?: (ctx: Context) => unknown];

  // Définition des événements à remonter à Amplitude
  const events = [
    // Les events nécessitant un enrichissement
    ['consent.changed', ctx => ({ action: ctx.action })],
    [
      'integrations.consentpassedtodfp',
      ctx => ({ 'consent status': ctx.consentStatus, 'index': ctx.index }),
    ],
    ['preferences.clickcategoryagree', ctx => ({ 'category Id': ctx.categoryId })],
    ['preferences.clickcategorydisagree', ctx => ({ 'category Id': ctx.categoryId })],
    ['preferences.clickpurposeagree', ctx => ({ 'purpose Id': ctx.purposeId })],
    ['preferences.clickpurposedisagree', ctx => ({ 'purpose Id': ctx.purposeId })],
    ['preferences.clickvendoragree', ctx => ({ 'vendor Id': ctx.vendorId })],
    ['preferences.clickvendordisagree', ctx => ({ 'vendor Id': ctx.vendorId })],
    // Les events a forward sans autre traitement
    ['consent.pendingchanged'],
    ['notice.backdropclick'],
    ['notice.clickagree'],
    ['notice.clickclose'],
    ['notice.clickdisagree'],
    ['notice.clickmoreinfo'],
    ['notice.clickviewvendors'],
    ['notice.hidden'],
    ['notice.shown'],
    ['preferences.clickagreetoall'],
    ['preferences.clickclose'],
    ['preferences.clickdisagreetoall'],
    ['preferences.clicksavechoices'],
    ['preferences.clickvendorsavechoices'],
    ['preferences.clickviewvendors'],
    ['preferences.hidden'],
    ['preferences.shown'],
  ] satisfies EventConfig[];

  // Conversion des événements en listeners Didomi
  const listeners: Listener[] = events.map(([event, enrich]) => ({
    event,
    listener(ctx: Context) {
      try {
        // @ts-expect-error -- Comme on utilise le CDN, on ne peut pas typer
        Tracker.sendEvent('didomi action', { type: event, ...enrich?.(ctx) });
      } catch (originalError) {
        logger.error('Error while logging didomi event', { event, ctx, originalError });
      }
    },
  }));

  // Injection dans l'objet global -- Typescript pleure un peu
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
  window.didomiEventListeners = window.didomiEventListeners || [];
  (window.didomiEventListeners as Listener[]).push(...listeners);
}

const Didomi = () => (
  <Script id="script-didomi" strategy="afterInteractive">
    {`
window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("${constants.DIDOMI_KEY}")})();
`}
  </Script>
);

export default Didomi;
