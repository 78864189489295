export default {
  'error': 'Erreur',
  'back-to-home': "Revenir à l'accueil",
  '404': {
    title: '404',
    message: "Oups, nous n'avons pas trouvé cette page. Elle a dû être déplacée ou n'existe plus.",
  },
  '500': {
    title: '500',
    message: 'Oups, une erreur est survenue.',
  },
};
