import { createInputs } from '../../utils';

const allowedLayouts = [
  { value: 'v1', label: '[Titre] [Text] [Action] [Image]' },
  { value: 'v2', label: '[Image] [Titre] [Text] [Action]' },
];

const allowedActions = [
  { value: 'LOGIN', label: "Bouton d'identification" },
  { value: 'ADDRESS', label: "Bloc de saisie d'adresse" },
];

export default createInputs([
  { name: 'layout', type: 'string', required: true, enum: allowedLayouts },
  { name: 'bgimg', type: 'file', required: true },
  { name: 'title', type: 'richText', required: true },
  { name: 'image', type: 'file' },
  { name: 'text', type: 'richText', required: true },
  { name: 'code', type: 'string', required: true },
  { name: 'action', type: 'string', required: true, enum: allowedActions },
]);
