import type { MouseEvent } from 'react';
import { memo } from 'react';

import { useRouter } from 'next/router';

import Tracker from '~/src/common/services/Tracker';

import { createSearchID, enrichSearchURL, createSearchURL } from '../../../utils';

import * as S from './layout';

const SEARCH_TYPE = 'Popular term';

type Props = {
  word: string; // Le mot clé retourné par l'api
};

const PopularWord = ({ word }: Props) => {
  // Url de la page de résultats
  const displayURL = createSearchURL(word);

  // Amplitude: Interception du click et ajout des params d'analytics
  const router = useRouter();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    const searchID = createSearchID();
    const enrichedURL = enrichSearchURL(displayURL, { source: SEARCH_TYPE, searchID });
    void router.push(enrichedURL, displayURL);
    Tracker.sendEvent('search completed', {
      'search type': SEARCH_TYPE,
      'search ID': searchID,
      'user input': '',
      'keywords': word,
    });
  };

  return (
    <S.Entry href={displayURL} onClick={handleClick}>
      {word}
    </S.Entry>
  );
};

export default memo(PopularWord);
