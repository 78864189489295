import type { MouseEvent } from 'react';
import { memo } from 'react';

import { useRouter } from 'next/router';

import Highlight from '~/src/common/components/Highlight/Highlight';
import Tracker from '~/src/common/services/Tracker';

import { createSearchID, createSearchURL, enrichSearchURL } from '../../../utils';

import * as S from './layout';

const SEARCH_TYPE = 'suggestion';

type Props = {
  query: string; // La recherche de l'utilisateur
  keyword: string; // Le mot clé retourné par l'api
};

const SuggestionsKeyword = ({ keyword, query }: Props) => {
  // Url de la page de résultats
  const displayURL = createSearchURL(keyword);

  // Amplitude: Interception du click et ajout des params d'analytics
  const router = useRouter();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    const searchID = createSearchID();
    const enrichedURL = enrichSearchURL(displayURL, { source: SEARCH_TYPE, searchID });
    void router.push(enrichedURL, displayURL);
    Tracker.sendEvent('search completed', {
      'search type': SEARCH_TYPE,
      'search ID': searchID,
      'user input': query,
      'keywords': keyword,
    });
  };

  return (
    <S.Entry href={displayURL} onClick={handleClick}>
      <S.Icon name="search-normal" />
      <S.Text>
        <Highlight input={keyword} query={query} />
      </S.Text>
    </S.Entry>
  );
};

export default memo(SuggestionsKeyword);
