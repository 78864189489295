export default {
  'title': 'Modifier mon mot de passe',
  'toast-success-message': 'Vos informations ont bien été sauvegardées',
  'form': {
    'current-password': {
      label: 'Mot de passe*',
      placeholder: 'Mot de passe',
    },
    'new-password': {
      label: 'Nouveau mot de passe*',
      placeholder: 'Mot de passe',
    },
    'submit-button': 'Modifier mon mot de passe',
  },
  'server-error': {
    message: 'Mot de passe actuel incorrect',
  },
};
