import { logger } from '~/src/common/services/Logger';

const KEY = 'referral-modal-opened';

export const hasReferralModalOpened = () => {
  let isReferralModalOpened: string | null = 'false';

  try {
    isReferralModalOpened = localStorage.getItem(KEY);
  } catch (e) {
    logger.info('Erreur lors de la récuperation du local storage (modal parrainage)', {
      originalError: e as Error,
    });
  }

  if (isReferralModalOpened == null) {
    return false;
  }

  return isReferralModalOpened === 'true';
};

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
export const persitReferralModalOpened = (v: boolean) => localStorage.setItem(KEY, `${v}`);
