export default {
  'title': 'Mes moyens de paiement',
  'toast-success-message': 'Vos informations ont bien été sauvegardées',
  'add-card-button': 'Ajouter une carte',
  'security-message':
    "Toutes vos informations bancaires sont cryptées et sauvegardées sur des services bancaires spécialisés. \nNous n'enregistrons pas vos coordonnées sur nos serveurs.",
  'add-card-modal': {
    'title': 'Ajouter une carte',
    'submit-button': 'Enregistrer ma carte',
    'unknown-error': "Une erreur est survenue lors de l'ajout de carte",
  },
  'no-card': 'Aucun moyen de paiement enregistré',
  'delete-confirmation-dialog': {
    message: 'Voulez-vous vraiment supprimer cette carte ?',
    title: 'Suppression de votre carte',
  },
  'delete-confirmation-toast-message': 'Votre carte a bien été supprimée',
};
