import { v4 } from 'uuid';

import { tryParseUrl } from '~/src/common/utils/url';
import constants from '~/src/constants';

import { SEARCH_QS, SEARCHBAR_ID } from './constants';
import { EnrichmentData } from './types';

/** Pour créer un identifiant de recherche */
export const createSearchID = () => v4();

/** Construit l'url de la page pour affichage des résultats de recherche */
export const createSearchURL = (term: string) => {
  return `/recherche?${SEARCH_QS.TERM}=${encodeURIComponent(term)}`;
};

/** Enrichi une url de recherche avec les parametres pour Amplitude */
export const enrichSearchURL = (url: string, extra: EnrichmentData) => {
  const parsed = tryParseUrl(url, constants.SITE_URL);
  if (extra.source) parsed?.searchParams.set(SEARCH_QS.SOURCE, extra.source);
  if (extra.searchID) parsed?.searchParams.set(SEARCH_QS.SEARCHID, extra.searchID);
  return parsed ? `${parsed.pathname}${parsed.search}` : url;
};

/** Permet d'ouvrir la recherche directement via un simple appel de fonction */
export const focusSearchBar = () => {
  const node = document.getElementById(SEARCHBAR_ID);
  if (node) node.focus?.();
};
