import { MouseEventHandler } from 'react';

import Spinner from '~/src/common/components/Spinner';
import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker, { DELIVERY_ORDER_STATUS } from '~/src/common/services/Tracker';
import {
  daysDiff,
  getDeliveryDate,
  getTimeslotIntervalHourLabelInfos,
} from '~/src/common/utils/date';
import { getDeliveryIconName, getDeliveryLabelName } from '~/src/common/utils/delivery';
import {
  CurrentOrders200ItemsItem,
  CurrentOrders200ItemsItemDelayDuration,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import ConfettisLayout from '~/src/screens/Home/InProgressOrdersBanner/ConfettisLayout';
import { getOrderStatusLabel, getOrderStatusCompletion } from '~/src/screens/Home/utils';
import { OngoingOrderState } from '~/src/typings/orders/types';

import { isDelivery } from '../../App/CatalogLayout/utils';

import * as S from './layout';
import { useEstimatedTimeSlot } from './useEstimatedTimeSlot.hook';

interface Props {
  orderId: string;
  state: OngoingOrderState;
  delivery: CurrentOrders200ItemsItem['delivery'];
  delay?: CurrentOrders200ItemsItemDelayDuration;
  isJaugeProgressionDisplayed: boolean;
}

const InProgressOrderBanner = ({
  orderId,
  state,
  delivery,
  delay,
  isJaugeProgressionDisplayed,
}: Props) => {
  const estimatedTimeSlot = useEstimatedTimeSlot(orderId, delivery);
  const modal = useNiceModal('delivery-tracking-drawer');

  const { deliveryZone } = delivery;

  const deliveryType = isDelivery(delivery)
    ? I18n.t(`common.${getDeliveryLabelName(deliveryZone?.pickupType)}`)
    : `${I18n.t('common.pickup')}`;

  const from = estimatedTimeSlot?.[0] ?? delivery.timeSlot.from;
  const to = estimatedTimeSlot?.[1] ?? delivery.timeSlot.to;

  const handleBannerClick: MouseEventHandler<HTMLAnchorElement> = event => {
    if (isDelivery(delivery)) {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      modal.show();
      return;
    }

    const fromDayJs = dayjs(from);
    const startTime = fromDayJs.format('HH[h]mm');
    const endTime = dayjs(to).format('HH[h]mm');

    Tracker.sendEvent('click order tracking panel', {
      'shipping type': deliveryZone?.type === 'delivery' ? 'delivery' : 'pickup',
      'pickup mode': deliveryZone?.pickupType,
      'shipping slot': `${startTime}-${endTime}`,
      'shipping slot days': daysDiff(from),
      'cart id': orderId,
      'order status': DELIVERY_ORDER_STATUS[state],
    });
  };

  const displayDelay = delay != null && estimatedTimeSlot === null;

  const deliverySlotInfos = (
    <S.InfoContainer>
      <S.DeliveryInfo>
        <S.PickupIcon
          name={getDeliveryIconName(deliveryZone?.pickupType)}
          color="PRIMARY"
          size={16}
        />
        <span>{`${deliveryType} - `}</span>
        <S.DeliveryInfoDate>{`${getDeliveryDate(from)} - `}</S.DeliveryInfoDate>
        <S.DeliveryTime $hasLineThrough={displayDelay}>
          {getTimeslotIntervalHourLabelInfos(from, to)}
        </S.DeliveryTime>
        {displayDelay ? (
          <S.DeliveryTimeWithDelay>
            {getTimeslotIntervalHourLabelInfos(from, to, delay)}
          </S.DeliveryTimeWithDelay>
        ) : null}
      </S.DeliveryInfo>
    </S.InfoContainer>
  );

  return (
    <S.Container $isJaugeProgressionDisplayed={isJaugeProgressionDisplayed}>
      <S.StyledLink href={{ pathname: `/account/orders/${orderId}` }} onClick={handleBannerClick}>
        <S.Content>
          <ConfettisLayout>
            <Spinner>
              <S.SpinnerContent>{getOrderStatusCompletion(state)}</S.SpinnerContent>
            </Spinner>
            <S.InProgressOrdersTitle>{getOrderStatusLabel(state)}</S.InProgressOrdersTitle>
            <S.DesktopDeliverySlotInfos>{deliverySlotInfos}</S.DesktopDeliverySlotInfos>
          </ConfettisLayout>
          <S.MobileDeliverySlotInfos>{deliverySlotInfos}</S.MobileDeliverySlotInfos>
        </S.Content>
      </S.StyledLink>
    </S.Container>
  );
};

export default InProgressOrderBanner;
