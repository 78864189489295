import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'section:promo_explanations';
const PromoExplanations = dynamic(() => import('./Component'));
export default createComponent(BuilderId, PromoExplanations, inputs, {
  friendlyName: 'Section explication promotions',
});
