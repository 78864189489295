export default {
  'title': 'Mes favoris',
  'banner': 'Retrouvez ici vos produits préférés.',
  'bookmarks': {
    'empty-products': 'Vous n’avez aucun produit dans vos favoris',
    'add-products-button': 'Ajouter des produits',
  },
  'add-products-to-cart': {
    'label': 'Ajouter tous les produits à mon panier',
    'message': '%{count} produit ajouté à votre panier',
    'message_plural': '%{count} produits ajoutés à votre panier',
    'action-button-label': 'Voir mon panier',
    'error':
      'Certains produits ne sont pas disponibles pour le créneau sélectionné, votre liste a été mise à jour, veuillez réessayer',
  },
};
