import { logger } from '../services/Logger';

type GetService<T> = () => T | null | undefined;
type ServiceCallback<T> = (service: T) => void;
type CallbackWrapper<T, U extends unknown[]> = (service: T, ...args: U) => void;
type Config<T> = { name: string; getService: GetService<T>; maxQueueLength?: number };

const withServiceQueue = <T>(config: Config<T>) => {
  // Récupération de la config, assignation des valeurs par défaut
  const { name, getService, maxQueueLength = 50 } = config;

  /**
   * Stockage des callback en attente de disponibilité du service.
   */
  const queue: ServiceCallback<T>[] = [];

  /**
   * Vérifie la disponibilité du service et dépile la file d'attente.
   */
  const dequeue = () => {
    const service = getService();
    if (service == null) return;
    while (queue.length) {
      const cb = queue.shift();
      if (cb) cb(service);
    }
  };

  /**
   * Ajoute un élément à la file d'attente, et dépile si possible.
   * Si la file d'attente a atteint le maximum autorisé, tout les
   * traitements sont suspendu et un warning est remonté via logger
   */
  const enqueue = (cb: ServiceCallback<T>) => {
    // Blocage pour éviter les fuites de mémoire
    if (queue.length >= maxQueueLength) return;
    // Ajout et traitement de la file d'attente
    queue.push(cb);
    dequeue();
    // Envoi du warning en cas de dépassement de la limite
    if (queue.length === maxQueueLength) {
      logger.warning(`Callback queue for ${name} is full`);
    }
  };

  /**
   * High-order function permettant de wrapper un callback pour intégrer le
   * systeme de file d'attente et de vérification de disponibilité du service
   */
  const withService = <U extends unknown[]>(cb: CallbackWrapper<T, U>) => {
    return (...params: U) => enqueue(service => cb(service, ...params));
  };

  // Vérification reguliere de la disponibilité du service
  const timer = setInterval(() => {
    if (getService() == null) return;
    clearInterval(timer);
    dequeue();
  }, 500);

  // Seul le HOF est retourné, le reste est privé
  return withService;
};

export { withServiceQueue };
