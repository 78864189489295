export const getBrowser = () => {
  const { userAgent } = navigator;
  let browserInfos =
    userAgent.match(
      /(opera|chrome|safari|firefox|samsungBrowser|msie|trident(?=\/))\/?\s*(\d+)/i,
    ) || [];

  if (/trident/i.test(browserInfos[1])) {
    const version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return `IE ${version[1]}`;
  }

  if (browserInfos[1] === 'Chrome') {
    const matchingInfos = userAgent.match(/\b(OPR|Edge|UCBrowser)\/(\d+)/);

    if (matchingInfos != null) {
      const [, browser, version] = matchingInfos;

      return `${browser} ${version}`;
    }
  }

  browserInfos = browserInfos[2]
    ? [browserInfos[1], browserInfos[2]]
    : [navigator.appName, navigator.appVersion, '-?'];

  const version = userAgent.match(/version\/(\d+)/i);

  if (version != null) {
    browserInfos.splice(1, 1, version[1]);
  }

  return `${browserInfos[0]} ${browserInfos[1]}`;
};

export const getOs = () => {
  const { userAgent } = navigator;

  if (userAgent.includes('Windows')) return 'Windows';

  if (userAgent.includes('Android')) return 'Android';

  if (userAgent.includes('iPhone')) return 'iOS';

  if (userAgent.includes('Mac')) return 'Mac OS';

  if (userAgent.includes('X11')) return 'UNIX';

  if (userAgent.includes('Linux')) return 'Linux';

  return 'Autres';
};
