import { useEffect, useState } from 'react';

/**
 * Ce hook retournes des informations à propos de l'écran de l'utilisateur.
 * Aujourd'hui, seul le `visualViewport` est disponible, mais il est possible
 * de le faire évoluer afin de retourner d'autres indicateurs, comme par exemple,
 * sur mobile, l'état du clavier (ouvert/fermé) ou la visibilité de la barre d'url.
 */
const useScreenInfos = () => {
  type State = { width: number; height: number } | null;
  const [viewport, setViewport] = useState<State>(null);

  useEffect(() => {
    const handler = () => {
      const width = window.visualViewport?.width ?? window.innerWidth;
      const height = window.visualViewport?.height ?? window.innerHeight;
      setViewport({ width, height });
    };

    window.addEventListener('resize', handler);
    window.visualViewport?.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
      window.visualViewport?.removeEventListener('resize', handler);
    };
  }, []);

  return { viewport };
};

export default useScreenInfos;
