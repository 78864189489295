export const transparentize = (hexColor: string, opacity: number) => {
  const [, color = null] = hexColor.match(/^#?((?:[0-9A-F]{3}){1,2})$/i) || [];

  if (opacity < 0 || opacity > 1) throw new Error('Bad opacity');

  if (color == null) throw new Error('Bad hex color');

  const components =
    color.length === 3
      ? [color[0] + color[0], color[1] + color[1], color[2] + color[2]]
      : [color[0] + color[1], color[2] + color[3], color[4] + color[5]];

  const [r, g, b] = components.map(c => parseInt(c, 16));

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * Retourne une média-query correspondant aux valeurs `min` et `max` spécifiées.
 *
 * Quelques notes :
 * - La syntaxe `(min <= width < max)` n'est pas disponible avant iOS 16.4, et n'est donc pas utilisée
 * - `min-width` et `max-width` sont inclusifs, donc `min-width: 0` et `max-width: 600` incluent 0 et 600
 * - Le point précédent est problématique pour définir des intervalles car elles peuvent se chevaucher
 * - Pour cette raison, la valeur `max` est réduite de `0.1px` afin d'éviter les chevauchements
 *
 * @exemple
 * ```ts
 * getMediaQuery(0, 600); // (0 <= width < 600)
 * => `screen and (min-width: 0px) and (max-width: 599.9px)`
 *
 * getMediaQuery(600); // (600 <= width)
 * => `screen and (min-width: 600px)`
 *
 * getMediaQuery(); // (0 <= width)
 * => `screen and (min-width: 0px)`
 * ```
 */
export const getMediaQuery = (min: number | null, max: number | null) => {
  const minQuery = `(min-width: ${min ?? 0}px)`;
  const maxQuery = max != null && `(max-width: ${max - 0.1}px)`;
  return ['screen', minQuery, maxQuery].filter(Boolean).join(' and ');
};
