import styled from 'styled-components';

import ButtonBase from './BaseButton';

const SecondaryLinkButton = styled(ButtonBase)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};

  height: auto;
  margin: 0;
  padding: 0;
  margin-left: -25px;
  margin-right: -25px;

  span {
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  & span:not([class*=' icon-']),
  & span:not([class^='icon-']) {
    text-decoration: underline;
  }

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
    background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  }

  &&:disabled {
    cursor: not-allowed;
    ${({ theme, $isLoading }) =>
      !$isLoading
        ? `
        background: ${theme.palette.common.TRANSPARENT};
        color: ${theme.palette.common.DISABLED};
        border-color: ${theme.palette.common.TRANSPARENT};
      `
        : ''};
  }
`;

export default SecondaryLinkButton;
