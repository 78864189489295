import React from 'react';

import * as S from './layout';

const Confetti = () => (
  <S.ConfettiContainer>
    <div />
    <div />
  </S.ConfettiContainer>
);

const ConfettisLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <S.LayoutContainer>
      <S.ConfettisBlock $isRevert={false}>
        <Confetti />
        <Confetti />
      </S.ConfettisBlock>
      {children}
      <S.ConfettisBlock $isRevert>
        <Confetti />
        <Confetti />
      </S.ConfettisBlock>
    </S.LayoutContainer>
  );
};

export default ConfettisLayout;
