import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'section:testimonial';
const Testimonials = dynamic(() => import('./Component'));
export default createComponent(BuilderId, Testimonials, inputs, {
  friendlyName: 'Section témoignage',
});
