import { createInputs } from '../../utils';

// Pour la validation des liens internes
const linkPattern = '^/[a-z0-9/-]*(\\?.*)?$';
const linkDetails = 'Liens internes uniquement, merci de ne fournir que le chemin de la page.';
const linkSamples = 'Par exemple `/plan-du-site` ou `/categorie/boissons`.';
const regex = { pattern: linkPattern, message: `${linkDetails} ${linkSamples}` };

// Les liens hors titre
const subFields = createInputs([
  { name: 'title', type: 'string', required: true },
  { name: 'link', type: 'string', required: true, regex },
]);

export default createInputs([
  { name: 'title', type: 'string', required: true },
  { name: 'link', type: 'string', regex },
  { name: 'items', type: 'list', required: true, subFields },
]);
