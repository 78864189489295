import styled from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

/** Les élément doivent avoir la même taille, d'où le flex et le width */
export const Container = styled.div`
  width: 1px;
  flex: 1 1 1px;

  /* Mobile: Largeur et hauteur fixe */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    width: auto;
    height: auto;
    flex: 0 0 auto;
  }
`;
