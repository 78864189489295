/**
 * Retourne une copie de l'objet passé en parametre après avoir supprimé
 * les clés définies par le second parametre (string ou string[])
 */
export const omit = <T>(data: T, keys: keyof T | (keyof T)[]) => {
  const result = { ...data };
  const fields = Array.isArray(keys) ? keys : [keys];
  fields.forEach(key => delete result[key]);
  return result;
};

/**
 * Retourne une copie de l'objet passé en parametre après avoir supprimé
 * les clés définies par le second parametre (string ou string[])
 */
export const pick = <T>(data: T, keys: (keyof T)[]) => {
  const result: Partial<T> = {};
  const fields = Array.isArray(keys) ? keys : [keys];
  fields.forEach(key => Object.assign(result, { [key]: data[key] }));
  return result;
};
