import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderID = 'block:links_box';
const Component = dynamic(() => import('./Component'));
export default createComponent(BuilderID, Component, inputs, {
  friendlyName: 'Bloc de liens',
});
