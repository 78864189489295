import styled from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

import { TRANSITION_DURATION } from '../../constants';

/** L'ensemble des resultats affichés */
export const Container = styled.div`
  padding: 16px;
  margin-top: 8px;
  max-height: calc(100vh - 128px);

  overflow: auto;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.common.WHITE};

  opacity: 0;
  pointer-events: none;
  transition: opacity ${TRANSITION_DURATION}ms ease-in-out;

  /* Invisible si vide */
  &&:empty {
    opacity: 0;
    pointer-events: none;
  }

  /* Hors desktop: Toujours visible */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    opacity: 1;
  }
`;
