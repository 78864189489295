export default {
  'no-address-title': 'On vous livre où ?',
  'no-slot-title': 'A quelle heure ?',
  'no-address-text':
    'Afin de garantir la disponibilité des produits, veuillez indiquer votre adresse de livraison.',
  'no-slot-text': 'Afin de garantir la disponibilité des produits, veuillez choisir votre créneau.',
  'close-button': 'Plus tard',
  'no-address-button-text': 'Indiquer mon adresse',
  'no-slot-button-text': 'Choisir mon créneau',
};
