import styled from 'styled-components';

export const Container = styled.span`
  display: inline-block;
  border-radius: 4px;
  padding: 2px 4px 4px;

  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
  text-transform: uppercase;
`;
