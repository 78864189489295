import { useState } from 'react';

import { useExitIntent } from 'use-exit-intent';

import Braze from '~/src/common/services/Braze';

const useExitIntentSetup = () => {
  const { registerHandler } = useExitIntent();
  const [initialUrl] = useState(() => typeof window === 'object' && document.location.href);

  registerHandler({
    id: 'braze-exit-intent',
    handler: () => {
      const currentUrl = document.location.href;
      const isFirstPage = initialUrl === currentUrl;
      Braze.logCustomEvent('exit intent', {
        'page path': currentUrl,
        'first page': isFirstPage,
      });
    },
  });
};

export default useExitIntentSetup;
