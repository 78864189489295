import { withServiceQueue } from '~/src/common/utils/withServiceQueue';

const withZendesk = withServiceQueue({
  name: 'Zendesk',
  getService: () => (typeof window === 'object' ? window.zE : null),
});

const hide = withZendesk(zendesk => {
  zendesk('messenger', 'hide');
});

const show = withZendesk(zendesk => {
  zendesk('messenger', 'show');
});

const open = withZendesk(zendesk => {
  zendesk('messenger', 'open');
  zendesk('messenger', 'show');
});

const onClose = withZendesk((zendesk, cb: () => void) => {
  zendesk('messenger:on', 'close', cb);
});

const onOpen = withZendesk((zendesk, cb: () => void) => {
  zendesk('messenger:on', 'open', cb);
});

const Zendesk = {
  hide,
  show,
  open,
  onClose,
  onOpen,
};

export default Zendesk;
