export default {
  'title': 'Mes adresses de livraison',
  'add-button': 'Ajouter une adresse de livraison',
  'no-address': 'Aucune adresse enregistrée',
  'delete-confirmation-dialog': {
    message: 'Voulez-vous vraiment supprimer cette adresse ?',
    title: 'Suppression de votre adresse',
  },
  'delete-confirmation-toast-message': 'Votre adresse a bien été supprimée',
};
