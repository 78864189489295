export default {
  'header-title': 'au gramme près !',
  'title': 'Une pomme n’est pas toujours égale à une pomme !',
  'text':
    '<p>Nos produits ne sont pas calibrés <b>au gramme près</b> ! Lorsque nous sélectionnons vos produits, le poids peut être légèrement supérieur ou inférieur à la quantité commandée.</p><p>C’est pourquoi, pour les produits à poids variables (fruits et légumes, fromages à la coupe, viandes et poissons), nous demandons à votre banque de réserver <b>15 % supplémentaires</b> au moment de votre commande.</p><p>Lors de votre livraison, vous serez débités du montant réel en fonction du poids des produits livrés.</p>',
  'explanation': {
    'title': 'Pour un chou de <mark>1kg</mark> à <mark>1,00€</mark>/kg',
    'example': '<p>Cas n°%{number}</p><p>%{percent}%</p>',
    'net-weight': '<p>Poids net:</p><p>%{netWeight}kg</p>',
    'price': '<p>Prix:</p><p>%{price}€</p>',
    'offer-up': 'Ce qui dépasse <b>15% du poids</b> annoncé vous est <b>offert !</b>',
  },
};
