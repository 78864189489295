import { useEffect, useState, useCallback } from 'react';

import { getWindowWidth } from '~/src/common/utils/dom';

export const useShouldDisplayWidthThreshold = (threshold: number) => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  const handleIsDisplayed = useCallback(() => {
    setIsDisplayed(getWindowWidth() >= threshold);
  }, [threshold]);

  useEffect(() => {
    handleIsDisplayed();

    window.addEventListener('resize', handleIsDisplayed, false);

    return () => window.removeEventListener('resize', handleIsDisplayed, false);
  }, [handleIsDisplayed]);

  return isDisplayed;
};
