import type { MouseEvent } from 'react';
import { memo } from 'react';

import { useRouter } from 'next/router';

import Highlight from '~/src/common/components/Highlight/Highlight';
import Tracker from '~/src/common/services/Tracker';
import { useRootCategories } from '~/src/queries/api-ecom/generated/api-ecom';

import { createSearchID, enrichSearchURL } from '../../../utils';

import * as S from './layout';

const SEARCH_TYPE = 'subcategory';

type Props = {
  query: string; // La recherche de l'utilisateur
  category: {
    id: string;
    name: string;
    slug: string;
    parentId: string;
  }; // La catégorie retourné par l'api
};

const SuggestionsCategory = ({ category, query }: Props) => {
  // Url de la page catégorie
  const displayURL = `/categorie/${category.slug}`;

  // Récupération de la catégorie racine des recettes
  const rootCategories = useRootCategories().data?.items;
  const rootRecipeCat = rootCategories?.find(c => c.tags?.includes('recette'));

  // Choix de l'icône en fonction de la catégorie parente
  const iconName = category.parentId === rootRecipeCat?.id ? 'cooking-hat' : 'shop-stand';

  // Amplitude: Interception du click et ajout des params d'analytics
  const router = useRouter();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    const searchID = createSearchID();
    const enrichedURL = enrichSearchURL(displayURL, { source: SEARCH_TYPE, searchID });
    void router.push(enrichedURL, displayURL);
    Tracker.sendEvent('search completed', {
      'search type': SEARCH_TYPE,
      'search ID': searchID,
      'user input': query,
      'keywords': category.name,
    });
  };

  return (
    <S.Entry href={displayURL} onClick={handleClick}>
      <S.Icon name={iconName} />
      <S.Text>
        <Highlight input={category.name} query={query} />
      </S.Text>
      <S.Icon name="Chevron-right" />
    </S.Entry>
  );
};

export default memo(SuggestionsCategory);
