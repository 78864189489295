export default {
  'title': 'Réinitialisation de votre mot de passe',
  'description': 'Veuillez entrer un nouveau mot de passe',
  'confirmation-message': 'Votre mot de passe a été réinitialisé avec succès',
  'expired-token': 'Le lien de réinitialisation de mot de passe a expiré',
  'invalid-email-address': "L'adresse email renseignée est introuvable",
  'password': {
    label: 'Nouveau mot de passe',
    placeholder: 'Mot de passe',
  },
  'password-confirmation': {
    label: 'Confirmation du mot de passe',
    placeholder: 'Confirmez votre mot de passe',
  },
  'error': {
    'missing-email-token': 'Impossible de réinitialiser votre mot de passe.',
  },
};
