import React from 'react';

import CKEditor from './CKEditor';
import Main from './Main';
import Normalize from './Normalize';

const GlobalStyles = () => (
  <>
    <Normalize />
    <Main />
    <CKEditor />
  </>
);

export default GlobalStyles;
