import React from 'react';

import { Props } from './DeliveryProgressBar';
import ProgressBarStep from './DeliveryProgressBarStep';
import { Container, ProgressBar, ProgressBarContainer, ProgressBarInnerSteps } from './layout';

const DeliveryProgressBarSteps = ({ deliveryPrices, totalCartAmount = 0 }: Props) => {
  if (!deliveryPrices || !deliveryPrices?.length) return null;

  // Les steps sans le premier et dernier élement
  const innerDeliveryPrices = [...deliveryPrices].slice(0, deliveryPrices.length - 1).slice(1);

  const totalCartAmountAsPercentage =
    deliveryPrices[deliveryPrices.length - 1].minCartNetPrice > totalCartAmount
      ? (totalCartAmount * 100) / deliveryPrices[deliveryPrices.length - 1].minCartNetPrice
      : 100;

  const lastMincartNetPrice = deliveryPrices[deliveryPrices.length - 1].minCartNetPrice;

  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBarStep
          isFirst
          reachedValue={totalCartAmount}
          step={deliveryPrices[0]}
          lastMincartNetPrice={lastMincartNetPrice}
        />
        <ProgressBarInnerSteps>
          {innerDeliveryPrices.map((step, index) => (
            <ProgressBarStep
              reachedValue={totalCartAmount}
              step={step}
              key={`${index + step.minCartNetPrice + step.shippingAmount}`}
              lastMincartNetPrice={lastMincartNetPrice}
            />
          ))}
        </ProgressBarInnerSteps>
        <ProgressBarStep
          isLast
          reachedValue={totalCartAmount}
          step={deliveryPrices[deliveryPrices.length - 1]}
          lastMincartNetPrice={lastMincartNetPrice}
        />
        <ProgressBar $reachedValueAsPercentage={totalCartAmountAsPercentage} />
      </ProgressBarContainer>
    </Container>
  );
};

export default DeliveryProgressBarSteps;
