import { useRef } from 'react';

import FooterLegal from '~/src/screens/App/Footer/FooterLegal';
import ReinsuranceBanner from '~/src/screens/App/Footer/ReinsuranceBanner';

import { AllLinksPart, Container } from './layout';
import AboutSection from './Sections/AboutSection';
import CategoriesSection from './Sections/CategoriesSection';
import ContactUsSection from './Sections/ContactUsSection';
import RatingsAndDownloadAppsSection from './Sections/RatingsAndDownloadAppsSection';

type Props = { hasOrderStatus?: boolean };

const Footer = ({ hasOrderStatus }: Props) => {
  const footerRef = useRef<HTMLElement | null>(null);

  return (
    <Container ref={footerRef}>
      <ReinsuranceBanner />
      <AllLinksPart>
        <CategoriesSection />
        <AboutSection />
        <ContactUsSection />
        <RatingsAndDownloadAppsSection />
      </AllLinksPart>
      <FooterLegal hasOrderStatus={hasOrderStatus} />
    </Container>
  );
};

export default Footer;
