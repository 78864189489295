import React from 'react';

const CKEditor = () => (
  <style jsx global>{`
    /* ckeditor5-image/theme/image.css */
    .ck-content .image {
      display: table;
      clear: both;
      text-align: center;
      margin: 0.9em auto;
      min-width: 50px;
    }
    /* ckeditor5-image/theme/image.css */
    .ck-content .image img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
    /* ckeditor5-image/theme/image.css */
    .ck-content .image-inline {
      /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
      display: inline-flex;
      max-width: 100%;
      align-items: flex-start;
    }
    /* ckeditor5-image/theme/image.css */
    .ck-content .image-inline picture {
      display: flex;
    }
    /* ckeditor5-image/theme/image.css */
    .ck-content .image-inline picture,
    .ck-content .image-inline img {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
    /* ckeditor5-image/theme/imageresize.css */
    .ck-content .image.image_resized {
      max-width: 100%;
      display: block;
      box-sizing: border-box;
    }
    /* ckeditor5-image/theme/imageresize.css */
    .ck-content .image.image_resized img {
      width: 100%;
    }
    /* ckeditor5-image/theme/imageresize.css */
    .ck-content .image.image_resized > figcaption {
      display: block;
    }
    /* ckeditor5-image/theme/imagecaption.css */
    .ck-content .image > figcaption {
      display: table-caption;
      caption-side: bottom;
      word-break: break-word;
      color: var(--ck-color-image-caption-text);
      background-color: var(--ck-color-image-caption-background);
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-block-align-left,
    .ck-content .image-style-block-align-right {
      max-width: calc(100% - var(--ck-image-style-spacing));
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-align-left,
    .ck-content .image-style-align-right {
      clear: none;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-side {
      float: right;
      margin-left: var(--ck-image-style-spacing);
      max-width: 50%;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-align-left {
      float: left;
      margin-right: var(--ck-image-style-spacing);
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-align-center {
      margin-left: auto;
      margin-right: auto;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-align-right {
      float: right;
      margin-left: var(--ck-image-style-spacing);
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-block-align-right {
      margin-right: 0;
      margin-left: auto;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-style-block-align-left {
      margin-left: 0;
      margin-right: auto;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content p + .image-style-align-left,
    .ck-content p + .image-style-align-right,
    .ck-content p + .image-style-side {
      margin-top: 0;
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-inline.image-style-align-left,
    .ck-content .image-inline.image-style-align-right {
      margin-top: var(--ck-inline-image-style-spacing);
      margin-bottom: var(--ck-inline-image-style-spacing);
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-inline.image-style-align-left {
      margin-right: var(--ck-inline-image-style-spacing);
    }
    /* ckeditor5-image/theme/imagestyle.css */
    .ck-content .image-inline.image-style-align-right {
      margin-left: var(--ck-inline-image-style-spacing);
    }
  `}</style>
);

export default CKEditor;
