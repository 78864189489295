import { createInputs } from '../../utils';

const allowedColors = ['PITAYA', 'PRIMARY', 'PRIMARY_HOVER', 'BLACK'];

export default createInputs([
  { name: 'code', type: 'string', required: true },
  { name: 'title', type: 'string' },
  { name: 'textColor', type: 'string', enum: allowedColors },
  { name: 'iconColor', type: 'string', enum: allowedColors },
]);
