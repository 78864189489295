import styled from 'styled-components';

import ButtonBase from './BaseButton';

const TransparentButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.palette.primary};
  background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
    background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  }
`;

export default TransparentButton;
