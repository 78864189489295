import React, { ReactNode, Suspense } from 'react';

import { isEditing, isPreviewing } from '@builder.io/sdk-react';
import { BuilderContextInterface } from '@builder.io/sdk-react/types/context/types';
import styled from 'styled-components';

import { logger } from '~/src/common/services/Logger';

const Box = styled.div`
  flex: 1;
  opacity: 0.75;
  display: flex;
  margin: 4px;
  padding: 32px;
  font-size: 48px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.CREAM};
  box-shadow: 0 0 4px ${({ theme }) => theme.palette.common.CREAM};
  border: 2px dashed ${({ theme }) => theme.palette.common.PRIMARY};
  border-radius: 8px;
`;

const ErrorBox = () => {
  if (isPreviewing()) return <Box>🚧</Box>;
  if (isEditing()) return <Box>🛠️</Box>;
  return null; // <Box>🤔</Box>;
};

type State = { error: Error | null };
type Props = React.PropsWithChildren<{ fallback: ReactNode }>;
class BuilderFence extends React.Component<Props, State> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(originalError: Error, react: React.ErrorInfo) {
    // En mode édition, on retente d'afficher le composant après 1s
    if (isEditing()) {
      setTimeout(() => this.setState({ error: null }), 1000);
    }
    // En mode live, on remonte l'erreur dans sentry
    if (!isEditing() && !isPreviewing()) {
      Object.assign(originalError, { stack: react.componentStack });
      logger.error('Error caught in BuilderFence', { originalError, react });
    }
  }

  render() {
    const { error } = this.state;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { children, fallback } = this.props;
    return error == null ? children : fallback;
  }
}

/**
 * HOC permettant de gérer les erreurs de composants Builder.
 *
 * Impossible à abstraire dans un hook car la gestion des erreurs
 * doit systématiquement passer par un composant qui va l'intercepter.
 *
 * Une `<ErrorBoundary/>` est utilisée pour remonter les erreurs dans
 * Sentry, mais ce fonctionnement n'est pas supporté côté serveur.
 * Pour le SSR, il faut donc passer par une balise `<Suspense/>`.
 *
 * C'est le seul moyen de gérer les erreurs à la fois côté client et côté serveur.
 * CF: https://github.com/facebook/react/issues/23184#issuecomment-1082537928
 */
const withBuilderFence = <
  T extends JSX.IntrinsicAttributes & {
    builderContext?: BuilderContextInterface;
  },
>(
  Component: React.ComponentType<T>,
  customName?: string,
) => {
  const Fenced = (props: T) => (
    <BuilderFence fallback={<ErrorBox />}>
      <Suspense fallback={<ErrorBox />}>
        <Component {...props} />
      </Suspense>
    </BuilderFence>
  );
  const componentName = customName || Component.displayName || Component.name;
  return Object.assign(Fenced, { displayName: `BuilderFence(${componentName})` });
};

export { withBuilderFence };
