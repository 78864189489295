import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderID = 'button:cta';
const Component = dynamic(() => import('./Component'));
export default createComponent(BuilderID, Component, inputs, {
  // A remplacer par notre propre image si nous le souhaitons
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  friendlyName: 'Bouton personnalisé',
});
