import { createInputs } from '../../utils';

const variants = [
  { value: 'primary', label: 'primaire' },
  { value: 'secondary', label: 'secondaire' },
  { value: 'tertiary', label: 'tertiaire' },
];

export default createInputs([
  { name: 'text', type: 'string', required: true },
  { name: 'link', type: 'string', required: true },
  { name: 'openLinkInNewTab', type: 'boolean', required: false },
  {
    name: 'variant',
    type: 'string',
    enum: variants,
    required: true,
    friendlyName: 'Type (primaire, secondaire ou tertiaire)',
  },
]);
