export default {
  'title': 'Mes commandes',
  'subtitle-in-progress': 'Commandes en cours',
  'subtitle-past': 'Commandes passées',
  'order-status': {
    'pickup-ready': 'Commande prête pour récupération',
    'accepted-by-client': 'Acceptée',
    'preparing': 'En préparation',
    'delivering': 'En livraison',
    'cancelled': 'Annulée',
    'terminated': 'Terminée',
    'refunded': 'Remboursée',
    'ready': 'Commande disponible',
  },
  'in-progress-order': {
    'title': 'Commandes en cours',
    'date-hours': 'Entre %{from} et %{to}',
    'nb-articles': '%{count} article - %{price}',
    'nb-articles_plural': '%{count} articles - %{price}',
    'update-order': 'Modifier ma commande',
    'order-again': 'Commander à nouveau',
  },
  'past-order': {
    'pickup': 'Retrait',
    'delivery': 'Livraison',
    'nb-products': '%{count} produit',
    'nb-products_plural': '%{count} produits',
    'order-again': 'Commander à nouveau',
    'no-order': 'Aucune commande passée',
    'recent-month-interval': 'Le dernier mois',
    'recent-month-interval_plural': 'Les %{count} derniers mois',
  },
};
