import styled from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { CATALOG_SIDE_PADDING_V2 } from '~/src/screens/App/CatalogLayout/layout';
import { breakpoints, NAV_HEIGHT } from '~/src/styles-constants';

import { LIST_VPADDING } from '../constants';

/**
 * La seule fonction du container est d'afficher un overlay lorsque
 * menu est ouvert. L'ouverture du menu est détectée via la présence
 * d'un élément plus bas dans le dom ayant l'attribut `aria-selected`
 */
export const Container = styled.div`
  &::before {
    content: '';
    display: block;
    position: fixed;
    inset: ${NAV_HEIGHT}px 0 0 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    background: ${({ theme }) => theme.palette.common.BLACK_60};
  }

  /* Desktop : Overlay visible si menu ouvert */
  @media ${getMediaQuery(breakpoints.XL, null)} {
    &:has([aria-selected='true'])::before {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

/** La liste des entrées du menu */
export const Content = styled.div`
  position: relative;
  display: flex;
  gap: 16px;

  padding: ${LIST_VPADDING}px ${CATALOG_SIDE_PADDING_V2}px;
  background: ${({ theme }) => theme.palette.common.WHITE};

  /* Mobile: Affichage vertical */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    flex-direction: column;
    padding: 0;
    gap: 8px;
  }
`;
