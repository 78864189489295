import styled, { css } from 'styled-components';

export const H1styles = css`
  ${({ theme }) => `
    color: ${theme.typography.h1.color};
    text-transform: ${theme.typography.h1.textTransform};
    font-size: ${theme.typography.h1.fontSize};
    font-weight: ${theme.typography.h1.fontWeight};
    font-family: ${theme.typography.h1.fontFamily};
    line-height: ${theme.typography.h1.lineHeight};
  `};
`;

export const H2styles = css`
  ${({ theme }) => `
    color: ${theme.typography.h2.color};
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.h2.fontWeight};
    font-family: ${theme.typography.h2.fontFamily};
    line-height: ${theme.typography.h2.lineHeight};
  `};
`;

export const H3styles = css`
  ${({ theme }) => `
    color: ${theme.typography.h3.color};
    font-size: ${theme.typography.h3.fontSize};
    font-weight: ${theme.typography.h3.fontWeight};
    font-family: ${theme.typography.h3.fontFamily};
    line-spacing: ${theme.typography.h3.letterSpacing};
    line-height: ${theme.typography.h3.lineHeight};`};
`;

export const H4styles = css`
  ${({ theme }) => `
    color: ${theme.typography.h4.color};
    font-size: ${theme.typography.h4.fontSize};
    font-weight: ${theme.typography.h4.fontWeight};
    font-family: ${theme.typography.h4.fontFamily};
    line-height: ${theme.typography.h4.lineHeight};
  `};
`;

export const Astyles = css`
  ${({ theme }) => `
    color: ${theme.typography.a.color};
    font-size: ${theme.typography.a.fontSize};
    font-weight: ${theme.typography.a.fontWeight};
    font-family: ${theme.typography.a.fontFamily};
    cursor: pointer;

    &:hover {
      color: ${theme.typography.a.colorHover};
    }
  `};
`;

export const TextStyles = css`
  ${({ theme }) => `
    color: ${theme.typography.p.color};
    font-size: ${theme.typography.p.fontSize};
    font-weight: ${theme.typography.p.fontWeight};
    font-family: ${theme.typography.p.fontFamily};
    line-height: ${theme.typography.p.lineHeight};
`}
`;

export const H1 = styled.h1`
  ${H1styles}
`;

export const H2 = styled.h2`
  ${H2styles}
`;

export const H3 = styled.h3`
  ${H3styles}
`;

export const P = styled.p`
  ${TextStyles}
`;
