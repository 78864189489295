import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useAnalyticsInitializer } from '~/src/common/hooks/use-analytics';
import { useAuthenticated } from '~/src/common/hooks/user/use-authenticated';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import { isTruthy } from '~/src/common/utils/guards';
import {
  useCurrentOrders,
  useHome,
  useLastOrder,
  useRootCategories,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { useGetHeadersQuery } from '~/src/queries/cms/generated/cms';
import { usePostAuthMeQuery } from '~/src/screens/App/queries/hooks';
import {
  hasReferralModalOpened,
  persitReferralModalOpened,
} from '~/src/screens/ReferralModal/utils';

import { CATALOG_QUERY_PARAMS } from '../Home/queries';

const useReferralModal = () => {
  const { pathname } = useRouter();
  const isAuthenticated = useAuthenticated();

  const { data: lastOrderData } = useLastOrder({
    query: { enabled: isAuthenticated && !pathname.startsWith('/account/'), staleTime: Infinity },
  });

  const { data: currentOrdersData } = useCurrentOrders({
    query: { enabled: isAuthenticated && !pathname.startsWith('/account/'), staleTime: Infinity },
  });

  const lastOrder = lastOrderData?.ongoing ?? lastOrderData?.past;
  const ordersCount = currentOrdersData?.count || 0;

  const isReferalModalChecked = useRef(false);
  const lastOrderState = lastOrder?.state;

  const { show } = useNiceModal('referral-modal');

  useEffect(() => {
    if (isAuthenticated && ordersCount > 0 && !isReferalModalChecked.current) {
      if (ordersCount === 1 && lastOrderState === 'paid') {
        isReferalModalChecked.current = true;
        const hasReferralModalAlreadyOpened = hasReferralModalOpened();

        if (!hasReferralModalAlreadyOpened) {
          persitReferralModalOpened(true);

          // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
          show();
        }
      }
    }
  }, [isAuthenticated, lastOrderState, show, ordersCount]);
};

export const useAppInit = () => {
  const { pathname } = useRouter();

  const { data: rootCategories } = useRootCategories({
    query: { staleTime: Infinity },
  });

  const { data: homeData } = useHome(CATALOG_QUERY_PARAMS, {
    query: { enabled: pathname === '/' },
  });

  const { data: homeHeaders } = useGetHeadersQuery(
    { headers_list: homeData?.headersIds },
    {
      enabled: pathname === '/' && (homeData?.headersIds?.length || 0) > 0,
      staleTime: Infinity,
    },
  );

  const sortedHeaders = (homeData?.headersIds || [])
    .map(id => homeHeaders?.headers?.find(p => p?.keplr_id === id))
    .filter(isTruthy);

  const { data: user } = usePostAuthMeQuery();

  useAnalyticsInitializer();

  useReferralModal();

  return {
    user,
    rootCategories: rootCategories?.items || [],
    homeHeader: sortedHeaders?.[0],
    homeHeaders: sortedHeaders || [],
  };
};
