export default {
  'credit-card': 'Ajouter une carte bancaire',
  'paypal': 'Paypal',
  'paypal-disclaimer': 'Indisponible pour un créneau au delà de %{days} jours',
  'add-form': {
    'submit': 'Ajouter ma carte',
    'unknown-error':
      "Une erreur est survenue lors de l'ajout de carte. Veuillez sélectionner votre moyen de paiement.",
    'save-card': 'Enregistrer ma carte',
    'validated-card': 'Ajouter une carte valide',
  },
  'error': {
    'no-selected-payment': 'Veuillez sélectionner votre moyen de paiement',
    'paypal-message':
      'Une erreur est survenue lors de la tentative de transaction via Paypal.<p>Veuillez choisir un autre moyen de paiement</p>',
    'paypal-cgv': 'Veuillez accepter les CGV sur la page de paiement puis réessayer.',
    'paypal-price-zero':
      'Paypal ne peut pas être utilisé pour une commande d’un montant de 0 €.<p>Veuillez choisir un autre moyen de paiement</p>',
  },
};
