import { useState } from 'react';

import I18n from '~/src/common/services/I18n';
import { getCategoryLink } from '~/src/common/utils/categories';
import { useNavigation } from '~/src/queries/api-ecom/generated/api-ecom';
import { sendFooterNavigationEvent } from '~/src/screens/App/Footer/utils';

import {
  LinksInnerSection,
  LinksSection,
  SectionTitle,
  StyledLink,
  ToggleSectionButton,
} from '../layout';

const CategoriesSection = () => {
  const [isSectionOpened, setSectionOpened] = useState(false);
  const toggleSection = () => setSectionOpened(!isSectionOpened);

  const { families, recipe } = useNavigation().data ?? {};
  const links: { id: string; name: string; href: string }[] = [
    // Lien vers la home en dur
    { id: '-', name: I18n.t('footer.home-footer-text'), href: '/' },
    // Liste des catégories
    ...(families ?? [])
      .flatMap(fam => fam.categories)
      .map(cat => ({ id: cat.id, name: cat.name, href: getCategoryLink(cat) })),
    // La catégorie recette
    ...(recipe ? [{ id: recipe.id, name: recipe.name, href: getCategoryLink(recipe) }] : []),
  ];

  return (
    <LinksSection>
      <SectionTitle onClick={toggleSection}>
        {I18n.t('footer.categories')}
        <ToggleSectionButton
          $isOpened={isSectionOpened}
          variant="transparent"
          icon="Chevron-down"
        />
      </SectionTitle>
      <LinksInnerSection $isOpened={isSectionOpened}>
        {links.map(({ id, href, name }) => (
          <StyledLink key={id} href={href} onClick={() => sendFooterNavigationEvent(name)}>
            {name}
          </StyledLink>
        ))}
      </LinksInnerSection>
    </LinksSection>
  );
};

export default CategoriesSection;
