import { useQueryClient } from '@tanstack/react-query';

import { User } from '~/src/queries/types/user';
import { QUERY_KEYS } from '~/src/screens/App/queries/queries';

/**
 * It returns true if the user is connected
 */
export const useAuthenticated = () => {
  const queryClient = useQueryClient();

  return Boolean(queryClient.getQueryData<User>(QUERY_KEYS.user)?.id);
};
