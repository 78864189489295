import styled from 'styled-components';

import { transparentize } from '~/src/common/utils/style';

import ButtonBase from './BaseButton';

const TransparentButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.palette.primary};
  background: ${({ theme }) => theme.palette.common.WHITE};
  border-color: ${({ theme }) => theme.palette.common.WHITE};
  margin: 10px;
  box-shadow: ${({ theme }) => transparentize(theme.palette.common.BLACK, 0.25)} 0 0 4px 0;
  padding: 0;

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.common.WHITE};
    background: ${({ theme }) => theme.palette.common.WHITE};
    box-shadow: ${({ theme }) => transparentize(theme.palette.common.BLACK, 0.25)} 0 0 4px 0;
  }
`;

export default TransparentButton;
