export default {
  'title': 'Produits similaires',
  'banner':
    'Quand l’un de nos produits est indisponible nous vous proposons des produits similaires !',
  'unavailable-product-title': 'Produit indisponible',
  'substitute-product-title': 'Produits similaires',
  'show-more-products': 'Afficher plus de produits',
  'empty-list':
    'Malheureusement les produits de substitution pour ce produit ne sont pas disponibles, mais ils seront bientôt de retour à la vente',
};
