import styled from 'styled-components';

import ButtonBase from './BaseButton';

const LinkButton = styled(ButtonBase)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary};
  background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};

  margin: 0;
  padding: 0;
  margin-left: -25px;
  margin-right: -25px;

  span {
    text-decoration: underline;
  }

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
    background: ${({ theme }) => theme.palette.common.TRANSPARENT};
  }
`;

export default LinkButton;
