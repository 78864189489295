import type { RegisteredComponent } from '@builder.io/sdk-react';

import blockCategory from './block/category';
import blockCommitment from './block/commitment';
import linksBox from './block/linksBox';
import blockPromoExplanation from './block/promoExplanation';
import blockReinsurance from './block/reinsurance';
import blockTestimonial from './block/testimonial';
import blockTuto from './block/tuto';
import customButton from './button/cta';
import buttonPromocode from './button/promocode';
import fieldAddress from './field/address';
import fancyTitle from './other/fancyTitle';
import otherFooter from './other/footer';
import otherHeader from './other/header';
import otherSeparator from './other/separator';
import sectionAddress from './section/address';
import sectionCategories from './section/categories';
import sectionCommitment from './section/commitment';
import sectionDownload from './section/download';
import sectionMain from './section/main';
import sectionPromoExplanations from './section/promoExplanationItems';
import sectionPromoDetails from './section/promoExplanations';
import sectionReinsurance from './section/reinsurance';
import sectionTestimonial from './section/testimonial';
import sectionTuto from './section/tuto';

export default [
  customButton,
  buttonPromocode,
  blockCommitment,
  blockReinsurance,
  blockTestimonial,
  blockTuto,
  blockCategory,
  blockPromoExplanation,
  fieldAddress,
  linksBox,
  otherHeader,
  otherSeparator,
  sectionAddress,
  sectionCommitment,
  sectionDownload,
  sectionMain,
  sectionReinsurance,
  sectionTestimonial,
  sectionTuto,
  sectionPromoDetails,
  sectionPromoExplanations,
  sectionCategories,
  otherFooter,
  fancyTitle,
] satisfies RegisteredComponent[];
