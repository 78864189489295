import React, { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { ThemeProvider } from 'styled-components';

import { useKeyboardKey } from '~/src/common/hooks/use-keyboard-key';
import { useMutationObserver } from '~/src/common/hooks/use-mutation-observer';
import I18n from '~/src/common/services/I18n';
import { useModalState } from '~/src/common/services/ModalsManager';
import { mainTheme } from '~/src/common/themes/main';
import type colors from '~/src/common/themes/main/colors';
import { getOs } from '~/src/common/utils/navigator';
import { isServerSide } from '~/src/common/utils/server';
import { SMART_BANNER_ID } from '~/src/screens/App/CatalogLayout/utils';

import { useBackModalState, ModalBackContext } from './context';
import { Container, InnerContainer, Overlay, ModalSizes, CloseButton, BackButton } from './layout';

export interface ModalProps {
  onClose: () => void;
  onBack?: () => void;
  children: React.ReactNode;
  size?: ModalSizes;
  isPortalEnabled?: boolean;
  hideCloseButton?: boolean;
  closeIconColor?: keyof typeof colors;
  variant?: 'basic' | 'white' | 'off-white' | 'error' | 'primary' | 'primary_hover';
  className?: string;
  variantClose?: 'transparent' | 'shadow';
  skipAnimation?: boolean;
  id?: string;
  onOverlayClick?: () => void;
}

const Modal = ({
  onClose,
  onBack,
  size = 'medium',
  isPortalEnabled = true,
  variant = 'basic',
  variantClose = 'transparent',
  children,
  closeIconColor,
  hideCloseButton,
  skipAnimation,
  className,
  id,
  onOverlayClick,
}: ModalProps) => {
  const backState = useBackModalState(onBack);
  const [handleBack] = backState;
  const [visualViewportHeight, setVisualViewportHeight] = useState<number | undefined>(undefined);
  const modalState = useModalState();

  useKeyboardKey('Escape', onClose);

  // Permet de masquer la smart banner appsflyer (bannière qui permet d'ouvrir l'app)
  useMutationObserver({
    selector: `#${SMART_BANNER_ID}`,
    callback: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
      window.AF('banners', 'hideBanner');
    },
    options: { childList: true, subtree: true },
  });

  const handleResizeWindow = (e: Event) => {
    setVisualViewportHeight((e?.target as VisualViewport)?.height);
  };

  const isAccountManagmentModalOpen = modalState['account-management-modal'];

  useEffect(() => {
    const isIosMobile = getOs() === 'iOS';

    // trick to allow the scroll on account managment modal for IOS
    if (isIosMobile && isAccountManagmentModalOpen) {
      window.visualViewport?.addEventListener('resize', handleResizeWindow);

      return () => {
        window.visualViewport?.removeEventListener('resize', handleResizeWindow);
      };
    }

    return undefined;
  }, [isAccountManagmentModalOpen]);

  const modal = (
    <ThemeProvider theme={mainTheme}>
      <ModalBackContext.Provider value={backState}>
        <RemoveScroll forwardProps>
          <Container className={className} size={size}>
            <Overlay onClick={onOverlayClick ?? onClose} data-testid="modal-overlay" />
            <InnerContainer
              role="dialog"
              variant={variant}
              size={size}
              data-testid="modal-content"
              id={id}
              visualViewportHeight={visualViewportHeight}
              skipAnimation={skipAnimation}>
              {handleBack != null ? (
                <BackButton icon="Arrow-back" variant="link" onClick={handleBack}>
                  {I18n.t('common.back')}
                </BackButton>
              ) : null}
              {!hideCloseButton ? (
                <CloseButton
                  variant={variantClose}
                  icon="cross"
                  color={closeIconColor}
                  onClick={onClose}
                  fixed={size === 'full'}
                  iconSize={24}
                  aria-controls={id}
                  label={I18n.t('common.close')}
                />
              ) : null}
              {children}
            </InnerContainer>
          </Container>
        </RemoveScroll>
      </ModalBackContext.Provider>
    </ThemeProvider>
  );

  if (!isPortalEnabled || isServerSide()) {
    return modal;
  }

  return createPortal(modal, document.body);
};

export default Modal;
