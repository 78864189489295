import { TREEVIEW_MARGIN, TREEVIEW_WIDTH } from '~/src/styles-constants';

import { TabName, Type } from './types';

/** La largeur du Treeview, marges incluses */
export const TREEVIEW_SPACE = TREEVIEW_WIDTH + TREEVIEW_MARGIN;

/** Identifiant unique pour l'input de la recherche */
export const SEARCHBAR_ID = 'search-bar-input';

/** La durée des animations pour la recherche */
export const TRANSITION_DURATION = 300;

/** Le délai avant de sortir les résultats du DOM */
export const HIDE_OUTPUT_DELAY = TRANSITION_DURATION + 200;

/** Nombre minimum de caractères pour activer la recherche */
export const MIN_SEARCH_QUERY_LENGTH = 3;

/** Nombre de produits en dessous duquel on affiche le formulaire */
export const MAX_PRODUCTS_FOR_PROPOSAL = 3;

/** Nombre de produits en dessous duquel on affiche les produits additionnels */
export const MAX_PRODUCTS_FOR_ADDITIONAL_PRODUCTS = 3;

/** Délai pour le debounce de la recherche */
export const DEBOUNCE_QUERY_DELAY = 300;

/** Le nom des parametres d'url liés à la recherche */
export const SEARCH_QS = {
  TERM: 'term', // Le terme recherché
  SOURCE: 'source', // Le mode de recherche (pour les analytics)
  SEARCHID: 'searchID', // L'identifiant de recherche (pour les analytics)
} as const;

/** Mapping entre les types back, et les données attendues par amplitude */
export const BACKEND_TO_AMPLITUDE: Record<Type, TabName> = {
  PRODUCT: 'products',
  RECIPE: 'recipes',
} as const;
