export default {
  title: 'Mes paramètres',
  informations: {
    title: 'Mes informations',
    text: 'Modifier mes informations personnelles (Nom, prénom, numéro de téléphone...)',
  },
  password: {
    title: 'Modifier mon mot de passe',
    text: 'Modifier votre mot de passe en toute sécurité.',
  },
  addresses: {
    title: 'Mes adresses de livraison',
    text: 'Modifier vos adresses de livraison enregistrées et les informations et préférences de celles-ci.',
  },
  payment: {
    title: 'Mes moyens de paiement',
    text: 'Ajouter, modifier et supprimer vos cartes bancaires de manière sécurisée.',
  },
  communications: {
    title: 'Communications',
    text: 'Gérer vos préférences de communication par mail ou SMS.',
  },
};
