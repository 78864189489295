import { createContext, PropsWithChildren, useContext, useRef } from 'react';

import { logger } from '~/src/common/services/Logger';
import Tracker from '~/src/common/services/Tracker';

import { Category, Family, Navigation, SubCategory } from './types';
import { getCategoryPath, getFamilyPath, getSubCategoryPath } from './utils';

/** Les messages d'erreurs possibles */
const ERRORS = {
  MISSING_CONTEXT: '[Nav] useNavigationContext() must be wrapped in a NavigationContext.Provider',
  UNKNOWN_FAMILY: '[Nav] Family: Unable to resolve the provided ID',
  UNKNOWN_CATEGORY: '[Nav] Category: Unable to resolve the provided ID',
  UNKNOWN_SUBCATEGORY: '[Nav] SubCategory: Unable to resolve the provided ID',
};

/** Événements suivis sur Amplitude */
type NavigationTracking = {
  large: {
    onClickFamilyCard: (id: string, action: 'open' | 'close') => void;
    onClickFamilyImage: (id: string) => void;
    onClickFamilyBanner: (id: string) => void;
    onClickCategoryName: (id: string) => void;
    onClickCategorySeeAll: (id: string) => void;
    onClickSubCategoryName: (id: string) => void;
  };
  small: {
    onClickCategoryName: (id: string) => void;
  };
};

// Retourne les infos de base pour famille/category/subcategory pour Amplitude
const getFamilyInfos = (data: { item: Family; index: number }) => ({
  'family id': data.item.id,
  'family name': data.item.name,
  'family position': data.index + 1,
  'number of categories': data.item.categories.length,
});
const getCategoryInfos = (data: { item: Category; index: number }) => ({
  'category id': data.item.id,
  'category name': data.item.name,
  'category position': data.index + 1,
  'number of subcategories': data.item.children.length,
});
const getSubCategoryInfos = (data: { item: SubCategory; index: number }) => ({
  'subcategory id': data.item.id,
  'subcategory name': data.item.name,
  'subcategory position': data.index + 1,
});

/** Contexte contenant les événements */
const NavigationTrackingContext = createContext<NavigationTracking | null>(null);

/** Sources autorisées pour la position de la navigation */
export type NavigationSource = 'Burger menu' | 'Homepage';

/** Consumer pour les événements, avec une sécurité en cas de contexte non disponible */
export const useNavigationTracking = () => {
  const ctx = useContext(NavigationTrackingContext);
  if (!ctx) throw new Error(ERRORS.MISSING_CONTEXT);
  return ctx;
};

/** Provider pour le contexte de Tracking */
type Props = PropsWithChildren<{
  navigation: Navigation | undefined;
  source?: NavigationSource;
}>;
export const NavigationTrackingProvider = ({ children, navigation, source }: Props) => {
  // Pour optimiser les performances et réduire les render inutiles, la valeur
  // du contexte est stabilisée via une ref, et mise à jour à chaque render
  const ref = useRef<Partial<NavigationTracking>>({});
  const value = Object.assign<Partial<NavigationTracking>, NavigationTracking>(ref.current, {
    large: {
      onClickFamilyCard: (id, action) => {
        if (action === 'close') return;
        const path = navigation ? getFamilyPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_FAMILY, { id });
        Tracker.sendEvent('user navigation menu family', {
          ...getFamilyInfos(path.family),
          'current page name': document.title,
          'current page path': document.location.pathname,
        });
      },
      onClickFamilyImage: id => {
        const path = navigation ? getFamilyPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_FAMILY, { id });
        Tracker.sendEvent('click mega menu family picture', {
          ...getFamilyInfos(path.family),
          picture: path.family.item.images.aside ?? '',
        });
      },
      onClickFamilyBanner: id => {
        const path = navigation ? getFamilyPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_FAMILY, { id });
        Tracker.sendEvent('click mega menu reinsurance footer', {
          ...getFamilyInfos(path.family),
          'reinsurance title': path.family.item.banner?.title ?? '',
        });
      },
      onClickCategoryName: id => {
        const path = navigation ? getCategoryPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_CATEGORY, { id });
        Tracker.sendEvent('user navigation menu category', {
          ...getFamilyInfos(path.family),
          ...getCategoryInfos(path.category),
          source: 'Mega menu title',
        });
      },
      onClickCategorySeeAll: id => {
        const path = navigation ? getCategoryPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_CATEGORY, { id });
        Tracker.sendEvent('user navigation menu category', {
          ...getFamilyInfos(path.family),
          ...getCategoryInfos(path.category),
          source: 'Mega menu see all',
        });
      },
      onClickSubCategoryName: id => {
        const path = navigation ? getSubCategoryPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_SUBCATEGORY, { id });
        Tracker.sendEvent('user navigation menu subcategory', {
          ...getFamilyInfos(path.family),
          ...getCategoryInfos(path.category),
          ...getSubCategoryInfos(path.subcategory),
          source: 'mega menu',
        });
      },
    },
    small: {
      onClickCategoryName: id => {
        const path = navigation ? getCategoryPath(navigation, id) : null;
        if (!path) return logger.error(ERRORS.UNKNOWN_CATEGORY, { id });
        Tracker.sendEvent('user navigation menu category', {
          ...getFamilyInfos(path.family),
          ...getCategoryInfos(path.category),
          source,
        });
      },
    },
  });

  return (
    <NavigationTrackingContext.Provider value={value}>
      {children}
    </NavigationTrackingContext.Provider>
  );
};
