import { useEffect, useMemo, useRef } from 'react';

/**
 * Ce hook va prendre en entrée une fonction, et retourner une nouvelle fonction
 * ayant une signature et un fonctionnement identique. La seule différence est
 * que la fonction retournée aura une référence stable, et ne provoquera pas
 * de re-render lorsqu'elle sera utilisé comme prop ou dépendance de hook.
 *
 * Ce hook a pour vocation d'être utilisé quand on a peu de controle sur la
 * stabilité d'une fonction (par exemple un callback dans un hook custom)
 *
 * Le fonctionnel est très largement inspiré de `useEvent` :
 *   https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Utiliser `unknown` bloque l'inférence
export const useStable = <T extends (..._: any[]) => any>(callback: T): T => {
  const ref = useRef<T>(callback);
  useEffect(() => void Object.assign(ref, { current: callback }), [callback]);
  // ReturnType<T> sera any donc on a pas trop le choix sur ce type de fonction générique
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return useMemo(() => ((...args: Parameters<T>) => ref.current(...args)) as T, []);
};
