import { isObject } from '../../utils/guards';
import { pick } from '../../utils/object';

import { Params } from './types';

// Import dynamique afin de faciliter le tree-shaking
const loader =
  typeof window === 'undefined'
    ? () => import('./server/fetch').then(m => m.serverFetch)
    : () => import('./browser/fetch').then(m => m.browserFetch);

// Wrapper autour de la base pour inclure la requête dans les erreurs
export const fetch = async <T>(input: Params): Promise<T> => {
  const isoFetch = await loader();
  return isoFetch<T>(input).catch(err => {
    const req = pick(input, ['method', 'url', 'params', 'data']);
    const extra = isObject(err) && isObject(err.extra) && err.extra;
    throw Object.assign(err, { extra: { req, ...extra } });
  });
};
