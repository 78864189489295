import styled from 'styled-components';

import ButtonBase from './BaseButton';

const QuaternaryButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.palette.common.BLACK};
  background: ${({ theme }) => theme.palette.common.WHITE};
  border-color: ${({ theme }) => theme.palette.common.BLACK};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.common.WHITE};
    background: ${({ theme }) => theme.palette.common.ACTION_DARKER};
    border-color: ${({ theme }) => theme.palette.common.ACTION_DARKER};
  }
`;

export default QuaternaryButton;
