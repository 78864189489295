import Icon from '~/src/common/components/Icon';
import I18n from '~/src/common/services/I18n';
import { logger } from '~/src/common/services/Logger';
import Tracker from '~/src/common/services/Tracker';
import { GetDetailBySlug200BreadcrumbsItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import { BreadcrumbLink } from './layout';

interface Props {
  categories: Partial<GetDetailBySlug200BreadcrumbsItem>[];
  contentName: string;
}

const BreadcrumbItem = ({ children }: { children: React.ReactNode }) => (
  <>
    <span>{children}</span>
    <Icon name="Chevron-right" />
  </>
);

const CatalogBreadcrumbs = ({ categories, contentName }: Props) => {
  const handleClickItem = (level: number) => {
    if (level === 0) Tracker.sendEvent('click breadcrumb level 0');
    else if (level === 1) Tracker.sendEvent('click breadcrumb level 1');
    else if (level === 2) Tracker.sendEvent('click breadcrumb level 2');
    else logger.warning(`Breadcrumb level ${level} not tracked`);
  };

  return (
    <>
      <BreadcrumbLink
        href="/"
        onClick={() => {
          handleClickItem(0);
        }}>
        <BreadcrumbItem>{I18n.t('common.home')}</BreadcrumbItem>
      </BreadcrumbLink>
      {categories?.map(({ enabled, slug, name }, i) => {
        if (!name || (enabled && !slug)) return null;

        return enabled && slug ? (
          <BreadcrumbLink
            href={`/categorie/${slug}`}
            key={slug}
            onClick={() => {
              handleClickItem(i + 1);
            }}>
            <BreadcrumbItem>{name}</BreadcrumbItem>
          </BreadcrumbLink>
        ) : (
          <BreadcrumbItem key={name}>{name}</BreadcrumbItem>
        );
      })}
      <span>{contentName}</span>
    </>
  );
};

export default CatalogBreadcrumbs;
