import styled, { css, keyframes } from 'styled-components';

import type colors from '~/src/common/themes/main/colors';
import { media, zIndex } from '~/src/styles-constants';

import Button from '../Button';

export const OPENING_ANIMATION_DURATION = 250; // ms

const TOP_POSITION_MODAL = 110;

const MODAL_SIZES = {
  small: '500px',
  medium: '800px',
  large: '1100px',
  full: '100%',
};

const isFullSize = (size: ModalSizes) => size === 'full';

export type ModalSizes = keyof typeof MODAL_SIZES;

const openAnimation = keyframes`
  0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;

  z-index: ${zIndex.modalOverlay};
  background: ${({ theme }) => theme.palette.common.BLACK_60};
`;

export const Container = styled.div<{
  size: ModalSizes;
}>`
  position: fixed;
  z-index: ${zIndex.modalContainer};
  inset: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: ${({ size }) => (isFullSize(size) ? 0 : '0 5px')};

  ${media.S<{ size: ModalSizes }>`
    padding: ${({ size }) => (isFullSize(size) ? 0 : '0 15px')};
  `};
`;

export const InnerContainer = styled.div<{
  size: ModalSizes;
  variant: string;
  skipAnimation?: boolean;
  visualViewportHeight?: number;
}>`
  position: relative;
  top: ${({ size }) => (isFullSize(size) ? 0 : '5%')};

  margin-bottom: ${({ size }) =>
    isFullSize(size) ? 0 : 'calc(env(safe-area-inset-bottom, 0) + 50px)'};

  max-width: ${({ size }) => MODAL_SIZES[size]};
  width: 100%;
  height: ${({ size }) => (isFullSize(size) ? '100%' : null)};
  z-index: ${zIndex.modalInnerContainer};
  background: ${({ theme, variant }) => {
    if (variant === 'white') return theme.palette.common.WHITE;

    if (variant === 'off-white') return theme.palette.common.BACKGROUND_1;

    if (variant === 'primary') return theme.palette.common.PRIMARY;

    if (variant === 'primary_hover') return theme.palette.common.PRIMARY_HOVER;

    return theme.palette.background;
  }};
  border-radius: ${({ theme, size }) => (isFullSize(size) ? 0 : theme.layout.borderRadius)};
  border-top: none;
  border-top-color: ${({ theme, variant }) =>
    variant === 'error' ? theme.palette.common.ERRORS : theme.palette.common.PRIMARY};

  animation-name: ${({ skipAnimation }) => (skipAnimation ? 'none' : openAnimation)};
  animation-duration: ${OPENING_ANIMATION_DURATION}ms;
  animation-timing-function: ease-in-out;

  ${media.XL<{ size: ModalSizes }>`
    top: ${({ size }) => (isFullSize(size) ? 0 : `${TOP_POSITION_MODAL}px`)};
  `};

  ${({ visualViewportHeight }) =>
    // trick to allow the scroll on account managment modal for IOS
    visualViewportHeight
      ? css`
          margin-bottom: ${visualViewportHeight / 2}px;

          ${Container} {
            height: 100%;
          }
        `
      : ''};
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

export const CloseButton = styled(Button)<{ color?: keyof typeof colors; fixed: boolean }>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  right: ${({ fixed }) => (fixed ? '5px' : 0)};
  top: ${({ fixed }) => (fixed ? '5px' : 0)};
  z-index: 1;
  padding: 0 1px 3px 0;
  color: ${({ color, theme }) =>
    color != null ? theme.palette.common[color] : theme.palette.common.PRIMARY};
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 5px;

  ${media.M`
    padding: 30px;
  `}
`;
