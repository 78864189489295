import { useEffect, useRef, useState } from 'react';

import Tracker from '~/src/common/services/Tracker';
import { isServerSide } from '~/src/common/utils/server';

const useSearchAction = () => {
  // Utilisé pour préserver l'animation d'ouverture/fermeture de la recherche et le lazyload du composant
  // Si le composant a été ouvert une fois, on ne le détruit pas, le isOpen prend le relai
  const hasSearchOpen = useRef(false);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const positions = useRef<{ left: number; bottom: number; right: number; top: number }>({
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  });
  const [isSearchOpen, setSearchOpened] = useState(false);

  useEffect(() => {
    if (isSearchOpen) {
      // Permet de masquer la smart banner appsflyer (bannière qui permet d'ouvrir l'app)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
      window.AF('banners', 'hideBanner');
    }
  }, [isSearchOpen]);

  if (isSearchOpen) {
    hasSearchOpen.current = true;
  }

  const toggleSearch = () => {
    if (!isServerSide() && searchContainerRef?.current) {
      const bounding = searchContainerRef.current.getBoundingClientRect();
      const center = bounding.left + bounding.width / 2;

      positions.current = {
        left: center,
        bottom: bounding.bottom,
        right: center,
        top: bounding.bottom,
      };
    }

    setSearchOpened(p => !p);
  };

  const handleSearchClick = () => {
    toggleSearch();

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'search' });
  };

  return {
    isSearchOpen,
    searchContainerRef,
    setSearchOpened,
    handleSearchClick,
  };
};

export default useSearchAction;
