import React from 'react';
/* Problème avec styled-components https://github.com/styled-components/styled-components/issues/2227 */

import IntroAltFontOtf from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Bold.otf';
import IntroAltFontWoff from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Bold.woff';
import IntroAltFontWoff2 from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Bold.woff2';
import IntroAltRegularFontOtf from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Regular.otf';
import IntroAltRegularFontWoff from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Regular.woff';
import IntroAltRegularFontWoff2 from '~/src/common/assets/fonts/Intro-Alt/Intro-Alt-Regular.woff2';

const Fonts = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Intro-Alt';
      src:
        url(${IntroAltFontWoff2}) format('woff2'),
        url(${IntroAltFontWoff}) format('woff'),
        url(${IntroAltFontOtf}) format('opentype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'Intro-Alt';
      src:
        url(${IntroAltRegularFontWoff2}) format('woff2'),
        url(${IntroAltRegularFontWoff}) format('woff'),
        url(${IntroAltRegularFontOtf}) format('opentype');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }
  `}</style>
);

export default Fonts;
