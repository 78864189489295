import React from 'react';

import { createRoot } from 'react-dom/client';

import Dialog from './Dialog';
import { DialogProps } from './types';

const dialog = (props: Omit<DialogProps, 'onClose'>) => {
  const element = document.createElement('div');
  const root = createRoot(element);
  document.body.appendChild(element);

  const handleClose = () => {
    root.unmount();
    document.body.removeChild(element);
  };

  root.render(<Dialog {...props} onClose={handleClose} />);
};

export default dialog;
