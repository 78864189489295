import { isNumber, isTruthy } from './guards';

/**
 * Compare les valeurs passées en argument et retourne :
 * - Si `valA = valB` : `0`
 * - Si `valA < valB` : `1`
 * - Si `valA > valB` : `-1`
 *
 * La notion d'ordre change en fonction du type des arguments :
 * - Si les deux valeurs sont des nombres, on utilise un tri numerique
 * - Sinon, on compare en utilisant l'ordre alphabetique des valeurs converties en string
 */
export const compare = (valA: string | number, valB: string | number) => {
  if (String(valA) === String(valB)) return 0;
  if (isNumber(valA) && isNumber(valB)) return valA > valB ? 1 : -1;
  return valA.toLocaleString().localeCompare(valB.toLocaleString()) > 0 ? 1 : -1;
};

/**
 * Tri une liste d'élément en utilisant la valeur définie par `getSortValue`
 * Il est possible de forcer un tri inverse en passant `true` en 3eme argument
 */
export const sortBy = <T>(
  items: T[],
  getSortValue: ((item: T) => string) | ((item: T) => number),
  reverse = false,
) => {
  const dir = reverse ? -1 : 1;
  return items
    .map(item => ({ item, val: getSortValue(item) }))
    .sort((a, b) => dir * compare(a.val, b.val))
    .map(({ item }) => item);
};

/**
 * Regroupe des elements d'un tableau dans une collection de tableau
 * La valeur utilisée pour l'indexation est le retour de `getItemKey`
 */
export const groupBy = <T>(
  items: T[],
  getItemKey: ((item: T) => string) | ((item: T) => number),
) => {
  const groups: Record<string, T[]> = {};
  items.forEach(item => {
    const key = getItemKey(item);
    groups[key] ??= [];
    groups[key].push(item);
  });
  return groups;
};

/**
 * Retourne un nouveau tableau dont tout les elements sont `truthy`.
 * Les valeurs 0, '', NaN, null, false, undefined sont considérés `falsy`.
 */
export const compact = <T>(items: (T | 0 | '' | null | false | undefined)[]) => {
  return items.filter(isTruthy);
};

/**
 * Dédoublonne le tableau passé en ne conservant que la premiere
 * occurence de chaque élément présent plus d'une fois
 */
export const uniq = <T>(items: T[]) => {
  return [...new Set(items)];
};

/**
 * Implémentation minimaliste de `Array.at()` pour les navigateurs ne le supportant pas.
 * Supporte les index négatifs, et retourne `undefined` si l'index est hors de portée.
 */
export const at = <T>(items: T[], index: number): T | undefined => {
  if (index >= 0) return items[index];
  return items[items.length + index];
};

/**
 * Retourne le dernier élément d'un tableau, ou `undefined` si le tableau est vide
 */
export const last = <T>(items: T[]): T | undefined => {
  return at(items, -1);
};

/**
 * Return true if the value is one of the values in the array
 */
export function isOneOf<T>(value: T, values: T[]) {
  return values.includes(value);
}
