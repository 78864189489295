import styled, { css } from 'styled-components';

import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { NAV_HEIGHT, media, zIndex } from '~/src/styles-constants';

export const ConfettisBlock = styled.div<{ $isRevert: boolean }>`
  position: relative;
  bottom: 2px;
  transform: ${({ $isRevert }) => ($isRevert ? 'scale(-1, 1)' : 'none')};
`;

export const Container = styled.div<{ $isJaugeProgressionDisplayed: boolean }>`
  position: fixed;
  left: 0;

  ${({ $isJaugeProgressionDisplayed }) =>
    $isJaugeProgressionDisplayed
      ? css`
          bottom: calc(env(safe-area-inset-bottom) + 64px);
        `
      : css`
          bottom: 0;

          & > a > div {
            padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
          }
        `};

  z-index: ${zIndex.inProgressOrderBanner};
  width: 100%;

  ${media.XL`
    position: sticky;
    top: ${NAV_HEIGHT}px;
    bottom: auto;
  `};
`;

export const Content = styled.div`
  padding: 8px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};

  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.XL`
      display: flex;
      align-items: center;
  `};
`;

export const LayoutContainer = styled.div`
  & > div:first-child {
    margin-right: 8px;
  }

  & > div:last-child {
    margin-left: 10px;
  }
`;

const baseConfettiChunkStyle = css`
  height: 3px;
  width: 10px;
  border-radius: 3px;
`;

export const ConfettiContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
  }

  /* Confetti chunk */
  & > div:first-child {
    ${baseConfettiChunkStyle};
    background-color: ${({ theme }) => theme.palette.common.PITAYA};
  }

  & > div:last-child {
    ${baseConfettiChunkStyle};
    background-color: ${({ theme }) => theme.palette.common.PINK};
  }

  &:first-child {
    transform: rotate(25deg);

    > div:first-child {
      top: 0;
      right: 2px;
    }

    > div:last-child {
      top: 3px;
      right: 2px;
    }
  }

  &:last-child {
    transform: rotate(-25deg);

    margin-top: 7px;

    > div:first-child {
      top: -1px;
      right: 4px;
    }

    > div:last-child {
      top: 1.5px;
      right: 4px;
    }
  }
`;

export const PickupIcon = styled(Icon)`
  margin-right: 8px;
  align-self: center;
  font-size: 16px !important;
`;

export const InProgressOrdersTitle = styled.h4`
  margin: 0 0 0 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;

  ${media.XL`
    margin-top: 0;
    margin-left: 20px;
  `};
  ${media.L`
    margin-bottom: 0;
  `};
`;

export const DeliveryInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > span,
  & > div {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DesktopDeliverySlotInfos = styled.div`
  display: none;

  ${media.XL`
    display: flex;
  `};
`;
export const MobileDeliverySlotInfos = styled.div`
  display: flex;

  ${media.XL`
    display: none !important;
  `};
`;

export const DeliveryInfoDate = styled.div`
  &::first-letter {
    margin-left: 3px;
    text-transform: capitalize;
  }
`;

export const DeliveryTime = styled.div<{ $hasLineThrough: boolean }>`
  margin-left: 3px;
  ${({ $hasLineThrough }) => ($hasLineThrough ? 'text-decoration: line-through' : '')};
`;

export const DeliveryTimeWithDelay = styled.div`
  margin-left: 2px;
  font-weight: 600;
  font-size: 14px;
`;

export const SpinnerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.WHITE};
`;

export const StyledLink = styled(Link)`
  &,
  &:hover {
    color: inherit;
  }
`;
