export default {
  'title': 'Une erreur vient de se produire',
  'subtitle':
    'Pour profiter de la meilleure expérience, utilisez un navigateur comme Google Chrome (https://www.google.com/intl/fr_ALL/chrome/) ou Firefox (https://www.mozilla.org/fr/firefox/new/)',
  'label-name': 'Nom',
  'label-email': 'Email',
  'label-comments': 'Décrivez le problème',
  'label-submit': "Envoyer le rapport d'erreur",
  'label-close': 'Fermer',
  'error-generic':
    "Une erreur inconnue s'est produite lors de l'envoie de votre rapport d'erreur. Merci de recommencer.",
  'error-form-entry':
    'Certains champs sont invalides. Merci de corriger les erreurs et de recommencer.',
  'success-message': 'Votre rapport a bien été envoyé. Merci!',
};
