import { v4 as uuidv4 } from 'uuid';

import { OrderProduct } from '~/src/common/typings/order';
import { ProductDetailed } from '~/src/common/typings/product';

import { ENHANCED_ECOMMERCE_KEYS } from './constants';

import {
  FormatProductImpressionPayload,
  ProductsViewPayload,
  PurchasePayload,
  PushDataLayerPayload,
  SetDataLayerPayload,
  SetProductQuantityToCartPayload,
} from '.';

class TagManagerService {
  productView({ product, list }: { product: ProductDetailed; list: string }) {
    this.setDataLayer({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list },
          products: [
            this.formatProductImpression({
              product: {
                ...product,
                price: product.itemPrice / 100,
              },
              list,
            }),
          ],
        },
      },
    });
  }

  productDetails({ product, list }: { product: ProductDetailed; list: string }) {
    this.setDataLayer({
      event: 'productDetails',
      ecommerce: {
        detail: {
          actionField: { list },
          products: [
            this.formatProductImpression({
              product: {
                ...product,
                price: product.itemPrice / 100,
              },
            }),
          ],
        },
      },
    });
  }

  pushDataLayer(data: PushDataLayerPayload) {
    if (!window.dataLayer) return;

    // Clear the previous ecommerce object
    // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-impressions
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
    window.dataLayer.push({ ecommerce: null });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
    window.dataLayer.push(data);
  }

  setDataLayer(data: SetDataLayerPayload) {
    const d = data;

    if (!window.dataLayer) return;

    // Clear the previous ecommerce object
    // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-impressions
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
    window.dataLayer.push({ ecommerce: null });

    // Necessaire pour clean de viewName dans le dataLayer
    d.viewName = data?.viewName;

    d.ecommerce = data?.ecommerce ?? {};

    ENHANCED_ECOMMERCE_KEYS.forEach(key => {
      // eslint-disable-next-line
      // @ts-ignore
      if (d.ecommerce != null) d.ecommerce[key] = data?.ecommerce?.[key];
    });

    d.event_id = data.event_id ?? uuidv4();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call  -- auto-ignored when updating eslint
    window.dataLayer.push(d);
  }

  formatProductImpression({
    product,
    position,
    list,
    forcedCategory,
    quantity,
  }: FormatProductImpressionPayload) {
    const { name, canonicalId, id, price } = product;
    const category = 'articleParents' in product ? product.articleParents?.[0] : undefined;
    const parentCategory = 'articleParents' in product ? product?.articleParents?.[1] : undefined;

    let categoryName =
      parentCategory?.name != null
        ? `${parentCategory.name}/${category?.name ?? ''}`
        : category?.name ?? '';

    if (forcedCategory) {
      categoryName = forcedCategory;
    }

    return {
      name,
      id: canonicalId || id,
      price: price?.toFixed(2) ?? '',
      category: categoryName,
      variant: id,
      list,
      position,
      quantity: quantity ?? '',
    };
  }

  formatGA4ProductImpression({
    product,
    position,
    list,
    quantity,
  }: FormatProductImpressionPayload) {
    const { name, canonicalId, id, price } = product;
    const category = 'articleParents' in product ? product.articleParents?.[0] : undefined;
    const parentCategory = 'articleParents' in product ? product.articleParents?.[1] : undefined;

    return {
      item_name: name,
      item_id: canonicalId || id,
      price: price?.toFixed(2) ?? '',
      item_category: parentCategory?.name,
      item_category2: category?.name,
      item_list_name: list,
      index: position,
      quantity: quantity ?? '',
    };
  }

  productsView({ products, listName: list, forcedCategory }: ProductsViewPayload) {
    this.setDataLayer({
      event: 'productsList',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: products?.map((product, position) =>
          this.formatProductImpression({
            product,
            position,
            list,
            forcedCategory,
          }),
        ),
      },
    });
  }

  purchase({
    id,
    promoCodes,
    tax,
    products,
    totalShipping,
    orderPrepaid,
    isFirstPurchase,
  }: PurchasePayload) {
    try {
      this.setDataLayer({
        // Criteo
        PageType: 'TransactionPage',
        TransactionID: id,
        revenue: orderPrepaid,
        tax,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ProductTransactionProducts: (products as unknown as OrderProduct[]).map(
          ({ name, quotation, canonicalId, id: productId, itemPrice }) => ({
            id: canonicalId || productId,
            name,
            price: (itemPrice / 100).toFixed(2),
            quantity: quotation.count || quotation.weight,
          }),
        ),
        // Criteo
        event: 'purchase',
        firstPurchase: isFirstPurchase,
        ecommerce: {
          purchase: {
            actionField: {
              id, // Transaction ID. Required for purchases and refunds.
              revenue: (orderPrepaid / 100).toFixed(2),
              tax: (tax / 100).toFixed(2),
              shipping: (totalShipping / 100).toFixed(2),
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              coupon: `${promoCodes}`,
            },
            products: (products as unknown as OrderProduct[]).map(product =>
              this.formatProductImpression({
                product: {
                  ...product,
                  price: product.itemPrice / 100,
                },
                quantity: product.quotation.count || product.quotation.weight,
              }),
            ),
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  setProductQuantityToCart(
    { product, quantity, from }: SetProductQuantityToCartPayload,
    addedToCart = true,
  ) {
    const eventPropName = addedToCart ? 'add' : 'remove';

    // GA Standard
    this.setDataLayer({
      event: addedToCart ? 'addToCart' : 'removeFromCart',
      ecommerce: {
        currencyCode: 'EUR',
        [eventPropName]: {
          actionField: { list: from },
          products: [
            this.formatProductImpression({
              product: {
                ...product,
                price: Math.abs((product.itemPrice ?? 0) / 100),
              },
              quantity: Math.abs(quantity),
              list: from,
            }),
          ],
        },
      },
    });

    // GA4
    this.setDataLayer({
      event: addedToCart ? 'add_to_cart' : 'remove_from_cart',
      ecommerce: {
        items: [
          this.formatGA4ProductImpression({
            product: {
              ...product,
              price: Math.abs((product.itemPrice ?? 0) / 100),
            },
            quantity: Math.abs(quantity),
            list: from,
          }),
        ],
      },
    });
  }

  pageView(PageType: string) {
    this.setDataLayer({
      event: 'pageView',
      PageType,
    });
  }
}

export default new TagManagerService();
