import dialog from './createDialog';
import { DialogProps } from './types';

type Props = Omit<DialogProps, 'onClose' | 'variant'>;

const error = (props: Props) => dialog({ size: 'medium', ...props, variant: 'error' });

const success = (props: Props) => dialog({ isAutoCloseable: true, ...props, variant: 'success' });

const info = (props: Props) => dialog({ ...props, variant: 'info' });

const Dialog = {
  error,
  success,
  info,
};

export default Dialog;
