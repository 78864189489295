import React from 'react';

import { formatPrice } from 'src/common/utils/prices';

import I18n from '~/src/common/services/I18n';

import { Props as DeliveryProgressBarProps } from './DeliveryProgressBar';
import {
  ProgressStepDot,
  ProgressStepDotIcon,
  ProgressStepPriceContainer,
  ProgressStepPriceText,
} from './layout';

type Props = {
  step: DeliveryProgressBarProps['deliveryPrices'][number];
  reachedValue: number;
  isFirst?: boolean;
  isLast?: boolean;
  lastMincartNetPrice: DeliveryProgressBarProps['deliveryPrices'][number]['minCartNetPrice'];
};

const ProgressBarStep = ({
  isFirst = false,
  isLast = false,
  step,
  lastMincartNetPrice,
  reachedValue,
}: Props) => {
  const isReached = step.minCartNetPrice <= reachedValue;
  const absolutePosition = (step.minCartNetPrice * 100) / lastMincartNetPrice;

  return (
    <>
      <ProgressStepDot
        $isFirst={isFirst}
        $isLast={isLast}
        $isReached={isReached}
        $absolutePosition={absolutePosition}>
        {!isFirst && isReached ? (
          <ProgressStepDotIcon name="valid-small" color="WHITE" size={10} />
        ) : null}
      </ProgressStepDot>
      <ProgressStepPriceContainer
        $isFirst={isFirst}
        $isLast={isLast}
        $absolutePosition={absolutePosition}>
        <ProgressStepPriceText $isHighlighted={isReached && !isFirst}>
          {step.shippingAmount === 0
            ? I18n.t('delivery-progress-bar.free')
            : `${formatPrice(step.shippingAmount)}`}
        </ProgressStepPriceText>
      </ProgressStepPriceContainer>
    </>
  );
};

export default ProgressBarStep;
