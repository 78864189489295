import styled from 'styled-components';

import { transparentize } from '~/src/common/utils/style';

const StableContainer = styled.div`
  position: relative;

  & > div:first-child {
    position: absolute;
    inset: 1px 0 0;
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  font-size: 3.2px;
  border-top: 1.1em solid ${({ theme }) => theme.palette.common.PRIMARY};
  border-right: 1.1em solid ${({ theme }) => theme.palette.common.PRIMARY};
  border-bottom: 1.1em solid ${({ theme }) => theme.palette.common.PRIMARY};
  border-left: 1.1em solid ${({ theme }) => transparentize(theme.palette.common.PRIMARY_HOVER, 0.6)};
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({
  children,
  hasStableContent = true,
}: {
  children?: React.ReactNode;
  hasStableContent?: boolean;
}) =>
  hasStableContent ? (
    <StableContainer>
      {children}
      <SpinnerContainer />
    </StableContainer>
  ) : (
    <SpinnerContainer>{children}</SpinnerContainer>
  );

export default Spinner;
