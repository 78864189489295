import React from 'react';

import styled from 'styled-components';

import Button, { ButtonsGroup } from '~/src/common/components/Button';
import Icon from '~/src/common/components/Icon';
import { Waves } from '~/src/common/components/Logo';
import ModalComponent from '~/src/common/components/Modal';
import { H3 } from '~/src/common/components/Typography';
import I18n from '~/src/common/services/I18n';
import { media, zIndex } from '~/src/styles-constants';

import { DialogVariantProps } from './types';
import { computeChildrenWidth } from './utils';

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 32px 8px;

  text-align: center;

  ${media.M`
    padding: 32px 40px;
  `}
`;

export const Modal = styled(ModalComponent)`
  z-index: ${zIndex.errorModal};
`;

const BaseTitle = styled(H3)`
  margin-top: 10px;
  margin-bottom: 15px;

  text-wrap: balance;
  text-align: center;
`;

const ErrorTitle = styled(BaseTitle)`
  color: ${({ theme }) => theme.palette.error};
`;

export const WavesHeader = ({ children }: { children: React.ReactNode }) => (
  <>
    <Waves />
    <BaseTitle>{children}</BaseTitle>
  </>
);

export const ErrorHeader = ({ children }: { children: React.ReactNode }) => (
  <>
    <Icon name="warning-normal" size={50} color="ERRORS" />
    <ErrorTitle>{children}</ErrorTitle>
  </>
);

export const ConfirmAndCancelButtons = ({
  confirmText = I18n.t('common.confirm'),
  cancelText = I18n.t('common.cancel'),
  onConfirm,
  onCancel,
}: Pick<DialogVariantProps, 'confirmText' | 'cancelText' | 'onConfirm' | 'onCancel'>) => (
  <ButtonsGroup $childrenWidth={computeChildrenWidth(confirmText ?? '', cancelText ?? '')}>
    {cancelText !== null ? (
      <Button
        variant={onConfirm == null ? 'primary' : 'secondary'}
        onClick={() => void onCancel?.()}>
        {cancelText}
      </Button>
    ) : null}
    {onConfirm != null && confirmText !== null ? (
      <Button onClick={() => void onConfirm()}>{confirmText}</Button>
    ) : null}
  </ButtonsGroup>
);

export const FooterText = styled.p`
  margin: 14px 0 0;

  text-align: center;
  font-size: 12px;
  line-height: 16px;
`;

export const Description = styled.div`
  margin-top: 9px;
  margin-bottom: 14px;

  text-align: center;
  font-weight: 400;
  font-size: 14px;

  span,
  p {
    font-weight: 500;
    line-height: 16px;
  }
`;
