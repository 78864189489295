import getConfig from 'next/config';

import { fetch } from '~/src/common/services/Networking';

import { getUpdatedServiceURL } from './utils';

// GraphQL nous retourne le résultat soit :
// - Si tout est bon, dans un objet de la forme `{ data: TData }`
// - Sinon, des données partielles + erreurs `{ data: Incomplete<TData>; error: GqlError[] }`
type Result<TData> = { data: TData } | { data?: unknown; errors: { message: string }[] };

const fetchCMS = async <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers'],
): Promise<TData> => {
  const cfg = getConfig().publicRuntimeConfig;
  const baseServiceUrl = cfg.CMS_GRAPHQL_ENDPOINT;
  const updatedServiceUrl = getUpdatedServiceURL(baseServiceUrl);

  const res = await fetch<Result<TData>>({
    method: 'post',
    url: updatedServiceUrl,
    data: { query, variables },
    forwardCookies: true,
    forwardHost: true,
    headers,
  });

  if (!('errors' in res)) return res.data;
  throw new Error(res.errors[0].message);
};

export const getFetchCMS = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers'],
) => {
  return () => fetchCMS<TData, TVariables>(query, variables, headers);
};
