import dayjs from '~/src/common/services/Date';
import { CurrentOrders200ItemsItemDeliveryTimeSlot } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

function roundToNearestFiveMinutes(date: Date | number | string) {
  const rounded = dayjs(new Date(date).getTime());

  return rounded
    .minute(Math.round(rounded.minute() / 5) * 5)
    .second(0)
    .millisecond(0)
    .valueOf();
}

export function getDeliveryEstimatedSlot(
  orderSlot: CurrentOrders200ItemsItemDeliveryTimeSlot,
  eta?: number | string,
): [number, number] {
  if (!eta) return [orderSlot.from, orderSlot.to];

  const rounded = roundToNearestFiveMinutes(eta);

  let minEstimated = dayjs(rounded).subtract(15, 'minutes');
  let maxEstimated = dayjs(rounded).add(15, 'minutes');

  const minLowerBound = dayjs(orderSlot.from);
  if (minLowerBound.isAfter(minEstimated)) minEstimated = minLowerBound;

  const minUpperBound = dayjs(orderSlot.from).add(15, 'minutes');
  if (minUpperBound.isAfter(maxEstimated)) maxEstimated = minUpperBound;

  return [minEstimated.valueOf(), maxEstimated.valueOf()];
}
