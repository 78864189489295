import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'other:fancy_title';
const FancyTitle = dynamic(() => import('./Component'));
export default createComponent(BuilderId, FancyTitle, inputs, {
  friendlyName: 'Texte fantaisie',
});
