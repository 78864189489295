import { DefaultTheme, useTheme } from 'styled-components';

import * as S from './layout';

type Props = {
  children: string;
  angle?: string;
  fg?: string;
  bg?: string;
  font?: keyof DefaultTheme['fonts'];
};

const Sticker = ({ children, angle, font, fg, bg }: Props) => {
  const theme = useTheme();
  const style = {
    color: fg || theme.palette.common.WHITE,
    background: bg || theme.palette.primary,
    fontFamily: theme.fonts[font ?? 'quaternary'],
    transform: `rotate(${angle ?? -4}deg)`,
  };

  return (
    <S.Container style={style} title={children}>
      {children}
    </S.Container>
  );
};

export default Sticker;
