import { QueryClient } from '@tanstack/react-query';

import { UpdateCartProduct } from '~/src/common/hooks/cart/types';
import { TagManager } from '~/src/common/services/TagManager';
import Tracker, {
  AddToCartProductEventSource,
  ExtraCartEventProperties,
  getCartEventMapping,
  getCartEventName,
} from '~/src/common/services/Tracker';
import { Cart } from '~/src/common/typings/cart';
import { isSameProduct } from '~/src/common/utils/product';
import { getGetCartQueryKey } from '~/src/queries/api-ecom/generated/api-ecom';
import { Recompute2200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

interface QuantityUpdateEventsProps {
  queryClient: QueryClient;
  quantity: number;
  productEventSource: AddToCartProductEventSource;
  CategoryNameFromProduct?: string;
  subcategoryName?: string;
  product: UpdateCartProduct;
  otherAnalyticsProperties?: ExtraCartEventProperties;
}
export const sendQuantityUpdateEvents = ({
  queryClient,
  quantity,
  productEventSource,
  product,
  CategoryNameFromProduct,
  subcategoryName,
  otherAnalyticsProperties,
}: QuantityUpdateEventsProps) => {
  const previousCart = queryClient.getQueryData<Cart>(getGetCartQueryKey());
  const previousCartProduct = previousCart?.products?.find(p => isSameProduct(p, product));
  const previousQuantity = previousCartProduct?.quotation2.count.quantity ?? 0;

  const { eventName, status } = getCartEventName(previousQuantity, quantity);
  const isFromXSell = ['checkout cross sell', 'main product cross sell'].includes(
    productEventSource,
  );

  const categoryName = isFromXSell ? 'Cross Selling' : CategoryNameFromProduct;

  TagManager.setProductQuantityToCart(
    {
      product,
      quantity: quantity - previousQuantity,
      from: productEventSource === 'checkout main product' ? 'Basket' : categoryName,
    },
    quantity >= previousQuantity,
  );

  Tracker.sendEvent(
    eventName,
    getCartEventMapping({
      cart: previousCart,
      quantity: quantity - previousQuantity,
      productEventSource,
      product,
      categoryName,
      subcategoryName,
      status,
      otherAnalyticsProperties,
    }),
  );
};

export const getArticlesCount = (cart?: Cart | Recompute2200) =>
  cart?.products?.reduce((count, p) => count + p.quotation2.count.quantity, 0) ?? 0;
