import { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';

import { useStable } from './use-stable';

type Value = string | null;
type Listener = () => void;
type Predicate<T> = (val: unknown) => val is T;

/**
 * Récupère une valeur depuis le localStorage.
 * Si la clé n'existe pas, retourne `null`.
 */
const getLocalStorageEntry = (key: string): Value => {
  return localStorage.getItem(key);
};

/**
 * Met à jour une valeur du localStorage. Si cette valeur
 * vaut `null` ou `undefined`, alors la clé est supprimée.
 * Déclenche un événement de stockage personnalisé si la valeur change.
 */
const setLocalStorageEntry = (key: string, newValue: Value) => {
  const oldValue = getLocalStorageEntry(key);
  if (newValue == null) localStorage.removeItem(key);
  else localStorage.setItem(key, newValue);
  if (newValue !== oldValue) dispatchStorageEvent(key, oldValue, newValue);
};

/**
 * Déclenche un événement de stockage personnalisé pour notifier
 * les autres parties de l'application des changements dans le localStorage.
 */
const dispatchStorageEvent = (key: string, oldValue: Value, newValue: Value) => {
  const payload = { key, oldValue, newValue, storageArea: localStorage };
  window.dispatchEvent(new StorageEvent('storage', payload));
};

/**
 * Abonne un écouteur à l'événement de stockage. Les écouteurs
 * sont stockés dans un Set pour éviter les doublons.
 * Retourne une fonction pour désabonner l'écouteur.
 */
const subscribeToStorageEvent = (() => {
  const listeners = new Set<Listener>();
  if (typeof window === 'object') {
    window.addEventListener('storage', event => {
      if (event.storageArea !== localStorage) return;
      listeners.forEach(listener => listener());
    });
  }
  return (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };
})();

/**
 * Hook React pour utiliser le localStorage avec synchronisation externe.
 * Fonctionne de manière similaire à `useState`, mais persiste les données
 * dans le localStorage sous forme de chaînes JSON. Cela permet de conserver
 * les valeurs entre les sessions de navigation.
 *
 * Le paramètre `predicate` est une fonction de validation qui permet de vérifier
 * et de caster la valeur récupérée du localStorage. Cela garantit que la valeur
 * est du type attendu avant de l'utiliser dans le composant.
 *
 * Le retour de la valeur est conditionné au premier rendu pour éviter des problèmes
 * d'hydratation avec le SSR (Server-Side Rendering). Pendant le premier rendu, la valeur
 * retournée est `null` pour s'assurer qu'elle soit identique à la valeur utilisée lors du
 * rendu effectué côté serveur. Après le premier rendu, la valeur correcte est retournée.
 */
export const useLocalStorage = <T>(key: string, predicate: Predicate<T>) => {
  // Branchement au localStorage et récupération de la valeur stringifiée
  const serialized = useSyncExternalStore(
    subscribeToStorageEvent,
    () => getLocalStorageEntry(key),
    () => null,
  );

  // Le predicate est utilisé pour certifier le type de valeur
  const cast = useStable((v: unknown) => {
    try {
      return predicate(v) ? v : null;
    } catch {
      return null;
    }
  });

  // La valeur visible par l'utilisateur est déserialisée et castée
  const value = useMemo(() => {
    try {
      return cast(JSON.parse(String(serialized)));
    } catch {
      return null;
    }
  }, [cast, serialized]);

  // Le setter va automatiquement valider et sérialiser la valeur
  const setValue = useCallback(
    (v: T | null) => {
      try {
        setLocalStorageEntry(key, JSON.stringify(cast(v)));
      } catch {
        // Noop -- Commentaire nécessaire pour eslint
      }
    },
    [key, cast],
  );

  // Pour éviter les soucis de SSR, le premier render renvoit null
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => setFirstRender(false), []);

  return [firstRender ? null : value, setValue] as const;
};
