import { useState } from 'react';

import SlotLinks from '~/src/common/services/Builder/elements/SlotLinks';
import I18n from '~/src/common/services/I18n';

import {
  AboutInfo,
  LinksInnerSection,
  LinksSection,
  SectionTitle,
  ToggleSectionButton,
} from '../layout';
import { sendFooterNavigationEvent } from '../utils';

const AboutSection = () => {
  const [isSectionOpened, setSectionOpened] = useState(false);

  const toggleSectionOpened = () => setSectionOpened(!isSectionOpened);

  return (
    <LinksSection>
      <SectionTitle onClick={toggleSectionOpened}>
        {I18n.t('footer.about')}{' '}
        <ToggleSectionButton
          $isOpened={isSectionOpened}
          variant="transparent"
          icon="Chevron-down"
        />
      </SectionTitle>
      <LinksInnerSection $isOpened={isSectionOpened}>
        <SlotLinks slot="footer:about" onClick={({ text }) => sendFooterNavigationEvent(text)} />
        <AboutInfo>{I18n.t('footer.about-info')}</AboutInfo>
      </LinksInnerSection>
    </LinksSection>
  );
};

export default AboutSection;
