import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderId = 'section:promo_details';
const PromoExplanationItems = dynamic(() => import('./Component'));
export default createComponent(BuilderId, PromoExplanationItems, inputs, {
  friendlyName: 'Section détails promotions',
});
