import React from 'react';

import * as S from '../layout';
import { DialogVariantProps } from '../types';

const SuccessDialog = ({ title, description }: DialogVariantProps) => {
  return (
    <>
      {title != null ? <S.WavesHeader>{title}</S.WavesHeader> : null}
      {description != null ? <S.Description>{description}</S.Description> : null}
    </>
  );
};

export default SuccessDialog;
