import type { Logger } from './types';

// Utilisation d'une queue le temps du chargement
const queue: Array<() => void> = [];
const logger: Logger = {
  debug: (...args) => queue.push(() => logger.debug(...args)),
  info: (...args) => queue.push(() => logger.info(...args)),
  warning: (...args) => queue.push(() => logger.warning(...args)),
  error: (...args) => queue.push(() => logger.error(...args)),
  critical: (...args) => queue.push(() => logger.critical(...args)),
  setUser: (...args) => queue.push(() => logger.setUser?.(...args)),
  showReportDialog: (...args) => queue.push(() => logger.showReportDialog?.(...args)),
};

// Import dynamique afin de faciliter le tree-shaking
const loader =
  typeof window === 'undefined'
    ? () => import('./server/logger').then(m => m.serverLogger)
    : () => import('./browser/logger').then(m => m.browserLogger);

// Chargement
void loader()
  .then(isoLogger => {
    Object.assign(logger, isoLogger);
    queue.forEach(callback => callback());
  })
  .catch(err => {
    console.error('Failed to load logger', err);
  });

export { logger };
