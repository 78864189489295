export default {
  'placeholder-address': 'Votre adresse de livraison',
  'placeholder-billling-address': 'Votre adresse de facturation',
  'placeholder-postal-code': 'Code postal',
  'searching-for': 'Recherche en cours pour "%{search}"',
  'street-number-missing': "Psst ! N'oubliez pas le numéro ;)",
  'enable-location-mobile': 'Veuillez activer la localisation dans les réglages de votre téléphone',
  'enable-location-desktop':
    'Veuillez activer la localisation dans les réglages de votre navigateur',
  'no-result-matching': 'Vous ne trouvez pas votre adresse ?',
  'use-manual-input': 'Utiliser la saisie manuelle',
};
