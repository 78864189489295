import React from 'react';

import I18n from '~/src/common/services/I18n';
import { formatPrice } from '~/src/common/utils/prices';

import { DELIVERY_BAR_TREESHOLD } from './constants';
import { Props } from './DeliveryProgressBar';
import { ContainerWording, HighLightDeliveryPrice, IconGift, TextWording } from './layout';

const WordingText = ({ deliveryPrices, totalCartAmount }: Props) => {
  const reachedSteps = deliveryPrices.filter(step => step.minCartNetPrice <= totalCartAmount);
  const unReachedSteps = deliveryPrices.filter(step => step.minCartNetPrice > totalCartAmount);
  const lastStep = deliveryPrices[deliveryPrices.length - 1];
  const allStepsAreReached = !unReachedSteps.length;
  const noStepIsReached = !reachedSteps.length;
  const lastStepDeliveryIsNotFree = lastStep.shippingAmount > 0;
  const nextStepIsFar =
    !allStepsAreReached &&
    unReachedSteps[0].minCartNetPrice - totalCartAmount > DELIVERY_BAR_TREESHOLD;
  const reachedOnlyOneStep = reachedSteps.length === 1;

  if (noStepIsReached) {
    return (
      <TextWording>
        {I18n.t('delivery-progress-bar.delivery-offered-when-step-reached', {
          deliveryFee: formatPrice(unReachedSteps[0].shippingAmount),
          cartStep: formatPrice(unReachedSteps[0].minCartNetPrice),
        })}
      </TextWording>
    );
  }

  if (allStepsAreReached) {
    if (lastStepDeliveryIsNotFree) {
      return (
        <TextWording>
          {I18n.t('delivery-progress-bar.delivery-at')}{' '}
          <HighLightDeliveryPrice>{formatPrice(lastStep.shippingAmount)}</HighLightDeliveryPrice>{' '}
          {I18n.t('delivery-progress-bar.applied')}
        </TextWording>
      );
    }
    return (
      <TextWording>
        {I18n.t('delivery-progress-bar.delivery')}{' '}
        <HighLightDeliveryPrice>{I18n.t('delivery-progress-bar.FREE')}</HighLightDeliveryPrice>{' '}
        {I18n.t('delivery-progress-bar.applied')}
      </TextWording>
    );
  }

  if (nextStepIsFar) {
    if (reachedOnlyOneStep) {
      return (
        <TextWording>
          {I18n.t(
            `delivery-progress-bar.${
              unReachedSteps[0].shippingAmount === 0 ? 'free-' : ''
            }delivery-when-step-reached`,
            {
              deliveryFee: formatPrice(unReachedSteps[0].shippingAmount),
              cartStep: formatPrice(unReachedSteps[0].minCartNetPrice),
            },
          )}
        </TextWording>
      );
    }
    return (
      <TextWording>
        {I18n.t('delivery-progress-bar.delivery-at')}{' '}
        <HighLightDeliveryPrice>
          {formatPrice(reachedSteps[reachedSteps.length - 1].shippingAmount)}
        </HighLightDeliveryPrice>{' '}
        {I18n.t('delivery-progress-bar.applied')}
      </TextWording>
    );
  }

  return (
    <TextWording>
      {I18n.t(
        `delivery-progress-bar.next-step-${
          unReachedSteps[0].shippingAmount > 0 ? 'paying' : 'offered'
        }`,
        {
          remaining: formatPrice(unReachedSteps[0].minCartNetPrice - totalCartAmount),
          deliveryFee: formatPrice(unReachedSteps[0].shippingAmount),
        },
      )}
    </TextWording>
  );
};

const DeliveryProgressBarWording = ({ deliveryPrices, totalCartAmount = 0 }: Props) => {
  if (!deliveryPrices || !deliveryPrices?.length) return null;

  return (
    <ContainerWording>
      <IconGift name="gift" size={14} color="PITAYA" />
      <WordingText deliveryPrices={deliveryPrices} totalCartAmount={totalCartAmount} />
    </ContainerWording>
  );
};

export default DeliveryProgressBarWording;
