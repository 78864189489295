import styled from 'styled-components';

import Icon from '~/src/common/components/Icon';
import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

import { TRANSITION_DURATION } from '../../constants';

/** L'input utilisé dans la barre de recherche */
export const Field = styled.input`
  width: 100%;
  height: 32px;
  border: 0 none;
  outline: 0 none;
  padding: 0 32px;

  font-size: 12px;
  background: transparent;
  transition: color ${TRANSITION_DURATION}ms ease-in-out;
  color: ${({ theme }) => theme.palette.common.TEXTS};

  &::placeholder {
    font-size: inherit;
    transition: inherit;
    color: ${({ theme }) => theme.palette.common.TEXTS};
  }

  /* Hors desktop: Passage à 16px pour éviter le zoom iOS */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    font-size: 16px;
  }
`;

/** Icône [🔎] affichée au début de la barre de recherche */
export const IconSearch = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 0;
  padding: 8px 6px 8px 10px;
  transform: translateY(-50%);

  font-size: 16px;
  pointer-events: none;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
`;

/** Icône [✖] affichée à la fin de la barre de recherche */
export const IconReset = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 8px;
  transform: translateY(-50%);

  font-size: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.DISABLED};
`;

/** Icône [←] affichée avant le champ, uniquement en mobile */
const IconClose = styled(Icon)`
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 2px;
  transform: translate(-38px, -50%);

  font-size: 28px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
`;

/** Le container pour le champ input et les icones */
export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.common.WHITE};

  @media ${getMediaQuery(0, breakpoints.XL)} {
    margin: 0 16px 8px;

    ${IconClose} {
      display: block;
    }
  }
`;
