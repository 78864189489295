import styled from 'styled-components';

import { BaseWaves, Waves } from '../Logo';

import { ANIMATION_STYLE } from './layout';

export const BaseLoader = styled(BaseWaves).attrs<React.SVGProps<SVGSVGElement>>(_props => ({
  role: 'status',
}))`
  ${ANIMATION_STYLE}
`;

const Loader = styled(Waves).attrs<React.SVGProps<SVGSVGElement>>(_props => ({ role: 'status' }))`
  ${ANIMATION_STYLE}
`;

export default Loader;
