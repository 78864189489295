/**
 * Cette fonction ne fait rien. Elle est utile en tant que placeholder ou
 * comme valeur par défaut pour les props/callback car sa référence est stable
 */
export const noop = () => {};

/**
 * Execute une fonction et retourne une valeur de fallback en cas d'erreur.
 * Utile dans les cas où une erreur non actionable n'est pas critique.
 * ```tsx
 * const cssSelector = '<valeur non controlée>';
 * const node = swallow(() => document.querySelector(cssSelector));
 */
export const swallow = <T>(fn: () => T, fallback: T | null = null) => {
  try {
    return fn();
  } catch {
    return fallback;
  }
};
