export default {
  'by': 'par',
  'batch-discount-label': {
    'value': 'le %{nthQuantity}ème à %{discount}',
    'free': 'le %{nthQuantity}ème offert',
    'percent': 'le %{nthQuantity}ème à -%{discount}%',
    'savings': '%{savings} économisé',
    'savings_plural': '%{savings} économisés',
    'one-out-of': '%{quantity}/%{goal}',
    'for-reward': 'pour',
    'discount-applied': 'Offre appliquée 🎉',
  },
};
