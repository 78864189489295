import type { StripeError } from '@stripe/stripe-js';
import colors, { Color } from 'src/common/themes/main/colors';

import NetworkError from '~/src/common/services/error-handler/NetworkError';
import type { ApiEcomError } from '~/src/queries/services/client';

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isArrayOfString = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every(isString);
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number' && Number.isFinite(value);
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return value != null && typeof value === 'object' && !Array.isArray(value);
};

export const isFunction = (value: unknown): value is (...args: unknown[]) => unknown => {
  return typeof value === 'function';
};
export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

export const isTruthy = <T>(value: T | 0 | '' | null | false | undefined): value is T => {
  return Boolean(value);
};

export const isBaseError = (v: unknown): v is Error => {
  return v instanceof Error;
};

export const isApiEcomError = (v: unknown): v is ApiEcomError =>
  isObject(v) &&
  isString(v.code) &&
  isString(v.message) &&
  isNumber(v.statusCode) &&
  // Le `!(owner in v)` est pour la compatibilité et devra
  // être supprimé une fois que le backend aura été mis à jour.
  ((isString(v.owner) && v.owner === 'Keplr') || !('owner' in v));

export const isNotFound = (v: unknown): v is ApiEcomError & { statusCode: 404 } => {
  return isApiEcomError(v) && v.statusCode === 404;
};

export const isStripeError = (v: unknown): v is StripeError =>
  isObject(v) && (isObject(v.payment_method) || isObject(v.payment_intent));

export const isPaypalError = (v: unknown): v is { message: string } =>
  isObject(v) && isString(v.message) && v.message.includes('paypal.com');

export const isNetworkError = (value: unknown): value is NetworkError =>
  isObject(value) && isString(value.message) && value instanceof NetworkError;

export const isColor = (value: unknown): value is Color => {
  return isString(value) && isString(colors[value as Color]);
};
