import { useState } from 'react';
import type { MouseEvent, Dispatch, SetStateAction } from 'react';

import { RemoveScroll } from 'react-remove-scroll';

import Button from '~/src/common/components/Button';
import useScreenInfos from '~/src/common/hooks/use-screen-infos';
import { useScreenSize } from '~/src/common/hooks/use-screen-size';
import I18n from '~/src/common/services/I18n';

import Input from './Input/Input';
import * as S from './layout';
import Output from './Output/Output';

type Props = {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const SearchBar = ({ active, setActive }: Props) => {
  const { isLargeScreen } = useScreenSize();

  // Gestion du state de la recherche
  const [query, setQuery] = useState('');

  // Hauteur max du contenu de la recherche = screen height
  const maxHeight = useScreenInfos().viewport?.height;

  // Fermeture de la recherche si clic en dehors du contenu
  const handleContainerClick = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    if (active) setActive(false);
  };
  const handleClose = () => setActive(false);

  const rmScrollStyle = active ? { zIndex: 1 } : undefined;

  return (
    <RemoveScroll enabled={active} style={rmScrollStyle}>
      <S.Container $active={active} onClick={handleContainerClick}>
        <S.Sizer>{I18n.t('search.bar.placeholder.simple')}</S.Sizer>
        <S.Content style={{ maxHeight }}>
          <S.Header>
            {!isLargeScreen ? (
              <S.Title>
                <span>{I18n.t('search.bar.title')}</span>
                <Button icon="cross" onClick={handleClose} iconSize={24} variant="transparent" />
              </S.Title>
            ) : null}
            <Input query={query} active={active} setQuery={setQuery} setActive={setActive} />
          </S.Header>
          <Output query={query} active={active} />
        </S.Content>
      </S.Container>
    </RemoveScroll>
  );
};

export default SearchBar;
