import * as S from './layout';

interface Props {
  children: React.ReactNode;
  isDisplayed: boolean;
}

const DeliveryProgressBanner = ({ children, isDisplayed }: Props) => {
  return (
    <S.DeliveryProgressBannerContainer>
      <S.DeliveryProgressBar $isDisplayed={isDisplayed}>{children}</S.DeliveryProgressBar>
    </S.DeliveryProgressBannerContainer>
  );
};

export default DeliveryProgressBanner;
