import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderID = 'other:separator';
const Component = dynamic(() => import('./Component'));
export default createComponent(BuilderID, Component, inputs, {
  friendlyName: 'Séparateur',
});
