import { keyframes, css } from 'styled-components';

const SVG_LENGTH = 694;

const animation = keyframes`
  from {
    stroke-dashoffset: ${SVG_LENGTH * 2};
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const ANIMATION_STYLE = css`
  width: 100%;

  .animated {
    stroke-dasharray: ${SVG_LENGTH} ${SVG_LENGTH};
    stroke-dashoffset: ${SVG_LENGTH * 2};
    animation: ${animation} 3s linear infinite;
  }
`;
