// From https://react.dev/learn/escape-hatches#reusing-logic-with-custom-hooks
import { useState, useEffect } from 'react';

/**
 * Ce hook est similaire à un debounce, avec quelques changements :
 * - La valeur initial est directement retournée, et vaut la valeur d'entrée
 * - La sortie n'est mise à jour qu'après {delay}ms sans changement de l'entrée
 */
const useDelayedValue = <T>(value: T, delay: number) => {
  const [delayedValue, setDelayedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDelayedValue(value), delay);
    return () => clearTimeout(timer);
  }, [value, delay]);

  return delayedValue;
};

export default useDelayedValue;
