import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { PostApiAuthMe200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { fetchAPI } from '~/src/queries/services/fetchAPI';
import { QUERY_KEYS } from '~/src/screens/App/queries/queries';

type QueryOptions = UseQueryOptions<unknown, unknown, PostApiAuthMe200, []>;
type Options = Pick<QueryOptions, 'onSuccess' | 'onError'>;

export const usePostAuthMeQuery = (options?: Options) =>
  useQuery(
    QUERY_KEYS.user,
    () =>
      fetchAPI<PostApiAuthMe200>({
        url: '/api/auth/me',
        method: 'post',
      }),
    {
      staleTime: 60_000,
      retry: false,
      ...(options || {}),
    },
  );
