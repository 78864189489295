import styled, { css } from 'styled-components';

import ButtonBase from './BaseButton';

export const cssStyle = css`
  color: ${({ theme }) => theme.palette.common.WHITE};
  background: ${({ theme }) => theme.palette.common.PITAYA};
  border-color: ${({ theme }) => theme.palette.common.PITAYA};

  & a {
    border-color: #e25a72;
  }

  &:hover,
  &:active {
    border-color: #e25a72;
    background: #e25a72; /* Couleur uniquement utilisée ici */
    color: ${({ theme }) => theme.palette.common.WHITE}; /* override du hover d'un lien */
  }
`;

const PinkButton = styled(ButtonBase)`
  ${cssStyle}
`;

export default PinkButton;
