import React from 'react';

import { ItemContainer, Title, TitleShort, Subtitle, ItemContentContainer } from './layout';

interface Props {
  title: string;
  titleShort: string;
  subtitle: string;
  icon: React.ReactNode;
}
const ReinsuranceBannerItem = ({ title, titleShort, subtitle, icon }: Props) => (
  <ItemContainer>
    {icon}
    <TitleShort>{titleShort}</TitleShort>
    <ItemContentContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </ItemContentContainer>
  </ItemContainer>
);

export default ReinsuranceBannerItem;
