export default {
  title: 'Un produit indisponible dans votre commande ?',
  subtitle: 'Nous le remplaçons !',
  cases: {
    'similar-product': 'Produit proche uniquement',
    'similar-weight': 'Poids similaire',
    'similar-price': 'Pas de surfacturation',
  },
  explanations: {
    'title': '3 cas de figure',
    'product-replacement': 'Le produit de remplacement est',
    'more-expensive': 'plus cher',
    'cheaper': 'moins cher',
    'no-product-replacement-1': 'Il n’y a',
    'no-product-replacement-2': 'pas de produit de remplacement',
    'consequence-more-expensive': 'La différence est offerte',
    'consequence-cheaper': 'Le prix inférieur est appliqué',
    'consequence-no-replacement': 'Vous n’êtes pas facturé !',
  },
  example: {
    'title': 'Exemple',
    'initial-product': {
      title: 'Ce produit est indisponible',
      name: 'Les aiguillettes de poulet',
      origin: 'Origine France',
      quantity: '6-7 Pièces (300 g)',
      price: '5,09 €',
    },
    'replacement-product': {
      title: 'Nous le remplaçons par',
      name: 'Les Filets de poulet',
      origin: 'Origine France',
      quantity: '2 Pièces (370g)',
      price: '5,55 €',
      invoiced: '5,09 € facturés',
    },
  },
};
