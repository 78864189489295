import { createInputs } from '../../utils';

const allowedColors = ['XLIGHT_GREEN', 'NEGATIVE_LIGHT', 'POSITIVE_LIGHT'];

export default createInputs([
  { name: 'text', type: 'string', required: true },
  { name: 'link', type: 'string', required: true },
  { name: 'image', type: 'file', required: true },
  { name: 'color', type: 'string', required: true, enum: allowedColors },
]);
