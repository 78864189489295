import React, { useEffect, useRef } from 'react';

import { DIALOGS } from './dialogs';
import * as S from './layout';
import { DialogProps } from './types';

const DISPLAY_DURATION = 5000;

const Dialog = ({
  size = 'small',
  variant = 'info',
  onCancel,
  onClose,
  onConfirm,
  isAutoCloseable = false,
  autoCloseableDuration = DISPLAY_DURATION,
  cancelOnClose = true,
  onOpen,
  id,
  ...props
}: DialogProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (onOpen != null) onOpen();
  }, [onOpen]);

  useEffect(() => {
    if (contentRef != null) {
      contentRef.current?.focus();
    }
  }, [contentRef]);

  useEffect(() => {
    if (isAutoCloseable) {
      const timeoutId = setTimeout(onClose, autoCloseableDuration);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return undefined;
  }, [onClose, isAutoCloseable, autoCloseableDuration]);

  const handleCancel = async () => {
    await onCancel?.();

    onClose();
  };

  const handleConfirm = async () => {
    await onConfirm?.();

    onClose();
  };

  const DialogVariant = DIALOGS[variant];

  return (
    <S.Modal
      id={id}
      size={size}
      onClose={cancelOnClose ? handleCancel : onClose}
      variant={variant === 'error' ? variant : 'basic'}>
      <S.Content ref={contentRef} tabIndex={-1}>
        <DialogVariant
          {...props}
          onConfirm={onConfirm != null ? handleConfirm : undefined}
          onCancel={handleCancel}
        />
      </S.Content>
    </S.Modal>
  );
};

export default Dialog;
