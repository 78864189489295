export default {
  title: 'Mes adresses de livraison',
  form: {
    'address-title': {
      label: "Titre de l'adresse",
      placeholder: 'Exemple : Maison',
    },
    'address': {
      label: 'Adresse',
      placeholder: 'Votre adresse de livraison',
    },
    'address-note': {
      label: "Complément d'adresse",
      placeholder: 'Ex : digicode 1234, 3ème étage, 2ème porte à droite',
    },
    'lastname': { label: 'Nom*', placeholder: 'Nomdefamille' },
    'firstname': { label: 'Prénom*', placeholder: 'Mathieu' },
    'phone': { label: 'Téléphone*', placeholder: '' },
    'submit-add-button': 'Ajouter une adresse de livraison',
    'submit-edit-button': 'Enregistrer les modifications',
  },
};
