export default {
  'ingredients-title': 'Ingrédients',
  'no-ingredient': 'Aucun ingrédient nécessaire',
  'steps-title': 'Préparation',
  'preparation-time': 'Préparation',
  'duration-time': '%{duration} min',
  'required-gears': 'Matériel nécessaire',
  'cooking-time': 'Cuisson',
  'add-products': 'Acheter les ingrédients',
  'servings': 'Personnes',
  'products': 'Produits',
};
