import { MouseEventHandler } from 'react';

import Spinner from '~/src/common/components/Spinner';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import ConfettisLayout from '~/src/screens/Home/InProgressOrdersBanner/ConfettisLayout';

import * as S from './layout';

interface Props {
  count: number;
  isJaugeProgressionDisplayed: boolean;
  isNextOrderDelivery: boolean;
}

const InProgressOrdersBanner = ({
  count,
  isNextOrderDelivery,
  isJaugeProgressionDisplayed,
}: Props) => {
  const modal = useNiceModal('delivery-tracking-drawer');

  const handleBannerClick: MouseEventHandler<HTMLAnchorElement> = event => {
    Tracker.sendEvent('click several orders tracking panel');

    if (isNextOrderDelivery) {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      modal.show();
    }
  };

  return (
    <S.Container $isJaugeProgressionDisplayed={isJaugeProgressionDisplayed}>
      <S.StyledLink href={{ pathname: '/account/orders' }} onClick={handleBannerClick}>
        <S.Content>
          <ConfettisLayout>
            <Spinner>
              <S.SpinnerContent>{count}</S.SpinnerContent>
            </Spinner>
            <S.InProgressOrdersTitle>
              {I18n.t('home.order.several-in-progress', { count })}
            </S.InProgressOrdersTitle>
          </ConfettisLayout>
        </S.Content>
      </S.StyledLink>
    </S.Container>
  );
};

export default InProgressOrdersBanner;
