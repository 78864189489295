import React from 'react';

import { translate, setLocale, setTranslations, TranslateProps, Replacements } from 'react-i18nify';

import { isNumber } from '~/src/common/utils/guards';

import fr from './fr';

// Afin de se protéger des attaques XSS, on va escape les valeurs des placeholders
const escapeLetter = (letter: string) => letter && `&#${letter.charCodeAt(0)};`;
const escapeString = (string: string) => string.replace(/[<'&">]/g, escapeLetter);
const escapeRecord = (record: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(record).map(([k, v]) => {
      return [k, isNumber(v) ? v : escapeString(String(v))];
    }),
  );

class I18nService {
  constructor() {
    // FIXME valeur par défaut côté SSR
    const locale = typeof navigator === 'undefined' ? ['fr'] : navigator.language.split('-');

    if (locale[0] !== 'en' && locale[0] !== 'fr') {
      locale[0] = 'en';
    }

    setTranslations({
      fr,
    });

    setLocale('fr');
  }

  t(key: string, replacements: Replacements = {}) {
    return translate(key, escapeRecord(replacements));
  }

  translate({ value, ...replacements }: TranslateProps) {
    return <span dangerouslySetInnerHTML={{ __html: this.t(value, replacements) }} />;
  }
}

export default new I18nService();
