import styled from 'styled-components';

import { NAV_HEIGHT, media, zIndex } from '~/src/styles-constants';

export const DeliveryProgressBar = styled.div<{ $isDisplayed: boolean }>`
  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  display: ${({ $isDisplayed }) => ($isDisplayed ? 'flex' : 'none')};
  animation: fade-in 0.5s;
  background-color: ${({ theme }) => theme.palette.common.WHITE};
  padding: 8px;
  flex-direction: column;

  ${media.XL`
  padding: 8px;
  position: absolute;
  top: 5px;
  right: 36px;
  width: 343px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;


  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    // Pour pointer exactement sur l'icone du panier du header
    right: 13.25%;
    transform: rotate(180deg);
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid ${({ theme }) => theme.palette.common.WHITE};
    clear: both;
  }
  `};
`;

export const DeliveryProgressBannerContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;

  & > div {
    padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
  }
  z-index: ${zIndex.deliveryProgressBanner};
  width: 100%;

  ${media.XL`
    position: sticky;
    top: ${NAV_HEIGHT}px;
    bottom: auto;
  `};
`;
