import styled, { css } from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

import { CARD_IMAGE_HEIGHT, CARD_TITLE_HEIGHT } from '../constants';

/** L'image a une hauteur fixe et s'adapte à la largeur disponible  */
export const Image = styled.img`
  width: 100%;
  height: ${CARD_IMAGE_HEIGHT}px;
  display: block;
  object-fit: cover;
`;

/**
 * Le titre de la famille, centré et ellipsé si nécessaire
 * Couleurs héritées de `Content` et surchargés depuis `Container`
 */
export const Title = styled.span`
  display: block;
  overflow: hidden;
  height: ${CARD_TITLE_HEIGHT}px;

  /* Le padding devrait être [4px 8px] mais l'alignement vertical de la police sur le web
   * est différent du rendu figma. Pour garder un centrage correct, le padding est ajusté
   * cf https://github.com/keplr-team/mon-marche/pull/2699#discussion_r1660567521 */
  padding: 3px 8px 5px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.quaternary};

  color: inherit;
  background: inherit;
  transition: all 0.2s ease;
`;

/**
 * Nécessaire pour mettre en place un border-radius sur `Image` et `Title`
 * Sans ce wrapper supplémentaire, la flèche sera coupé par l'overflow
 * Couleurs hérités par `Title`. Surchargeable dans `FamilyCard`
 */
export const Content = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  ${({ theme }) => css`
    color: ${theme.palette.common.TEXTS};
    background: ${theme.palette.common.BACKGROUND_1};
  `}
`;

/** Défini les différentes surcharges d'affichage en fonction de l'état */
export const Container = styled.div`
  cursor: pointer;
  position: relative;

  /* Hover: Surcharge Title */
  &&:hover {
    ${({ theme }) => css`
      ${Title} {
        color: ${theme.palette.common.WHITE};
        background: ${theme.palette.common.PRIMARY_HOVER};
      }
    `}
  }

  /* Selected: Surcharge Title */
  [aria-selected='true'] > & {
    ${({ theme }) => css`
      ${Title} {
        color: ${theme.palette.common.WHITE};
        background: ${theme.palette.common.PRIMARY};
      }
    `}
  }

  /* Mobile : Pas de fond ni d'image */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    pointer-events: none;

    ${Content} {
      background: none !important;
    }
    ${Image} {
      display: none;
    }
    ${Title} {
      padding: 0;
      font-size: 20px;
      text-align: left;
      ${({ theme }) => css`
        color: ${theme.palette.common.TEXTS} !important;
        background: ${theme.palette.common.TRANSPARENT} !important;
      `}
    }
  }
`;
