import Script from 'next/script';

import constants from '~/src/env-constants';

const GoogleTagManager = () => (
  <>
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${constants.GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
    <Script id="script-google-tag-manager" strategy="afterInteractive">
      {`
var dataLayer = dataLayer || [];
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://srv.mon-marche.fr/5mplemtng6aaq93.js?aw='+i.replace(/^GTM-/, '')+dl+'&gtm_auth=${constants.GTM_AUTH}&gtm_preview=${constants.GTM_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${constants.GTM_ID}');
      `}
    </Script>
  </>
);

export default GoogleTagManager;
