export default {
  'title': 'Recherche',
  'placeholder': 'De quoi avez-vous envie ?',
  'no-result': 'Aucun résultat pour « %{q} »',
  'searching': 'Recherche en cours pour « %{q} »',
  'results': '%{count} résultat trouvé',
  'results_plural': '%{count} résultats trouvés',
  'reset': 'Vider la recherche',
  'no-result-form': {
    'title': '0 résultat trouvé',
    'text-1': 'Nous avons cherché dans tous nos étals, aucun résultat pour <span>“%{text}”</span>',
    'text-2': 'Vérifiez l’orthographe ou essayez avec une autre recherche',
    'proposal-title': 'Vous cherchez un produit en particulier ?',
    'proposal-sent': 'Merci,<br/>votre demande a été transmise à nos équipes',
    'submit-aria-label': 'Envoyer une suggestion',
    'button-text': 'Dites le nous',
    'button-text-sent': 'Faire une autre suggestion',
    'input-aria-label': 'Suggestion de produit',
  },
  'additional-products': {
    title: 'Envie d’autre chose ?',
  },
  'header': {
    products: 'Produits (%{count})',
    recipes: 'Recettes (%{count})',
  },
};
