export const ENHANCED_ECOMMERCE_KEYS = [
  'click',
  'detail',
  'checkout',
  'purchase',
  'remove',
  'add',
  'refund',
] as const;

export const IDS = {
  searchResults: 'Search Results',
  lists: 'Lists',
  basket: 'Basket',
  productPage: 'Product Page',
  listingPage: 'Listing Page',
  promotions: 'Promotions',
  checkout: 'Checkout',
  xSellCheckout: 'checkout cross sell',
  xSellMain: 'main product cross sell',
  headerMarketing: 'header marketing',
};

// Pour conversion GTM -> Amplitude
export const SOURCE_GTM2AMP = {
  [IDS.checkout]: 'checkout main product',
  [IDS.basket]: 'products list',
  [IDS.searchResults]: 'search results',
  [IDS.xSellCheckout]: 'checkout cross sell',
  [IDS.xSellMain]: 'main product cross sell',
  [IDS.headerMarketing]: 'header marketing',
};
