import styled, { css, keyframes } from 'styled-components';

import Button from '~/src/common/components/Button';
import { ButtonContent, InnerButton } from '~/src/common/components/Button/layout';
import { media } from '~/src/styles-constants';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ContentWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const ProgressBarContainer = styled.div`
  height: 4px;
  background-color: ${({ theme }) => theme.palette.common.BLACK_15};
  border-radius: 50px;
  margin: 8px 10px 0;
`;

export const Container = styled.div<{ $hasImage: boolean }>`
  padding: 8px 24px;
  background-color: ${({ theme }) => theme.palette.common.WHITE};
  box-shadow: 0 0 4px 0 ${({ theme }) => theme.palette.common.BLACK_30};
  border-radius: ${({ theme }) => theme.layout.borderRadius};

  ${({ $hasImage }) =>
    $hasImage
      ? css`
          display: flex;
          flex-direction: row;
          padding: 8px;

          ${Content} {
            flex: 1;
          }

          ${ProgressBarContainer} {
            margin: 0;
          }
        `
      : ''};
`;

export const Message = styled.div`
  font-weight: 400;
  max-width: 210px;

  &,
  & * {
    font-size: 12px;
    line-height: 16px;
  }

  > span > span {
    font-weight: 500;
    display: block;

    ${media.XL`
      display: inline;
    `}
  }

  ${media.XL`
    max-width: 330px;
  `}
`;

export const ActionButton = styled(Button)`
  > div > span {
    font-size: 12px;
  }

  ${InnerButton} > ${ButtonContent} {
    padding: 0 24px;
  }
`;

export const ProgressBar = styled.div`
  height: 4px;
  animation: ${keyframes`
        0% { width: 0; opacity: 1}
        70% { opacity: 1 }
        100% { width: 100%; opacity: 1 }
        `} 5s ease-in-out;
  background-color: ${({ theme }) => theme.palette.common.PINK};
  border-radius: 50px;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

export const BatchDiscountMessage = styled.div`
  font-weight: 400;
  margin-top: -8px;

  p {
    display: inline-block;
    font-weight: 500;
    background-color: ${({ theme }) => theme.palette.common.PROMOTION};
    border-radius: 4px;
    padding: 4px 3px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const ToasterTextUpdateDeliverySlot = styled.div`
  & > span:first-child > span {
    font-weight: 500;
  }

  & > p:last-child {
    margin: 0;
    font-weight: 500;
  }
`;
