import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import FamilyItem from '../FamilyItem/FamilyItem';
import type * as T from '../types';

import * as S from './layout';

type Props = {
  families: T.Family[];
};

const FamilyList = ({ families }: Props) => {
  // Gestion de l'élement actif
  const [familyID, setFamilyID] = useState<string | null>(null);
  const setActiveItem = (id: string) => setFamilyID(p => (p === id ? null : id));

  // Pour fermer automatiquement, on surveille les clics sur l'ensemble de la page
  // Les clics à l'intérieur du menu sont ignorés grace au `e.stopPropagation()`
  useEffect(() => {
    const remove = () => setFamilyID(null);
    document.addEventListener('click', remove);
    return () => document.removeEventListener('click', remove);
  }, []);

  // Les clics à l'intérieur du menu seront systématiquement bloqués pour ne pas
  // fermer le menu. Les clics sur des liens <a/> avec href peuvent continuer à
  // se propager afin que le handler global ferme le menu avant la navigation
  const handleClick = (e: MouseEvent) => {
    const target = e.target instanceof Element ? e.target : null;
    const anchor = target?.closest('a[href]');
    if (!anchor) e.stopPropagation();
  };

  return (
    <S.Container>
      <S.Content onClick={handleClick}>
        {families.map(family => (
          <FamilyItem
            key={family.id}
            family={family}
            active={family.id === familyID}
            onClick={() => setActiveItem(family.id)}
          />
        ))}
      </S.Content>
    </S.Container>
  );
};

export default FamilyList;
