export default {
  'signin-form': {
    'title': 'Connexion à mon compte',
    'description': 'Veuillez renseigner vos informations',
    'forgotten-password': 'Mot de passe oublié ?',
    'email': {
      label: 'Identifiant',
      placeholder: 'Adresse email',
    },
    'password': {
      label: 'Mot de passe',
      placeholder: 'Mot de passe',
    },
    'submit': 'Me connecter',
  },
  'forgotten-password-form': {
    'title': 'Réinitialisation de votre mot de passe',
    'description': 'Un email va vous être envoyé afin de réinitialiser votre mot de passe',
    'email': {
      label: 'Identifiant',
      placeholder: 'Adresse email',
    },
    'email-sent':
      "Un email sera envoyé afin de réinitialiser votre mot de passe si nous trouvons un compte correspondant à l'email renseigné",
  },
};
