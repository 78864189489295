import { Fragment, memo } from 'react';

import { highlight } from '~/src/common/utils/text';

type Props = {
  input: string;
  query: string;
};

const Highlight = ({ input, query }: Props) => (
  <>
    {highlight(input, query.trim()).map(({ key, match, text }) => {
      if (match) return <mark key={key}>{text}</mark>;
      return <Fragment key={key}>{text}</Fragment>;
    })}
  </>
);

export default memo(Highlight);
