import React from 'react';

import Icon from '~/src/common/components/Icon';
import I18n from '~/src/common/services/I18n';

import { Container, ContentContainer } from './layout';
import ReinsuranceBannerItem from './ReinsuranceBannerItem';

const ICON_SIZE = 30;

const ReinsuranceBanner = () => (
  <Container>
    <ContentContainer>
      <ReinsuranceBannerItem
        title={I18n.t('footer.reinsurance.ultra-fresh')}
        titleShort={I18n.t('footer.reinsurance.ultra-fresh-short')}
        subtitle={I18n.t('footer.reinsurance.received')}
        icon={<Icon name="supply" size={ICON_SIZE} color="PRIMARY" />}
      />
      <ReinsuranceBannerItem
        title={I18n.t('footer.reinsurance.meticulous-selection')}
        titleShort={I18n.t('footer.reinsurance.meticulous-selection')}
        subtitle={I18n.t('footer.reinsurance.season-variety')}
        icon={<Icon name="selection" size={ICON_SIZE} color="PRIMARY" />}
      />
      <ReinsuranceBannerItem
        title={I18n.t('footer.reinsurance.fair-price')}
        titleShort={I18n.t('footer.reinsurance.fair-price')}
        subtitle={I18n.t('footer.reinsurance.promotions')}
        icon={<Icon name="price" size={ICON_SIZE} color="PRIMARY" />}
      />
      <ReinsuranceBannerItem
        title={I18n.t('footer.reinsurance.delivery')}
        titleShort={I18n.t('footer.reinsurance.delivery')}
        subtitle={I18n.t('footer.reinsurance.bike')}
        icon={<Icon name="Move-delivery" size={ICON_SIZE} color="PRIMARY" />}
      />
    </ContentContainer>
  </Container>
);

export default ReinsuranceBanner;
