/** Supprime les balises html et normalise le retour */
export const sanitize = (html: string) =>
  html
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/\s+/g, ' ')
    .trim();

/** Retourne une version tronquée de la chaine passée en parametre */
export const truncate = (text: string, length: number, ellipsis = '…') => {
  if (text.length <= length) return text;
  const pattern = `^(.{0,${length - ellipsis.length}})\\b.*$`;
  return `${text.replace(new RegExp(pattern), '$1')}${ellipsis}`;
};

/**
 * Échappe les caracteres spéciaux d'une chaine pour pouvoir l'utiliser dans une RegExp
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 */
export const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const formatPhone = (phone = '') => phone.replace(/\D/g, '');

/** Suppression des diacritiques d'une chaîne de caractères */
export const removeDiacritics = (text: string) => text.normalize('NFD').replace(/\p{M}/gu, '');

/** Mise en évidence d'une sous-chaîne dans un texte donné */
export const highlight = (input: string, query: string) => {
  // Récupération de la position de la sous-chaine
  const cleanInput = removeDiacritics(input).toLowerCase();
  const cleanQuery = removeDiacritics(query).toLowerCase();
  const matchIndex = cleanInput.indexOf(cleanQuery);

  // Si pas de match, on retourne directement
  if (matchIndex < 0) return [{ key: 'source', match: false, text: input }];

  // Sinon, on extrait chaque partie
  const prior = input.substring(0, matchIndex);
  const match = input.substring(matchIndex, matchIndex + query.length);
  const after = input.substring(matchIndex + query.length);

  // Puis on renvoi le tout
  return [
    { key: 'prior', match: false, text: prior },
    { key: 'match', match: true, text: match },
    { key: 'after', match: false, text: after },
  ];
};
