type CustomEvent = 'front order prepaid' | 'exit intent';
type CustomEventProperties = Record<string, unknown>;

// Définition du typage de la librairie.
// Idéalement, devrait être fourni par Braze.
type Braze = {
  changeUser: (userId: string) => void;
  requestPushPermission: () => void;
  logCustomEvent: (
    customEventName: CustomEvent,
    eventProperties?: CustomEventProperties,
  ) => boolean;
};

// Ajout de la librairie au type de Window.
// Surcharge locale pour ne pas s'éparpiller.
declare global {
  interface Window {
    braze?: Braze;
  }
}

// Type-guard pour accéder à Braze
const getBraze = (): Braze | never => {
  if (typeof window === 'undefined') throw new Error('Braze should not be used on server side');
  if (window.braze == null) throw new Error('Braze is not available yet');
  return window.braze;
};

// Queue pour le stockage des appels Braze
type Action = (braze: Braze) => void;
const actions: Action[] = [];

// Traitement de la queue, avec auto retry
let timer: ReturnType<typeof setTimeout>;
const process = () => {
  clearTimeout(timer);
  try {
    const braze = getBraze();
    while (actions.length > 0) {
      actions[0](braze);
      actions.shift();
    }
  } catch {
    timer = setTimeout(process, 1000);
  }
};

// Ajout d'une action à la queue
const enqueue = (action: Action) => {
  actions.push(action);
  process();
};

// Export du service
export default {
  changeUser: (userId: string) => enqueue(braze => braze.changeUser(userId)),
  requestPushPermission: () => enqueue(braze => braze.requestPushPermission()),
  logCustomEvent: (customEvent: CustomEvent, eventProperties?: CustomEventProperties) =>
    enqueue(braze => braze.logCustomEvent(customEvent, eventProperties)),
};
