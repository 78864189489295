import { createInputs } from '../../utils';

export default createInputs([
  { name: 'name', type: 'string', required: true },
  { name: 'rating', type: 'number', required: true },
  { name: 'title', type: 'string', required: true },
  { name: 'text', type: 'longText', required: true },
  { name: 'date', type: 'date', required: true },
  { name: 'source', type: 'string', required: true },
]);
