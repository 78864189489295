import { useEffect } from 'react';

import { useRouter } from 'next/router';

import Icon from '~/src/common/components/Icon';
import { useScreenSize } from '~/src/common/hooks/use-screen-size';
import I18n from '~/src/common/services/I18n';
import { useGoBack } from '~/src/common/services/Router';
import { CommonCatalogEventProperties } from '~/src/common/services/Tracker';
import { GetDetailBySlug200BreadcrumbsItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import CatalogBreadcrumbs from './CatalogBreadcrumbs';
import * as S from './layout';

export const IS_MOBILE_CATALOG_CONTENT_CLASS = 'is-mobile-catalog-content';

type Props = {
  name: string;
  children: React.ReactNode;
  categoriesBreadcrumbs: Partial<GetDetailBySlug200BreadcrumbsItem>[];
  analyticsProperties: CommonCatalogEventProperties;
};

const CatalogContentScreenLayout = ({
  name,
  categoriesBreadcrumbs,
  children,
  analyticsProperties,
}: Props) => {
  const router = useRouter();
  const { handleBack } = useGoBack(analyticsProperties);
  const { isLargeScreen } = useScreenSize();

  useEffect(() => {
    if (isLargeScreen) return;

    document.body.classList.add(IS_MOBILE_CATALOG_CONTENT_CLASS);

    router.events.on('routeChangeStart', () => {
      document.body.classList.remove(IS_MOBILE_CATALOG_CONTENT_CLASS);
    });
  }, [router.events, isLargeScreen]);

  return (
    <S.Content>
      <S.NavigationContainer>
        <S.DesktopGoBackButton variant="link" onClick={handleBack}>
          <Icon name="Arrow-back" />
          <S.GoBackSpan>{I18n.t('common.back')}</S.GoBackSpan>
        </S.DesktopGoBackButton>
        <S.DesktopBreadcrumbsContainer>
          <CatalogBreadcrumbs categories={categoriesBreadcrumbs} contentName={name} />
        </S.DesktopBreadcrumbsContainer>
      </S.NavigationContainer>
      {children}
    </S.Content>
  );
};

export default CatalogContentScreenLayout;
