import { OngoingOrder, OrderDetails } from '~/src/typings/orders/types';

type OrderState =
  | 'preparing'
  | 'cart'
  | 'prepaying'
  | 'prepaid'
  | 'prepared'
  | 'readyToDeliver'
  | 'approvedByClient'
  | 'delivered'
  | 'inTransit'
  | 'returning'
  | 'returned'
  | 'cancelled'
  | 'expired'
  | 'paid'
  | 'refunded'
  | 'ready';

export const isOrderBeingDelivered = (orderState: OrderState) => {
  // 'approvedByClient' & 'readyToDeliver' ne sont plus utilisés
  const deliveringStatus = ['approvedByClient', 'inTransit', 'readyToDeliver'];

  return deliveringStatus.includes(orderState);
};

export const isOrderTerminated = (orderState: OrderState) => {
  const terminatedStatus = ['paid', 'delivered', 'expired', 'returned', 'returning', 'refunded'];

  return terminatedStatus.includes(orderState);
};

export const isOrderPrepared = (orderState: OrderState) =>
  isOrderBeingDelivered(orderState) || isOrderTerminated(orderState) || orderState === 'prepared';

export const isOrderCancelled = (orderState: OrderState) => orderState === 'cancelled';

export const isOrderAcceptedByClient = (orderState: OrderState) => {
  const acceptedByClientStatus = ['cart', 'prepaid', 'prepaying'];

  return acceptedByClientStatus.includes(orderState);
};

export const isOrderBeingPrepared = (orderState: OrderState, isRemotePickup: boolean) => {
  const beingPreparedStatus = ['prepared', 'preparing'];

  return beingPreparedStatus.includes(orderState) || (orderState === 'inTransit' && isRemotePickup);
};

export const isOrderReady = (orderState: OrderState, isRemotePickup: boolean) => {
  return (orderState === 'inTransit' && !isRemotePickup) || orderState === 'ready';
};

export const isOrderPaid = (orderState: OrderState) => orderState === 'paid';

export const isOrderUpdatable = (order: OngoingOrder | OrderDetails) =>
  order.state === 'prepaid' && order.delivery.timeSlot.orderUntil > Date.now();
