/**
 * Approximative letter size
 */
const LETTER_SIZE = 10.5;

/**
 * It return the highest length between confirm & cancel button text
 * @param confirmText Text of the confirm button
 * @param cancelText Text of the cancel button
 */
export const computeChildrenWidth = (confirmText: string, cancelText: string) => {
  const cancelTextLength = cancelText.length;
  const confirmTextLength = confirmText.length;

  return Math.max(cancelTextLength, confirmTextLength) * LETTER_SIZE;
};
