import { ImageParams } from '~/src/common/typings/image';
import { getImageUrl } from '~/src/common/utils/cloudinary';

import type * as T from '../types';

import * as S from './layout';

type Props = {
  family: T.Family;
  onClick: () => void;
};

const FamilyCard = ({ family, onClick }: Props) => {
  const { images, colors, name } = family;
  const style = { color: colors.fg, background: colors.bg };
  const image = getImageUrl(images.header, ImageParams.familyHeader);

  return (
    <S.Container onClick={onClick}>
      <S.Content style={style}>
        <S.Image src={image} alt={name} />
        <S.Title>{name}</S.Title>
      </S.Content>
    </S.Container>
  );
};

export default FamilyCard;
