export default {
  'brand': 'mon marché',
  'no-address': 'On vous livre où ?',
  'choose-slot': 'Votre créneau ?',
  'choose-slot-aria-label': 'Votre créneau ?',
  'go-delivery': 'livrable',
  'search-button': 'De quoi avez-vous envie ?',
  'menu': {
    'home': 'Accueil',
    'search': 'Rechercher un produit ou une recette',
    'recipes': 'Nos recettes',
    'favorites': 'Mes favoris',
    'profile': 'Mon compte',
    'cart': 'Mon panier',
    'our-stalls': 'Nos étals',
    'my-products': 'Mes produits',
  },
};
