export default {
  'title': 'Mes informations',
  'form': {
    'firstname': { label: 'Prénom*', placeholder: 'Mathieu' },
    'name': { label: 'Nom*', placeholder: 'Nomdefamille' },
    'email': {
      label: 'Email*',
      placeholder: 'ex: mathieu@email.com',
    },
    'phone': { label: 'Téléphone*', placeholder: '' },
    'submit-button': 'Modifier mes informations',
  },
  'toast-success-message': 'Vos informations ont bien été sauvegardées',
};
