import styled from 'styled-components';

import Link from '~/src/common/components/Link';
import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

/** Container global */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0 16px;

  /* Hors desktop: Pas de padding */
  @media ${getMediaQuery(0, breakpoints.XL)} {
    padding: 0;
  }
`;

/** Titre de la section */
export const Title = styled.h4`
  margin: 0;
  padding: 0;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.TEXTS};
`;

/** Liste des mots clés */
export const Entries = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
`;

/** Lien pour un mot clé */
export const Entry = styled(Link)`
  padding: 4px 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.common.XLIGHT_GREEN};

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
`;
