export default {
  steps: {
    'manual-map': {
      'title': 'Votre adresse',
      'street-number': 'N°',
      'street-number-placeholder': '432',
      'street': 'Voie',
      'street-placeholder': 'nom de la voie',
      'map-value-error': 'Les coordonnées de votre position sont erronnées',
      'map-instructions': {
        'click-n-cursor-position': 'Soyons sûrs de vous trouver,',
        'find-you': 'placez le curseur sur la carte',
      },
      'validate': 'Valider',
    },
    'manual-postal-code': {
      title: 'Saisissez votre code postal',
    },
    'manual-street': {
      title: 'Saisissez votre adresse',
      confirm: 'Valider',
    },
    'unknown-address': {
      'title': 'Votre adresse',
      'our-current-delivery-zone': 'Notre zone de livraison actuelle',
      'fill-another-address': 'Indiquer une autre adresse',
      'map-delivery-zone': 'Carte zone de livraison',
    },
    'back': 'Retour',
  },
};
