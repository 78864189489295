// eslint-disable-next-line no-restricted-imports -- On a besoin de l'import pour créer le wrapper
import { useMedia } from 'react-use';

import { useUserAgent } from '~/src/common/services/UserAgent';
import { breakpoints } from '~/src/styles-constants';

/**
 * Cette fonction permet de déterminer la taille de l'écran.
 * Les media-queries n'étant pas disponible coté serveur, une
 * valeur de fallback est générée en se basant sur l'user-agent.
 * La solution est loin d'être parfaite, mais devrait couvrir la majorité des cas
 */
export const useScreenSize = () => {
  // On utilise l'UA pour définir le type de device
  const { device } = useUserAgent() ?? {};
  const isTablet = device?.type === 'tablet';
  const isMobile = device?.type === 'mobile';
  const isDesktop = device?.type === 'desktop' || device?.type == null;
  // Ces valeurs servent de fallback coté serveur pour les media queries
  const isLargeScreen = useMedia(`(min-width: ${breakpoints.XL}px)`, isDesktop);
  const isSmallScreen = useMedia(`(max-width: ${breakpoints.M}px)`, isMobile || isTablet);
  const isXSmallScreen = useMedia(`(max-width: ${breakpoints.S}px)`, isMobile);
  return { isLargeScreen, isSmallScreen, isXSmallScreen };
};
