import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { PageTypes } from '../constants';
import Tracker from '../Tracker';
import { getEventBrowseValues } from '../utils';

const useAmplitudePageTracking = () => {
  const { route, query, asPath } = useRouter();

  // Mise à jour des users properties
  useEffect(() => {
    const pageType = PageTypes[route];
    if (pageType == null) return;
    Tracker.setUserProperties({
      'actual page viewed path': asPath,
      'actual page viewed type': pageType,
    });
  }, [route, asPath]);

  // Events de navigation "XXX page viewed"
  useEffect(() => {
    const url = asPath.split('?')[0];
    const data = getEventBrowseValues(url, query);
    if (data) Tracker.sendEvent(data.eventName, data.eventProperties);
  }, [asPath, query]);
};

export default useAmplitudePageTracking;
