import styled from 'styled-components';

import { media } from '~/src/styles-constants';

const ButtonsGroup = styled.div<{ $childrenWidth: number; inline?: boolean }>`
  display: flex;
  flex-flow: ${({ inline = true }) => (inline ? 'row' : 'column')} wrap-reverse;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  width: 100%;

  && > * {
    min-width: 150px;
    width: 100%;
  }

  && > *:first-child {
    margin-top: 10px;
  }

  ${media.M<{ $childrenWidth: number }>`
    && > * {
      max-width: ${({ $childrenWidth }) => $childrenWidth}px;
      margin: 10px;
    }
  `}
`;

export default ButtonsGroup;
