import Sticker from '~/src/common/components/Sticker';
import { ImageParams } from '~/src/common/typings/image';
import { getImageUrl } from '~/src/common/utils/cloudinary';

import { useNavigationTracking } from '../tracking';
import type * as T from '../types';

import * as S from './layout';

type Props = {
  family: T.Family;
};

const MiniMenu = ({ family }: Props) => {
  const { categories } = family;
  const { onClickCategoryName } = useNavigationTracking().small;

  return (
    <S.Container>
      {categories.map(({ id, name, slug, label, image }) => (
        <S.Item key={id} href={`/categorie/${slug}`} onClick={() => onClickCategoryName(id)}>
          <S.Image src={getImageUrl(image, ImageParams.familyCategory)} alt={name} />
          <S.Name>{name}</S.Name>
          {label && (
            <Sticker bg={label.bg} fg={label.fg}>
              {label.text}
            </Sticker>
          )}
          <S.Arrow name="Chevron-right" />
        </S.Item>
      ))}
    </S.Container>
  );
};

export default MiniMenu;
