export default {
  bar: {
    title: 'Recherche',
    placeholder: {
      simple: 'Rechercher un produit ou une recette',
      active: 'De quoi avez-vous envie ?',
    },
    popular: {
      title: 'Recherches populaires',
    },
  },
  results: {
    page: {
      title: 'Résultats de recherche',
    },
    header: {
      title: 'Résultats pour',
      tab: {
        products: 'Produits (%{count})',
        recipes: 'Recettes (%{count})',
      },
    },
    status: {
      empty: {
        line1: 'Nous avons cherché dans tous nos étals, aucun résultat pour ',
        line2: 'Vérifiez l’orthographe ou essayez avec une autre recherche',
      },
      error: {
        line1: 'Une erreur est survenue lors de votre recherche pour ',
        line2: 'Vous pouvez tenter de relancer la recherche via la bouton ci-dessous',
        retry: 'Réessayer',
      },
    },
  },
};
