import React from 'react';

import { IconNames } from '../../Icon';
import { BaseLoader } from '../../Loader';
import {
  Button,
  InnerButton,
  LoaderContainer,
  ButtonContent,
  Icon,
  LoaderInnerContainer,
} from '../layout';

type Props = React.ComponentProps<typeof Button> & {
  icon?: IconNames;
  rightIcon?: IconNames;
  children?: React.ReactNode;
  loading?: boolean;
  iconSize?: number;
  label?: string;
};

const ButtonBase = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      icon,
      iconSize = 26,
      rightIcon,
      children,
      loading = false,

      disabled = false,

      type = 'button',
      label = '',
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <Button
        aria-label={label}
        {...props}
        ref={ref}
        type={type}
        disabled={disabled || loading}
        $isLoading={loading}>
        {/* FF bug : https://bugzilla.mozilla.org/show_bug.cgi?id=984869 */}
        <InnerButton>
          {icon != null && !loading ? (
            <Icon name={icon} role="img" aria-label={icon} size={iconSize} $isLoading={loading} />
          ) : null}
          {children != null ? <ButtonContent $isLoading={loading}>{children}</ButtonContent> : null}
          {rightIcon != null ? (
            <Icon
              name={rightIcon}
              role="img"
              aria-label={rightIcon}
              size={iconSize}
              $isLoading={loading}
            />
          ) : null}
          {loading ? (
            <LoaderContainer>
              <LoaderInnerContainer>
                <BaseLoader />
              </LoaderInnerContainer>
            </LoaderContainer>
          ) : null}
        </InnerButton>
      </Button>
    );
  },
);

export default ButtonBase;
