import styled from 'styled-components';

import BaseIcon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';

/** Container global */
export const Container = styled.div`
  padding-bottom: 16px;
`;

/** Liste des suggestions */
export const Entries = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
`;

/** Lien pour une suggestion */
export const Entry = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  padding: 0 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.BACKGROUND_3};

  &:hover,
  &[aria-selected='true'] {
    background: ${({ theme }) => theme.palette.common.XLIGHT_GREEN};
  }
`;

/** Icône des liens de suggestions */
export const Icon = styled(BaseIcon)`
  flex: 0 0 auto;
`;

/** Texte + surcharge pour mise en évidence */
export const Text = styled.span`
  flex: 1 1 auto;

  > mark {
    font-weight: 400;
    background: none;
    color: ${({ theme }) => theme.palette.common.TEXTS};
  }
`;
