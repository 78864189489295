export default {
  'title': 'Confirmation de votre commande',
  'thank-you': 'Merci %{firstname} !',
  'order-confirmation-1':
    'Votre commande a bien été prise en compte. Vous allez recevoir un email récapitulatif.',
  'order-confirmation-2':
    "Votre commande reste modifiable jusqu'à ce que nous entamions sa préparation.",
  'order-bye': 'À bientôt !',
  'order-number': 'Commande Nº %{orderId}',
  'save-as-list': 'Enregistrer ma commande en tant que liste de courses',
  'advent-calendar-2022':
    "Jusqu'au <span>23 décembre</span>, de <span>nombreux lots à gagner</span> tous les jours<br>bons d'achat, produits festifs, repas du réveillon...",
  'advent-calendar-2022-mobile':
    "Jusqu'au <span>23 décembre</span>,<br>de <span>nombreux lots à gagner</span> tous les jours<br>bons d'achat, produits festifs, repas du réveillon...",
  'advent-calendar-2022-title': 'Calendrier du goût 2022',
  'advent-calendar-2022-cta': 'Je tente ma chance',
};
