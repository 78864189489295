export default {
  'title': 'Produits modifiés',
  'updated-products-label': '%{count} produit de votre panier a été modifié',
  'updated-products-label_plural': '%{count} produits de votre panier ont été modifiés',
  'explanation': 'Au rythme de nos arrivages, les caractéristiques de nos produits évoluent.',
  'update-label': '%{count} modification',
  'update-label_plural': '%{count} modifications',
  'price-label': 'Prix',
  'trading-name-label': 'Nom commercial',
};
