import type { ComponentType } from 'react';

import { RegisteredComponent } from '@builder.io/sdk-react';
import { BuilderContextInterface } from '@builder.io/sdk-react/types/context/types';
import { css } from 'styled-components';

import type { Input, PropsFromInputs } from './types';
import { withBuilderFence } from './withBuilderFence';

export const createInputs = <const T extends Input>(inputs: T[]) => inputs;

export const createComponent = <T extends Input[] & { builderContext?: BuilderContextInterface }>(
  name: string,
  Component: ComponentType<PropsFromInputs<T>>,
  inputs: T,
  details?: Omit<RegisteredComponent, 'component' | 'name' | 'inputs'>,
): RegisteredComponent => {
  const component = withBuilderFence(Component, name);
  // Pour aider l'inférence, `inputs` est typé comme étant readonly, mais
  // le type `RegisteredComponent` attend une variable qui ne le soit pas.
  // Le readonly vient de l'utilisation de `as const` et ne correspond pas
  // à la réalité, l'objet est bien mutable. D'où la ligne suivante :
  // @ts-expect-error -- `inputs` n'est pas réellement readonly
  return { component, name, inputs, ...details };
};

// Génération de CSS commun pour l'ensemble des sections CMS
// - `width`: la largeur de la section, par défaut 100%
// - `margin`: active les marges droites et gauche, true par défaut
type StyleConfig = { width?: number; margin?: boolean };
export const sectionStyle = ({ width, margin = true }: StyleConfig) => {
  const mValue = margin ? 16 : 0; // Taille des marges
  const wValue = width && width + 2 * mValue; // Largeur demandée
  const mwValue = wValue ? `min(100%, ${wValue}px)` : '100%'; // Largeur maximale

  return css`
    width: 100%;
    display: flex;
    align-self: center;
    margin: 0 ${mValue}px;
    max-width: calc(${mwValue} - 2 * ${mValue}px);
  `;
};
