export const QUERY_KEYS = {
  featureFlags: ['featureFlags'],
  user: ['/api/auth/me'],
};

export const MUTATION_KEYS = {
  updateCartQuantity: ['update/cart/quantity'],
  createCoupons: ['create/coupons'],
  updateCoupons: ['update/coupons'],
};
