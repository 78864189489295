import styled from 'styled-components';

import { media } from '~/src/styles-constants';

const REINSURANCE_BANNER_HEIGHT = 78;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.common.BACKGROUND_1};
  padding: 0 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: ${REINSURANCE_BANNER_HEIGHT}px;
  width: 100%;
  max-width: 1085px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  ${media.XL`
  justify-content: center;
  `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  ${media.XL`
    flex-direction: row;
    width: 100%;
    justify-content: center;
  `}
`;

export const ItemContentContainer = styled.div`
  display: none;

  ${media.XL`
    display: block;
    flex-direction: column;
    margin-left: 10px;
  `}
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 9px;
  font-weight: bold;
  margin: 0;

  ${media.XL`
    font-size: 16px;
  `}
`;

export const TitleShort = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 9px;
  font-weight: bold;
  display: block;

  ${media.XL`
    display: none;
  `}
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 12px;
`;
