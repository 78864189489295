import styled from 'styled-components';

import Button from '~/src/common/components/Button';
import Icon from '~/src/common/components/Icon';
import { getMediaQuery } from '~/src/common/utils/style';
import { media } from '~/src/styles-constants';

export const DeliveryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  border-radius: 8px;
  overflow: hidden;
`;

export const DeliveryInfoTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;

  @media ${getMediaQuery(420, null)} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const DeliveryInfoText = styled.p`
  margin: 4px 0;
  font-family: inherit;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.palette.common.WHITE};

  @media ${getMediaQuery(420, null)} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const DeliveryInfoIcon = styled(Icon)`
  font-size: 30px;

  ${media.M`
    font-size: 41px;
  `}
`;

export const DeliveryInfoButton = styled(Button)`
  height: initial;
  width: 100%;

  span {
    padding: 2px !important;
    font-size: 11px;
    line-height: 16px;
  }

  @media ${getMediaQuery(420, null)} {
    padding: 8px;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const LaterButton = styled(Button)`
  height: auto;
  max-height: auto;
  color: ${({ theme }) => theme.palette.common.WHITE};
  font-weight: 400;

  span {
    font-size: 10px;
    line-height: 16px;
  }

  span:hover {
    color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
  }

  ${media.M`
    span {
      font-size: 12px;
    }
  `}
`;
