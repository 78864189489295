import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import colors from '~/src/common/themes/main/colors';
import { getMediaQuery } from '~/src/common/utils/style';

// Font size
const FONT_SIZE = 1;

// Line height

const Main = () => {
  const theme = useContext(ThemeContext);

  const fontsList = `${
    theme?.fonts.primary || ''
  }, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sa`;

  return (
    <style jsx global>{`
      #__next {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      #__next ~ img {
        position: absolute;
        top: -100px;
      }

      *,
      *::before,
      *::after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
      }

      html,
      body,
      #root {
        height: 100%;
        margin: 0;
        padding: env(safe-area-inset-top, 0px) 0 env(safe-area-inset-bottom, 0px) 0;
        font-family: ${fontsList};
        color: ${theme?.palette.text || 'black'};
      }

      body {
        background-color: white;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      body.modal-opened {
        overflow: hidden !important;
        position: fixed !important;
      }

      [type='reset'],
      [type='submit'] {
        appearance: none;
        outline: 0;
        cursor: pointer;
      }

      input::placeholder {
        color: ${theme?.palette.common.DISABLED || 'grey'};
        font-family: ${fontsList};
        font-size: ${FONT_SIZE}rem;
      }
      input::-ms-clear {
        width: 0;
        height: 0;
      }

      button {
        cursor: pointer;
        color: inherit;
      }
      button:disabled {
        cursor: not-allowed;
      }

      /* Get rid of gap under images by making them display: inline-block; by default */
      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }

      /* Remove Svg outline */
      svg {
        outline: 0;
      }

      /* Link */

      a {
        line-height: inherit;
        tap-highlight-color: transparent;
        text-decoration: none;
        color: ${theme?.typography.a.color || colors.PRIMARY};
      }

      a:hover,
      a:focus {
        text-decoration: none;
        color: ${theme?.typography.a.colorHover || colors.PRIMARY_HOVER};
      }

      a img {
        border: 0;
      }

      /*  Code */

      pre,
      code {
        font-family: Consolas, Monaco, 'Lucida Console', monospace;
      }

      b {
        font-weight: bold;
      }

      /* Popover */

      .Popover {
        z-index: 50;
      }
      .Popover-body {
        display: inline-flex;
        flex-direction: column;
        background: ${theme?.palette.background || colors.WHITE};
        box-shadow: 0 2px 5px 0 rgba(45, 62, 79, 0.45);
        border-radius: 0.3rem;
      }

      .Popover-tipShape {
        fill: ${theme?.palette.background || colors.WHITE};
      }

      /* Image with 'data-src' attribute is hidden */
      img[data-src] {
        opacity: 0;
      }
      /* Animate loaded image */
      img.loaded {
        -webkit-transition: opacity 0.5s linear 0.2s;
        -moz-transition: opacity 0.5s linear 0.2s;
        transition: opacity 0.5s linear 0.2s;
        opacity: 1;
      }
      @keyframes bounce-in {
        0% {
          transform: scale(0, 0) translateY(0px);
          opacity: 0;
        }
        10% {
          transform: scale(0.1) translateY(-5px);
          opacity: 0.5;
        }
        18% {
          transform: scale(0.95, 1.05) translateY(-7px);
          opacity: 0.7;
        }
        40% {
          transform: scale(1.1, 0.9) translateY(0px);
          opacity: 0.9;
        }
        50% {
          transform: scale(1, 1) translateY(-1px);
          opacity: 1;
        }
        75% {
          transform: scale(1, 1) translateY(0px);
          opacity: 1;
        }
        100% {
          transform: scale(1, 1) translateY(0px);
          opacity: 1;
        }
      }

      /* Focus style */
      *:focus,
      *:focus-within {
        outline: none;
      }

      /* For Appsflyer Smart Banner */
      .responsive-wrapper div {
        font-size: 12px;
      }

      @media only ${getMediaQuery(300, 360)} {
        .responsive-wrapper div {
          font-size: 9px;
        }
      }

      @media only ${getMediaQuery(360, 390)} {
        .responsive-wrapper div {
          font-size: 10px;
        }
      }

      @media only ${getMediaQuery(375, null)} {
        .responsive-wrapper div {
          font-size: 12px;
        }
      }
    `}</style>
  );
};

export default Main;
