import { ComponentProps, forwardRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import NextLink, { type LinkProps } from 'next/link';

type Props = LinkProps & Omit<ComponentProps<'a'>, keyof LinkProps>;

const Link = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  return <NextLink prefetch={false} {...props} ref={ref} />;
});

export default Link;
