import { useEffect } from 'react';

import I18n from '~/src/common/services/I18n';
import Tracker from '~/src/common/services/Tracker';
import { useGetSearchPopular } from '~/src/queries/api-ecom/generated/api-ecom';

import * as S from './layout';
import PopularWord from './PopularWord';

const Popular = () => {
  const { data } = useGetSearchPopular();
  const hasResults = !!data?.popularWords.length;

  // Amplitude: Suivi du nombre de termes populaires affichés
  useEffect(() => {
    if (data == null) return;
    Tracker.sendEvent('search started', {
      'number of popular terms': data.popularWords.length,
    });
  }, [data]);

  return hasResults ? (
    <S.Container>
      <S.Title>{I18n.t('search.bar.popular.title')}</S.Title>
      <S.Entries>
        {data.popularWords.map(word => (
          <PopularWord key={word} word={word} />
        ))}
      </S.Entries>
    </S.Container>
  ) : null;
};

export default Popular;
