import { useContext, useEffect } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { useRouter as useNextRouter } from 'next/router';

import { registerModals, removeAllModals } from './utils';

const ModalsManager = () => {
  const router = useNextRouter();
  const niceModalContext = useContext(NiceModal.NiceModalContext);

  // Enregistrement des modales dans NiceModal
  useEffect(registerModals, []);

  // Fermeture des modales ouvertes quand on clic sur le bouton de retour du navigateur
  // On bloque également le retour à la précédente page dans ce cas.
  useEffect(() => {
    router.beforePopState(() => {
      const openedModals = Object.keys(niceModalContext);
      if (openedModals.length > 0) {
        removeAllModals();

        // Nécessaire si on ne veut pas avoir une différence entre l'url et la page affiché
        // Vu qu'on bloque le changement de page, on reste sur la même page mais l'url affiché
        // dans le navigateur est celle de la précédente page si on ne fait pas le forward.
        window.history.forward();

        return false;
      }

      return true;
    });
  }, [niceModalContext, router]);

  // Fermeture des modales ouvertes quand on change de pages
  useEffect(() => {
    router.events.on('routeChangeComplete', () => removeAllModals());

    return () => {
      router.events.off('routeChangeComplete', () => removeAllModals());
    };
  }, [router.events]);

  return null;
};

export default ModalsManager;
