import colors from './colors';
import fonts from './fonts';

export const theme = {
  palette: {
    common: colors,
    primary: colors.PRIMARY,
    error: colors.ERRORS,
    text: colors.TEXTS,
    background: colors.WHITE,
  },
  fonts: {
    primary: fonts.RUBIK,
    secondary: fonts.INTRO_ALT,
    tertiary: fonts.INTRO_ALT,
    quaternary: fonts.TRADE_GOTHIC,
  },
  typography: {
    h1: {
      color: colors.BLACK,
      fontSize: '24px',
      fontWeight: 700,
      fontFamily: fonts.INTRO_ALT,
      textTransform: 'initial',
      fontStyle: 'normal',
      lineHeight: '32px',
      marginBottom: '16px',
    },
    h2: {
      color: colors.BLACK,
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: fonts.RUBIK,
      fontStyle: 'normal',
      lineHeight: '24px',
      marginBottom: '8px',
    },
    h3: {
      color: colors.BLACK,
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: fonts.RUBIK,
      letterSpacing: '0px',
      fontStyle: 'normal',
      lineHeight: '24px',
      marginBottom: '8px',
    },
    h4: {
      color: colors.BLACK,
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: fonts.RUBIK,
      lineHeight: '24px',
    },
    // HTML link
    a: {
      color: colors.PRIMARY,
      colorHover: colors.PRIMARY_HOVER,
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: fonts.RUBIK,
      fontStyle: 'normal',
      lineHeight: '24px',
    },
    p: {
      color: colors.BLACK,
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: fonts.RUBIK,
      fontStyle: 'normal',
      lineHeight: '24px',
      marginBottom: '16px',
    },
  },
  layout: {
    borderRadius: '8px',
  },
} as const;
