export const DEFAULT_PRICE_FORMATTER_OPTIONS = {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const priceFormatter = new Intl.NumberFormat('fr-FR', DEFAULT_PRICE_FORMATTER_OPTIONS);

const DEFAULT_UNIT_FORMATTER_OPTIONS = {
  style: 'unit',
  unitDisplay: 'short',
} as const;

let weightKgFormatter: Intl.NumberFormat;
let weightGFormatter: Intl.NumberFormat;
let weightLFormatter: Intl.NumberFormat;
let weightMlFormatter: Intl.NumberFormat;

// Permet d'afficher l'app et la page des navigateurs obsolètes pour les navigateurs ne supportant pas Intl.NumberFormat et l'option unit = 'style'
try {
  weightKgFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    unit: 'kilogram',
  });
  weightGFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    unit: 'gram',
  });
  weightLFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    unit: 'liter',
  });
  weightMlFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    unit: 'milliliter',
  });
} catch {
  weightKgFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    style: 'decimal',
    unit: 'kilogram',
  });
  weightGFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    style: 'decimal',
    unit: 'gram',
  });
  weightLFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    style: 'decimal',
    unit: 'liter',
  });
  weightMlFormatter = new Intl.NumberFormat('fr-FR', {
    ...DEFAULT_UNIT_FORMATTER_OPTIONS,
    style: 'decimal',
    unit: 'milliliter',
  });
}

export const unitFormatters = {
  kg: {
    unit: weightKgFormatter,
    milliUnit: weightGFormatter,
  },
  l: {
    unit: weightLFormatter,
    milliUnit: weightMlFormatter,
  },
};
