/**
 * Cette fonction va transformer l'url passée en entrée afin de l'adapter à un
 * appel par le front-end. L'opération consiste à remplacer le protocole et le
 * domaine du service par ceux du domaine actuellement affiché à l'utilisateur.
 *
 * Cette transformation est nécessaire pour le dev local, et permet
 * de contourner simplement les soucis de SSL, de CORS, et de cookies.
 *
 * Si cette fonction est utilisée coté serveur, aucune transformation n'est effectuée
 */
export const getUpdatedServiceURL = (serviceUrl: string) => {
  if (typeof window === 'undefined') return serviceUrl;

  // On parse l'url et on surcharge protocole et domaine
  const parsedServiceUrl = new URL(serviceUrl);
  const { protocol, host } = window.location;
  parsedServiceUrl.protocol = protocol;
  parsedServiceUrl.host = host;

  // URL() force le pathname à `/` si il est vide
  // On doit donc nettoyer ce comportement de notre côté
  const updatedServiceUrl = parsedServiceUrl.toString();
  const shouldRemoveFinalSlash = !serviceUrl.endsWith('/');

  return shouldRemoveFinalSlash ? updatedServiceUrl.replace(/\/$/, '') : updatedServiceUrl;
};
