import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { useScreenSize } from '~/src/common/hooks/use-screen-size';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';

import { DeliveryItems } from './DeliveryItems';
import useDeliveryActions from './hooks/use-delivery-actions';
import useNavigationActions from './hooks/use-navigation-actions';
import * as S from './layout';

const LeftContent = () => {
  const { isLargeScreen } = useScreenSize();
  const {
    deliveryZoneName,
    deliveryAddress,
    timeSlot,
    handleDeliveryAddressClick,
    handleDeliverySlotClick,
  } = useDeliveryActions();
  const { handleLogoClick } = useNavigationActions();

  const { show: openCategoriesMobileMenu } = useNiceModal('categories-mobile-menu-modal');

  const handleBurgerMenuClick = () => {
    Tracker.sendEvent('Open burger menu');

    void openCategoriesMobileMenu();
  };

  if (!isLargeScreen) {
    return (
      <S.BurgerMenuButton onClick={handleBurgerMenuClick}>
        <Icon name="burger-menu" color="WHITE" size={24} />
      </S.BurgerMenuButton>
    );
  }

  return (
    <>
      <Link href="/" onClick={handleLogoClick} aria-label={I18n.t('navigation.brand')}>
        <S.StyledLogo />
      </Link>
      <DeliveryItems
        hasAddress={Boolean(deliveryAddress || deliveryZoneName)}
        timeSlot={timeSlot}
        onDeliveryAddressClick={handleDeliveryAddressClick}
        onDeliverySlotClick={handleDeliverySlotClick}
      />
    </>
  );
};

export default LeftContent;
